import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { createMockVarList } from '@f1/shared/src/data/merchant/varList';
import { merchantGuids, relationshipGuids } from '../../guids';
import { mockEmployeesGet } from '../../employee/mockEmployees';

const createMockMerchant = (options) => {
  const {
    applicationId,
    chargebackNotificationEmailAddress,
    mid,
    discoverMid,
    dbaName,
    electronicBenefitsTransfer,
    amexRetainedEsa,
    waiveCardBrandFees,
    waiveDuesAndAssessments,
    legalName,
    onHold,
    policyException,
    relationshipId,
    requiresExtraMonitoring,
    status,
    varList,
    chargebackOnly,
    debitOnly
  } = options || {};
  const mockContactAddress = getMockValue({ type: 'address' });
  const mockBillingAddress = getMockValue({ type: 'address' });
  const mockYearMonthStarted = getMockValue({ type: 'yearMonth' });
  const mockContactPerson = getMockValue({ type: 'user', userType: 'external' });
  const mockStatus = status ?? 'Open';
  const isClosed = ['Soft Close', 'Closed'].includes(mockStatus);
  const mockDescriptor = getMockValue({ type: 'string' });
  const mockSeasonalBusiness = getMockValue({ type: 'boolean' });
  const mockOnHold = onHold ?? getMockValue({ type: 'boolean' });
  const mockUseEBT = electronicBenefitsTransfer ?? getMockValue({ type: 'boolean' });
  const mockAmexRetainedEsa = amexRetainedEsa ?? getMockValue({ type: 'boolean' });
  const mockWaiveCardBrandFees = waiveCardBrandFees ?? getMockValue({ type: 'boolean' });
  const mockWaiveDuesAndAssessments = waiveDuesAndAssessments ?? getMockValue({ type: 'boolean' });
  const mockRequiresExtraMonitoring = requiresExtraMonitoring !== undefined
    ? requiresExtraMonitoring
    : getMockValue({ type: 'boolean' });
  const mockPolicyException = policyException !== undefined
    ? policyException
    : getMockValue({ type: 'boolean' });
  const mockEmployeeWhoSetOnHold = mockOnHold
    ? {
      employeeId: mockEmployeesGet.employee01.employeeId,
      firstName: mockEmployeesGet.employee01.firstName,
      lastName: mockEmployeesGet.employee01.lastName,
      email: mockEmployeesGet.employee01.email
    }
    : null;
  const mockDebitOnly = debitOnly !== undefined ? debitOnly : createMockAchDetails();
  const mockChargebackOnly = chargebackOnly !== undefined ? chargebackOnly : createMockAchDetails();
  const mockMerchant = {
    merchant: {
      achInfo: {
        ...createMockAchDetails(),
        bankContact: getMockValue({ type: 'user', userType: 'external' }).fullName,
        bankPhone: getMockValue({ type: 'bePhone' }),
        dateOpened: getMockValue({ type: 'date' }),
        debitOnly: mockDebitOnly,
        chargebackOnly: mockChargebackOnly
      },
      status: {
        status: mockStatus,
        approvedDate: getMockValue({ type: 'date' }),
        closedDate: isClosed ? getMockValue({ type: 'date' }) : null,
        closure_reason: isClosed ? getMockValue({ type: 'string' }) : null,
        closureReason: isClosed ? getMockValue({ type: 'list', list: ['ACH Rejects', 'Bank Request', 'Brand Request', 'CB', 'Fraud', 'Match', 'Merchant Requested', 'Non-Compliance', 'Not Indicated', 'Refunds/RejectedACH', 'Risk Termination'] }) : null,
        partnerClosureMessage: isClosed
          ? `${getMockValue({ type: 'description', length: 'long' })}\n${getMockValue({ type: 'description', length: 'long' })}`
          : null,
        riskDetails: getMockValue({ type: 'string' }),
        riskTermination: getMockValue({ type: 'string' }),
        onHold: mockOnHold,
        onHoldStartTimeStamp: mockOnHold ? getMockValue({ type: 'isoDate' }) : null,
        onHoldEndTimeStamp: mockOnHold ? getMockValue({ type: 'isoDate' }) : null,
        onHoldReason: mockOnHold ? getMockValue({ type: 'description' }) : null,
        employeeWhoSetOnHold: mockOnHold ? mockEmployeeWhoSetOnHold : null
      },
      mid: mid ?? getMockValue({ type: 'mid' }),
      discoverMid: discoverMid ?? getMockValue({ type: 'discoverMid' }),
      organizationalStructure: getMockValue({ type: 'list', list: ['llc', 'soleProprietor', 'partnership', 'government', '501C'] }),
      merchantAcceptanceDate: getMockValue({ type: 'date' }),
      businessStartYear: mockYearMonthStarted.substring(0, 4),
      monthAndYearStarted: mockYearMonthStarted,
      stockSymbol: getMockValue({ type: 'string' }),
      market: getMockValue({ type: 'string' }),
      descriptor: mockDescriptor,
      descriptorShort: mockDescriptor.slice(0, 22),
      dbaName: dbaName ?? getMockValue({ type: 'businessName' }),
      legalName: dbaName ?? legalName ?? getMockValue({ type: 'businessName' }),
      relationshipId: relationshipId ?? relationshipGuids.relationship08,
      reservesIndicator: getMockValue({ type: 'string' }),
      maskedEin: getMockValue({ type: 'ssn', isMasked: true }),
      mccCode: getMockValue({ type: 'mcc' }),
      crm: getMockValue({ type: 'string' }),
      chargebackProvider: getMockValue({ type: 'string' }),
      chargebackNotificationEmailAddress: chargebackNotificationEmailAddress ?? getMockValue({ type: 'email', userType: 'external' }),
      fulfillment: getMockValue({ type: 'string' }),
      callCenterProvider: getMockValue({ type: 'string' }),
      amexServiceEstablishmentNumber: getMockValue({ type: 'accountNumber' }),
      contactInfo: {
        address: mockContactAddress.fullAddress,
        address2: null,
        city: mockContactAddress.cityName,
        state: mockContactAddress.stateCode,
        zip: mockContactAddress.zipCode,
        country: 'US',
        contactEmail: mockContactPerson.email,
        contactPhone: getMockValue({ type: 'bePhone' }),
        contactName: mockContactPerson.fullName,
        url: getMockValue({ type: 'url' }),
        addressType: getMockValue({ type: 'list', list: ['Mailing', 'Location', 'Physical', 'Billing'] }),
        faxNumber: getMockValue({ type: 'bePhone' }),
        billingAddress: {
          addressLine1: mockBillingAddress.fullAddress,
          addressLine2: `Suite ${getMockValue({ type: 'number' })}`,
          city: mockBillingAddress.cityName,
          state: mockBillingAddress.stateCode,
          postalCode: mockBillingAddress.zipCode,
          country: 'US'
        }
      },
      paymentSummary: {
        cashDiscount: getMockValue({ type: 'boolean' }),
        seasonalBusiness: mockSeasonalBusiness,
        netAch: true,
        averageTransactionAmount: getMockValue({ type: 'number', stringify: true }),
        highestTransactionAmount: getMockValue({ type: 'number', stringify: true }),
        currentAllowedMonthlyVolume: getMockValue({ type: 'number' }),
        cardsSwiped: getMockValue({ type: 'number' }),
        telephoneOrder: getMockValue({ type: 'number' }),
        ecommerce: getMockValue({ type: 'number' }),
        seasonalBusinessJan: mockSeasonalBusiness ? true : null, // make at least 1 true for FTs
        seasonalBusinessFeb: mockSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        seasonalBusinessMar: mockSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        seasonalBusinessApr: mockSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        seasonalBusinessMay: mockSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        seasonalBusinessJun: mockSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        seasonalBusinessJul: mockSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        seasonalBusinessAug: mockSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        seasonalBusinessSep: mockSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        seasonalBusinessOct: mockSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        seasonalBusinessNov: mockSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        seasonalBusinessDec: mockSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        fundingChoices: getMockValue({ type: 'list', list: ['standard', 'next_day_funding'] }),
        perMonthFunding: getMockValue({ type: 'number', stringify: true }),
        visaCard: getMockValue({ type: 'boolean' }),
        masterCard: getMockValue({ type: 'boolean' }),
        discoverCard: getMockValue({ type: 'boolean' }),
        amexOptBlue: getMockValue({ type: 'boolean' }),
        debitCardsOnly: getMockValue({ type: 'boolean' }),
        otherCard: getMockValue({ type: 'boolean' }),
        masterCardNobRegStatus: getMockValue({ type: 'string' }),
        guarantorSignedDate: getMockValue({ type: 'date' }),
        amexAcceptance: getMockValue({ type: 'date' }),
        annualVolume: getMockValue({ type: 'number', stringify: true }),
        imprintWithCardPresent: getMockValue({ type: 'number' }),
        currentHoldbackRatio: getMockValue({ type: 'ratio' }),
        currentHoldbackPeriod: getMockValue({ type: 'string' }),
        hrBillingModel: getMockValue({ type: 'list', list: ['Straight Sale', 'SS Continuity', 'Trial'] }),
        hrBusinessType: getMockValue({ type: 'list', list: ['Nutra', 'Non Nutra'] }),
        hrBusinessSubtype1: getMockValue({ type: 'list', list: ['BizOps', 'Convenient Store', 'Gadgets', 'Health Consulting', 'Hydro Equipment', 'Misc. Ingestible', 'Misc. Services', 'Multiple SubTypes', 'Muscle', 'No Limit Zone', 'Nutra', 'Physical Non-Nutra', 'Plus Choice Wellness ProWeight Loss', 'Restaurant', 'Savings Club', 'Skin Serum', 'Topical', 'Weight Loss', 'Wellness'] }),
        hrBusinessSubtype2: getMockValue({ type: 'string' }),
        reserveHoldbackRatio: getMockValue({ type: 'ratio' }),
        reserveHoldbackDays: '1', // For tests
        averageMonthlyVolume: getMockValue({ type: 'number', stringify: true }),
        averageMonthlyVolumeAmex: getMockValue({ type: 'number' }),
        amexNumber: getMockValue({ type: 'accountNumber' }),
        amexRetainedEsa: mockAmexRetainedEsa,
        waiveCardBrandFees: mockWaiveCardBrandFees,
        waiveDuesAndAssessments: mockWaiveDuesAndAssessments,
        electronicBenefitsTransfer: mockUseEBT,
        fnsEbtNumber: mockUseEBT ? getMockValue({ type: 'accountNumber' }) : null,
        suppressStatement: getMockValue({ type: 'boolean' }),
        combinedAch: getMockValue({ type: 'boolean' }),
        discountType: getMockValue({ type: 'list', list: ['monthly', 'daily'] })
      },
      person: Array.from({ length: getMockValue({ type: 'number', min: 1, max: 4 }) }).map(() => {
        const mockPerson = getMockValue({ type: 'user', userType: 'external' });
        const mockPersonAddress = getMockValue({ type: 'address' });
        return {
          firstName: mockPerson.firstName,
          lastName: mockPerson.lastName,
          dob: getMockValue({ type: 'date' }),
          ssn: getMockValue({ type: 'ssn' }),
          ownershipPercentage: 0.25,
          homeStreetAddress: mockPersonAddress.fullAddress,
          city: mockPersonAddress.cityName,
          state: mockPersonAddress.stateCode,
          zip: mockPersonAddress.zipCode,
          emailAddress: mockPerson.email,
          businessPhoneNumber: getMockValue({ type: 'bePhone' }),
          mobileNumber: getMockValue({ type: 'bePhone' }),
          county: mockPersonAddress.county,
          type: getMockValue({ type: 'list', list: ['Primary', 'Beneficiary'] }),
          title: getMockValue({ type: 'string' }),
          fullName: mockPerson.fullName
        };
      }),
      highRiskRegistration: getMockValue({ type: 'boolean' }),
      ein: getMockValue({ type: 'ssn' }),
      nmi: {
        nmiName: getMockValue({ type: 'string' }),
        nmiId: getMockValue({ type: 'guid' })
      },
      varList: createMockVarList(Array.isArray(varList) ? varList : null),
      applicationId: applicationId ?? getMockValue({ type: 'guid' }),
      requiresExtraMonitoring: mockRequiresExtraMonitoring,
      policyException: mockPolicyException,
      policyExceptionReason: mockPolicyException ? getMockValue({ type: 'description' }) : null
    }
  };
  return mockMerchant;
};

const createMockAchDetails = () => ({
  nameOnAccount: getMockValue({ type: 'user', userType: 'external' }).fullName,
  nameOfBank: getMockValue({ type: 'bankName' }),
  accountNumber: getMockValue({ type: 'accountNumber' }),
  routingNumber: getMockValue({ type: 'routingNumber' })
});

// this is the backend response to the GET request
// if a field does not have a value, the BE will not send an empty or null field
const mockMerchantsGET = {
  merchant1: {
    merchant: {
      mid: '11111111111111',
      discoverMid: '000392849573846',
      organizationalStructure: 'corporation',
      merchantAcceptanceDate: '2020-07-02',
      businessStartYear: '2019',
      monthAndYearStarted: '2020-02',
      stockSymbol: 'AA',
      market: 'gadgets',
      descriptor: `Jerry's bank`,
      descriptorShort: 'merchant bank',
      dbaName: 'Merchant with Transaction Report Data',
      legalName: 'eTi4wSeiPBxLzXM inc.',
      applicationId: '2', // Approved app
      requiresExtraMonitoring: true,
      policyException: false,
      highRiskRegistration: null,
      relationshipId: relationshipGuids.default,
      reservesIndicator: 'true',
      ein: '103938374',
      mccCode: '6763',
      crm: 'fake crm',
      chargebackProvider: 'fake chargeback provider',
      fulfillment: 'yes?',
      callCenterProvider: 'AT&T',
      hrApplicationId: '1234',
      amexServiceEstablishmentNumber: '1234',
      contactInfo: {
        billingAddress: {
          addressLine1: '77 Billing Dr',
          addressLine2: null,
          city: 'Houston',
          state: 'TX',
          postalCode: '77777',
          country: 'US'
        },
        address: 'Apartment 5A',
        address2: '129 West 81st Street',
        city: 'New York',
        state: 'NY',
        zip: '10024',
        country: 'US',
        contactEmail: 'jseinfeld@gmail.com',
        contactPhone: '5126801234',
        contactName: 'Jerry Seinfeld',
        url: 'jerryseinfeld.com',
        addressType: 'Mailing',
        faxNumber: '12234568974'
      },
      paymentSummary: {
        seasonalBusiness: true,
        netAch: true,
        averageTransactionAmount: '90.00',
        highestTransactionAmount: '2000.00',
        currentAllowedMonthlyVolume: '45123',
        cardsSwiped: 3,
        telephoneOrder: 4,
        ecommerce: 40,
        seasonalBusinessJan: true,
        seasonalBusinessFeb: true,
        seasonalBusinessMar: true,
        seasonalBusinessApr: true,
        seasonalBusinessMay: true,
        seasonalBusinessJun: true,
        seasonalBusinessJul: true,
        seasonalBusinessAug: true,
        seasonalBusinessSep: true,
        seasonalBusinessOct: true,
        seasonalBusinessNov: true,
        seasonalBusinessDec: true,
        fundingChoices: 'standard',
        perMonthFunding: 'yes',
        visaCard: true,
        masterCard: true,
        discoverCard: true,
        amexOptBlue: true,
        debitCardsOnly: false,
        otherCard: true,
        masterCardNobRegStatus: 'good',
        guarantorSignedDate: '2020-07-17',
        amexAcceptance: '2020-07-18',
        annualVolume: '700000',
        imprintWithCardPresent: 888,
        currentHoldbackRatio: 0.2,
        currentHoldbackPeriod: '0.3',
        reserveHoldbackRatio: 0.2,
        reserveHoldbackDays: '1',
        averageMonthlyVolume: '2',
        hrBillingModel: 'Straight Sale',
        hrBusinessType: 'Nutra',
        hrBusinessSubtype1: 'Muscle',
        hrBusinessSubtype2: 'Wellness',
        cashDiscount: true,
        combinedAch: true,
        discountType: 'monthly'
      },
      achInfo: {
        nameOnAccount: 'George Costanza',
        accountNumber: '123456',
        routingNumber: '789101122',
        nameOfBank: 'Elaine Bennes Banks',
        bankContact: 'Elaine Bennes',
        bankPhone: '+15551234567',
        dateOpened: '1989-07-05'
      },
      status: {
        status: 'Open',
        approvedDate: '1989-07-05',
        riskDetails: 'TestNote',
        riskTermination: false
      },
      varList: createMockVarList([{
        varCreationTimestamp: '2020-08-01T14:40:16.950', // used for tests
        posMid: '000999888777',
        vitalNumber: '73409054',
        acquirerBin: '001122',
        agentBank: '123456', // used for tests
        agentChain: '789012',
        storeNumber: '0004',
        terminalNumber: '0001',
        locationNumber: '00004',
        industry: 'food_restaurant',
        abaNumber: '023948593',
        networksAndSharingGroups: 'Cool Network',
        sharingGroups: null
      }, {
        varCreationTimestamp: '2020-08-02T14:45:16.950', // used for tests
        agentBank: '098765',
        agentChain: '432109'
      }]),
      person: [
        {
          firstName: 'Cosmo',
          lastName: 'Kramer',
          dob: '1961-04-12',
          ssn: '555555553',
          ownershipPercentage: 0.25,
          homeStreetAddress: '123 Fake St.',
          city: 'New York',
          state: 'NY',
          zip: '23940',
          emailAddress: 'Kramer@email.com',
          businessPhoneNumber: '12837584405',
          mobileNumber: '4397782304',
          county: 'Merin',
          type: 'Primary',
          title: 'Owner',
          fullName: 'Cosmo Kramer'
        },
        {
          firstName: 'Newman',
          lastName: 'Newman',
          dob: '1955-08-27',
          ssn: '555515555',
          ownershipPercentage: 0.25,
          homeStreetAddress: '124 Fake St.',
          city: 'New York',
          state: 'NY',
          zip: '22394',
          emailAddress: 'Newman@email.com',
          businessPhoneNumber: '19439475887',
          mobileNumber: '8893472804',
          county: 'Cook',
          type: 'Beneficiary',
          title: 'Owner',
          fullName: 'Newman Newman'
        },
        {
          firstName: 'Frank',
          lastName: 'Costanza',
          dob: '1927-06-08',
          ssn: '555515555',
          ownershipPercentage: 0.25,
          homeStreetAddress: '124 Fake St.',
          city: 'New York',
          state: 'NY',
          zip: '22394',
          emailAddress: 'georgesdad@email.com',
          businessPhoneNumber: '19439475887',
          mobileNumber: '8893472804',
          county: 'Cook',
          type: 'Beneficiary',
          title: 'Owner',
          fullName: 'Frank Costanza'
        }
      ],
      nmi: {
        nmiName: 'NMI name',
        nmiId: '12345'
      }
    }

  },
  merchant2: {
    merchant: {
      mid: '234567890123',
      organizationalStructure: 'llc',
      merchantAcceptanceDate: '2019-08-03',
      businessStartYear: '2019',
      monthAndYearStarted: '2020-03',
      stockSymbol: 'AA',
      market: 'gadgets',
      descriptor: `The Bank of Frank`,
      descriptorShort: 'merchant bank',
      dbaName: 'testtest',
      legalName: 'testing',
      applicationId: '',
      requiresExtraMonitoring: false,
      policyException: true,
      policyExceptionReason: 'Mock policy exception description',
      highRiskRegistration: false,
      relationshipId: relationshipGuids.default,
      ein: '372948574',
      mccCode: '6763',
      hrApplicationId: '5678',
      amexServiceEstablishmentNumber: '1234',
      contactInfo: {
        billingAddress: {
          addressLine1: '88 Billing Dr',
          addressLine2: null,
          city: 'Denver',
          state: 'CO',
          postalCode: '88888',
          country: 'US'
        },
        address: `544 Mateo Street`,
        city: 'Los Angeles',
        state: 'CA',
        zip: '90013',
        country: 'US',
        contactEmail: 'chardeemacdennis@gmail.com',
        contactPhone: '5127891234',
        contactName: 'Ronald MacDonald'
      },
      paymentSummary: {
        seasonalBusiness: true,
        netAch: true,
        currentAllowedMonthlyVolume: '45123',
        reserveHoldbackRatio: 0.2,
        reserveHoldbackDays: '1',
        averageMonthlyVolume: '2',
        seasonalBusinessJan: false,
        seasonalBusinessFeb: false,
        seasonalBusinessMar: false,
        seasonalBusinessApr: false,
        seasonalBusinessMay: false,
        seasonalBusinessJun: true,
        seasonalBusinessJul: true,
        seasonalBusinessAug: true,
        seasonalBusinessSep: false,
        seasonalBusinessOct: false,
        seasonalBusinessNov: false,
        seasonalBusinessDec: false,
        cashDiscount: null
      },
      achInfo: {
        nameOnAccount: 'Charlie Kelly',
        accountNumber: '123456',
        routingNumber: '789101100',
        nameOfBank: 'The Bank of Frank',
        bankContact: 'Frank Reynolds',
        bankPhone: '+15551234567',
        dateOpened: '1989-07-05'
      },
      status: {
        status: 'Closed',
        approvedDate: '1989-07-05',
        closedDate: '2001-01-01',
        closureReason: 'ACH Rejects',
        partnerClosureMessage: `This was closed for these reasons:\n1) This is the first reason\n2) This is the second reason`,
        riskDetails: 'TestNote',
        riskTernmination: true
      },
      varList: [],
      person: [
        {
          firstName: 'Deandra',
          lastName: 'Reynolds',
          dob: '1975-08-18',
          ssn: '111223333',
          ownershipPercentage: 0.5,
          fullName: 'Dee Reynolds'
        },
        {
          firstName: 'Dennis',
          lastName: 'Reynolds',
          dob: '1976-04-13',
          ssn: '554555555',
          ownershipPercentage: 0.95,
          fullName: 'Dennis Reynolds'
        }
      ],
      nmi: {
        nmiId: '67890'
      }
    }
  },
  merchant3: {
    merchant: {
      mid: '1111111111111',
      organizationalStructure: 'soleProprietor',
      merchantAcceptanceDate: '2019-08-04',
      businessStartYear: '2019',
      monthAndYearStarted: '2020-04',
      stockSymbol: 'AA',
      market: 'gadgets',
      descriptor: `My bank`,
      descriptorShort: 'merchant bank',
      dbaName: 'My bank dba',
      legalName: 'testing',
      applicationId: '2', // Approved App
      requiresExtraMonitoring: false,
      policyException: false,
      highRiskRegistration: true,
      relationshipId: relationshipGuids.default,
      ein: '111223333',
      mccCode: '5111',
      hrApplicationId: '9012',
      amexServiceEstablishmentNumber: '1234',
      contactInfo: {
        billingAddress: {
          addressLine1: '99 Billing Dr',
          addressLine2: null,
          city: 'Los Angeles',
          state: 'CA',
          postalCode: '99999',
          country: 'US'
        },
        address: `111 One Street`,
        address2: 'apartment 1',
        city: 'Austin',
        state: 'TX',
        zip: '78704',
        contactEmail: 'one@mybank.com',
        contactPhone: '9996663322',
        contactName: 'One Onesie'
      },
      paymentSummary: {
        reserveHoldbackRatio: 0.2,
        reserveHoldbackDays: '1',
        averageMonthlyVolume: '2',
        currentAllowedMonthlyVolume: '265',
        cashDiscount: false
      },
      achInfo: {
        nameOnAccount: 'John E Doe',
        accountNumber: '111222333',
        routingNumber: '444555666',
        nameOfBank: 'My Bank',
        bankContact: 'Banky Banks',
        bankPhone: '+19998887766',
        dateOpened: '2000-04-04'
      },
      status: {
        status: 'Open',
        approvedDate: '2001-09-08'
      },
      varList: [],
      person: [
        {
          firstName: 'Sarah',
          lastName: 'Parker',
          dob: '1980-02-26',
          ssn: '888665544',
          ownershipPercentage: 0.23,
          title: 'Owner',
          fullName: 'Sarah Parker'
        }
      ],
      nmi: {
        nmiId: '13579'
      }
    }
  },
  merchant4: {
    merchant: {
      mid: '111111111112',
      organizationalStructure: 'partnership',
      merchantAcceptanceDate: '2019-08-05',
      businessStartYear: '2019',
      monthAndYearStarted: '2020-05',
      stockSymbol: 'AA',
      market: 'gadgets',
      descriptor: `netevia descriptor`,
      descriptorShort: 'netevia desc',
      dbaName: 'Mock Netevia Merchant 1',
      legalName: 'Mock Netevia Merchant Legal Name',
      applicationId: '5', // Approved App
      requiresExtraMonitoring: false,
      policyException: false,
      highRiskRegistration: true,
      relationshipId: relationshipGuids.relationship12,
      ein: '111223333',
      mccCode: '5968',
      hrApplicationId: '9012',
      amexServiceEstablishmentNumber: '1234',
      contactInfo: {
        billingAddress: {
          addressLine1: '100 Billing Dr',
          addressLine2: null,
          city: 'Nashville',
          state: 'TN',
          postalCode: '10101',
          country: 'US'
        },
        address: `99 Netevia Street`,
        address2: 'apartment 9',
        city: 'Austin',
        state: 'TX',
        zip: '78704',
        contactEmail: 'hello@netevia.com',
        contactPhone: '+16663332222',
        contactName: 'Mr. Netevia'
      },
      paymentSummary: {
        reserveHoldbackRatio: 0.4,
        reserveHoldbackDays: '1',
        averageMonthlyVolume: '3',
        currentAllowedMonthlyVolume: '88',
        cashDiscount: false
      },
      achInfo: {
        nameOnAccount: 'Net Evia',
        accountNumber: '1112223333',
        routingNumber: '444555666',
        nameOfBank: 'Evolve',
        bankContact: 'Eve Olve',
        bankPhone: '+14445556666',
        dateOpened: '1999-06-08'
      },
      status: {
        status: 'Open',
        approvedDate: '2000-04-03'
      },
      varList: createMockVarList([{
        agentBank: '987654' // used for tests
      }]),
      person: [
        {
          firstName: 'Janet',
          lastName: 'Doe',
          dob: '1985-07-29',
          ssn: '222334444',
          ownershipPercentage: 0.1,
          title: 'Owner',
          fullName: 'Janet N Doe'
        }
      ],
      nmi: {
        nmiId: null
      }
    }
  },
  merchant5: {
    merchant: {
      mid: '111111111113',
      organizationalStructure: 'government',
      merchantAcceptanceDate: '2019-08-06',
      businessStartYear: '2019',
      monthAndYearStarted: '2020-06',
      stockSymbol: 'AA',
      market: 'gadgets',
      descriptor: `netevia descriptor`,
      descriptorShort: 'netevia desc',
      dbaName: 'Mock Netevia Merchant elevated',
      legalName: 'Mock Netevia Merchant Legal Name elevated',
      applicationId: '6', // Approved App
      requiresExtraMonitoring: false,
      policyException: false,
      highRiskRegistration: true,
      relationshipId: relationshipGuids.relationship12,
      ein: '111223333',
      mccCode: '5968',
      hrApplicationId: '9012',
      amexServiceEstablishmentNumber: '1234',
      contactInfo: {
        billingAddress: {
          addressLine1: '22 Billing Dr',
          addressLine2: null,
          city: 'Orlando',
          state: 'FL',
          postalCode: '22222',
          country: 'US'
        },
        address: `99 Netevia Street`,
        address2: 'apartment 9',
        city: 'Austin',
        state: 'TX',
        zip: '78704',
        contactEmail: 'hello@netevia.com',
        contactPhone: '+16663332222',
        contactName: 'Ms. Netevia'
      },
      paymentSummary: {
        reserveHoldbackRatio: 0.4,
        reserveHoldbackDays: '1',
        averageMonthlyVolume: '3',
        currentAllowedMonthlyVolume: '88'
      },
      achInfo: {
        nameOnAccount: 'Net Evia',
        accountNumber: '1112223333',
        routingNumber: '444555666',
        nameOfBank: 'Evolve',
        bankContact: 'Eve Olve',
        bankPhone: '+14445556666',
        dateOpened: '1999-06-08'
      },
      status: {
        status: 'Open',
        approvedDate: '2000-04-03'
      },
      varList: [],
      person: [
        {
          firstName: 'Janet',
          lastName: 'Doe',
          dob: '1985-07-29',
          ssn: '222334444',
          ownershipPercentage: 0.1,
          title: 'Owner',
          fullName: 'Janet N Doe'
        }
      ],
      nmi: {
        nmiId: null
      }
    }
  }
};

const mockMerchantDetails = {
  [merchantGuids.default]: mockMerchantsGET.merchant1,
  [merchantGuids.closed]: mockMerchantsGET.merchant2,
  [merchantGuids.merchant03]: mockMerchantsGET.merchant3,
  [merchantGuids.merchant04]: mockMerchantsGET.merchant4,
  [merchantGuids.merchant05]: mockMerchantsGET.merchant5
};

// @DEPRECATED - for new mock merchant data, add to `mockMerchantMap`
export const mockMerchantDetailsGetRes = {
  [merchantGuids.default]: mockMerchantDetails[merchantGuids.default],
  [merchantGuids.closed]: mockMerchantDetails[merchantGuids.closed],
  [merchantGuids.merchant03]: mockMerchantDetails[merchantGuids.merchant03],
  [merchantGuids.merchant04]: mockMerchantDetails[merchantGuids.merchant04],
  [merchantGuids.merchant05]: mockMerchantDetails[merchantGuids.merchant05]
};

const mockMerchantMap = {
  [merchantGuids.merchant01]: {
    mid: '898551494426551',
    dbaName: 'ZL2xIwX4Du9R9Bl',
    relationshipId: relationshipGuids.relationship02,
    chargebackNotificationEmailAddress: 'mockCbEmail@test.com',
    discoverMid: '111222333444555',
    varList: [ // should only have 1 VAR for tests
      {}
    ],
    debitOnly: null
  },
  [merchantGuids.merchant06]: {
    mid: '111111111114',
    dbaName: 'Mock Merchant - Priority / Axiom / Elevated',
    onHold: true, // set to hardcoded boolean for FT
    relationshipId: relationshipGuids.relationship23,
    chargebackOnly: null
  },
  [merchantGuids.merchant07]: {
    mid: '111111111115',
    dbaName: 'Mock Merchant - Priority / Wells Fargo / Elevated',
    onHold: false, // set to hardcoded boolean for FT
    relationshipId: relationshipGuids.relationship24,
    debitOnly: null, // debitOnly not allowed for priority/first data
    chargebackOnly: null
  },
  [merchantGuids.merchant08]: {
    mid: '111111111116',
    dbaName: 'Mock Merchant - Micamp / Mavric / Elevated',
    relationshipId: relationshipGuids.relationship12,
    requiresExtraMonitoring: null,
    policyException: null,
    chargebackOnly: null
  },
  [merchantGuids.merchant09]: {
    mid: '11111145678',
    dbaName: 'Mock Repay Merchant 1',
    relationshipId: relationshipGuids.relationship02,
    chargebackNotificationEmailAddress: 'mockCbEmail@test.com',
    discoverMid: '000000000000001',
    varList: [
      { varCreationTimestamp: '2020-04-01T14:40:16.950' },
      { varCreationTimestamp: '2020-05-01T14:40:16.950' }
    ],
    chargebackOnly: null
  }
};

export const mockMerchantV2DetailGet = (body, params, options) => {
  const { guid } = options || {};
  return {
    [guid]: mockMerchantDetailsGetRes[guid] ?? createMockMerchant({ ...mockMerchantMap[guid] })
  };
};
