import React from 'react';
import PropTypes from 'prop-types';
import { AlertBar, SiteNav } from '@f1/shared';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Header from '@f1/shared/src/Header';
import { envIsDevOrLess, envIsNotProd } from '@f1/shared/src/_helpers';
import * as actionCreators from '../redux/actions/actionCreators';
import withPolling from './WithPolling';
import {
  employeeGroupsInclude,
  getMyPrevets,
  getMyTickets,
  refreshAllData
} from '../utils';

function mapStateToProps (state) {
  return {
    isAuthenticated: state.authenticate.isAuthenticated,
    barStyle: state.alertBar.barStyle,
    message: state.alertBar.message,
    timeout: state.alertBar.timeout,
    isInternal: state.authenticate.user.isInternal,
    email: state.authenticate.user.identityToken.email
  };
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}
export class BoundSiteHeader extends React.PureComponent {
  constructor (props) {
    super(props);
    const { isAuthenticated } = this.props;
    this.mounted = false;
    this.state = {
      headerLinks: [],
      myTicketCountLoading: false,
      myPrevetCountLoading: false,
      isAuth: isAuthenticated,
      alertBarType: 'closed',
      alertBarMessage: '',
      alertBarTimeout: true
    };
  }

  componentDidMount () {
    this.mounted = true;
    this.setHeaderLinks();
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      isAuthenticated,
      barStyle,
      message,
      timeout
    } = this.props;
    if (prevProps.isAuthenticated !== isAuthenticated) {
      this.updateState({
        isAuth: isAuthenticated
      }, () => {
        this.setHeaderLinks();
        this.handleRefreshAllData();
      });
    }
    if (prevProps.barStyle !== barStyle || prevProps.message !== message) {
      this.updateState({
        alertBarType: barStyle,
        alertBarMessage: message,
        alertBarTimeout: timeout
      });
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state, callback) => {
    this.mounted && this.setState(state, callback);
  }

  handleCloseAlertBar = (data) => {
    const { alertBar } = this.props;
    const { alertBarType, alertBarMessage, alertBarTimeout } = data;
    alertBar(alertBarType, alertBarMessage, alertBarTimeout);
  }

  setHeaderLinks = () => {
    const { isAuthenticated, isInternal } = this.props;
    const showProhibitedEntities = (isInternal && employeeGroupsInclude(['App Review', 'Risk', 'Operations', 'Engineering'])) || !isInternal;
    const showBatchSummary = employeeGroupsInclude(['Operations', 'Sales', 'Risk', 'Compliance']);
    this.updateState({
      headerLinks: isAuthenticated
        ? [
          // viewable by all
          {
            text: 'Merchants',
            href: '/merchant',
            // TODO SNEK-2049: remove env check
            ...(envIsDevOrLess() && isInternal ? {
              submenu: [
                { text: 'Merchant Ledger', href: '/merchant/merchantLedger' }
              ]
            } : {})
          },
          // only viewable by internal groups
          ...((isInternal)
            ? [{
              text: 'Partners',
              href: '/partner',
              submenu: [
                { text: 'Action Suggested', href: '/partner/actionSuggested' },
                { text: 'ACH Report', href: '/partner/achReport' }
              ]
            }]
            : []),
          // only viewable by internal groups
          ...((isInternal)
            ? [{ text: 'Relationships', href: '/relationship' }]
            : []),
          ...(employeeGroupsInclude(['Accounting', 'Engineering', 'Residual']) ? [{
            text: 'Finance',
            submenu: [
              ...(envIsNotProd() && employeeGroupsInclude(['Accounting'])
                ? [{ text: 'Nacha', href: '/nacha' }] : []),
              { text: 'Merchant Profitability', href: '/profitability' },
              ...(employeeGroupsInclude(['Engineering', 'Residual'])
                ? [{ text: 'Residuals', href: '/residualsv1' }, { text: 'Residuals V2', href: '/residualsv2' }] : [])
            ]
          }] : []),
          // only viewable by IT users
          ...(employeeGroupsInclude('IT')
            ? [{ text: 'IT', href: '/it' }]
            : []),
          ...(
            (isInternal && employeeGroupsInclude(['App Review', 'Compliance', 'Credit', 'Engineering', 'Operations Legacy', 'Operations', 'Sales'])) ||
            (!isInternal && employeeGroupsInclude(['MVB'])
            )
              ? [{
                text: 'Applications',
                href: '/application-v2',
                ...(isInternal && {
                  submenu: [
                    ...(employeeGroupsInclude(['App Review', 'Engineering', 'Sales', 'Operations']) ? [{
                      text: 'CRAB',
                      href: '/application-v2'
                    }] : []),
                    ...(employeeGroupsInclude(['App Review', 'Credit']) ? [{
                      text: 'MATCH',
                      href: '/application/match'
                    }] : []),
                    ...((isInternal && employeeGroupsInclude(['App Review', 'Credit', 'Sales', 'Operations', 'Engineering'])) || (!isInternal && employeeGroupsInclude(['MVB']))
                      ? [{ text: 'Pend Report', href: '/application/report/pend' }]
                      : []),
                    ...(showProhibitedEntities ? [{ text: 'Prohibited Entities', href: '/prohibitedEntities' }] : []),
                    ...(employeeGroupsInclude(['App Review', 'Credit', 'Engineering'])
                      ? [{ text: 'Repay Mid List', href: '/repay-mid-list' }]
                      : []),
                    ...(employeeGroupsInclude(['Compliance', 'Credit', 'Operations Legacy']) ? [{
                      text: 'Sticky',
                      href: '/application/sticky'
                    }] : [])
                  ]
                })
              }]
              : []),
          ...(isInternal && employeeGroupsInclude(['App Review', 'Credit', 'Engineering', 'Sales'])
            ? [{ text: 'Prevet', href: '/prevet' }]
            : []),
          // only viewable by Risk
          ...(employeeGroupsInclude(['Risk', 'Compliance', 'Engineering']) || !isInternal
            ? [{
              text: 'Risk',
              href: '/prohibitedEntities',
              ...(employeeGroupsInclude(['Compliance']) && { href: '/risk/binStatus' }),
              ...(employeeGroupsInclude(['Engineering']) && { href: '/risk/binStatus' }),
              ...(employeeGroupsInclude(['Risk']) && { href: '/risk' }),
              submenu: [
                ...(employeeGroupsInclude(['Risk']) ? [{ text: 'Dashboard', href: '/risk' }] : []),
                ...(showProhibitedEntities ? [{ text: 'Prohibited Entities', href: '/prohibitedEntities' }] : []),
                ...(employeeGroupsInclude(['Risk']) ? [{ text: 'Rules', href: '/risk/rules' }] : []),
                ...(employeeGroupsInclude(['Risk', 'Compliance']) ? [{ text: 'Bin Status', href: '/risk/binStatus' }] : []),
                ...(((isInternal && employeeGroupsInclude(['Risk', 'Engineering'])) || !isInternal)
                  ? [{ text: 'Repay Transaction Hold', href: 'risk/repayTransactionHold' }]
                  : [])
              ]
            }]
            : []),
          // only viewable by Compliance and NOT Risk
          ...((employeeGroupsInclude('Compliance') && !employeeGroupsInclude('Risk'))
            ? [{ text: 'Bin Status', href: '/binStatus' }]
            : []),
          {
            text: 'Reports',
            href: isInternal ? '/reports/reserves' : '/reports/transactionProfitability',
            ...(isInternal && {
              ...(showBatchSummary && { href: '/reports/batchDetails' }),
              submenu: [
                ...(showBatchSummary ? [{ text: 'Batch Summary Report', href: '/reports/batchDetails' }] : []),
                { text: 'Reserves', href: '/reports/reserves' },
                { text: 'Transaction Profitability', href: '/reports/transactionProfitability' }
              ]
            })
          },
          // viewable by internal only
          ...(isInternal ? [
            { text: 'Tickets', href: '/tickets', notifications: null }
          ] : []),
          // viewable by all
          {
            text: 'Account',
            href: '/account',
            submenu: [
              { text: 'Account', href: '/account' },
              // only viewable by internal groups
              ...((isInternal)
                ? [
                  { text: 'Repay', href: '/repay' }
                ]
                : []),
              // only viewable by Operations users
              ...(employeeGroupsInclude(['Operations'])
                ? [{ text: 'Operations', href: '/support' }]
                : []),
              ...(isInternal
                ? [{ text: 'Training', href: '/training' }]
                : []),
              { text: 'Sign Out', href: 'signOut' }
            ]
          }
        ]
        : []
    }, isInternal && isAuthenticated ? this.addCountToHeaders : null);
  }

  handleRefreshAllData = () => {
    const { isAuth } = this.state;
    isAuth && refreshAllData({
      fullPageLoad: false,
      useFullPageLoader: false,
      refreshAll: false,
      showSuccessAlert: false
    });
  }

  addCountToHeaders = async () => {
    const { email, isAuthenticated, isInternal } = this.props;
    const { headerLinks } = this.state;
    const isAppReview = employeeGroupsInclude(['App Review']);
    this.updateState({
      myTicketCountLoading: true,
      myPrevetCountLoading: isAppReview ? true : null
    });
    const myTickets = await getMyTickets(email);
    const myPrevets = isAppReview
      ? await getMyPrevets(email, { isAppReview })
      : null;
    this.updateState({
      myTicketCountLoading: false,
      myPrevetCountLoading: isAppReview ? false : null,
      headerLinks: headerLinks.map((link) => {
        if (link.text === 'Tickets') {
          return ({
            ...link,
            notifications: myTickets?.data > 0 ? myTickets?.data : null
          });
        }
        if (myPrevets && link.text === 'Prevet') {
          return ({
            ...link,
            notifications: myPrevets
          });
        }
        return link;
      })
    });
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      isInternal && this.addCountToHeaders();
    }, 300000); // run every 5 minutes
    !isAuthenticated && clearTimeout(timeout);
    // clear the timeout if the user logs out
  }

  render () {
    const {
      isAuth,
      myTicketCountLoading,
      myPrevetCountLoading,
      headerLinks,
      alertBarType,
      alertBarMessage,
      alertBarTimeout
    } = this.state;
    const {
      barStyle,
      resetStore,
      isAuthenticated
    } = this.props;
    return (
      <Header destination="/">
        {barStyle !== 'closed' && (
          <AlertBar
            options={{ barStyle: alertBarType, message: alertBarMessage, timeout: alertBarTimeout }}
            callback={this.handleCloseAlertBar}
          />
        )}
        { isAuth && (
          <SiteNav
            headerLinks={headerLinks}
            isAuthenticated={isAuthenticated}
            resetStore={resetStore}
            myTicketCountLoading={myTicketCountLoading}
            myPrevetCountLoading={myPrevetCountLoading}
          />
        )}
      </Header>
    );
  }
}

BoundSiteHeader.propTypes = {
  isAuthenticated: PropTypes.bool,
  alertBar: PropTypes.func,
  barStyle: PropTypes.string,
  message: PropTypes.string,
  timeout: PropTypes.bool,
  resetStore: PropTypes.func,
  isInternal: PropTypes.bool,
  email: PropTypes.string
};

BoundSiteHeader.defaultProps = {
  isAuthenticated: false,
  alertBar: () => {},
  barStyle: 'closed',
  message: '',
  timeout: true,
  resetStore: () => {},
  isInternal: false,
  email: ''
};

const SiteHeader = withPolling(
  connect(mapStateToProps, mapDispatchToProps)(BoundSiteHeader)
);

export default SiteHeader;
