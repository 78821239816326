export const mockTransactionReportReserveGet = (body, params, options) => {
  const { guid = '' } = options || {};
  return {
    [guid]: {
      header: {
        label: 'date',
        lines: ['count'],
        biaxial: ['count'],
        currency: true,
        biaxialCurrency: null
      },
      data: [
        { date: 'Feb', count: 30, 'Relationship with Transaction Report Data': 1500 },
        { date: 'Mar', count: 0, 'Relationship with Transaction Report Data': 0 },
        { date: 'Apr', count: 0, 'Relationship with Transaction Report Data': 0 },
        { date: 'May', count: 0, 'Relationship with Transaction Report Data': 0 },
        { date: 'Jun', count: 0, 'Relationship with Transaction Report Data': 0 },
        { date: 'Jul', count: 30, 'Relationship with Transaction Report Data': 1500 },
        { date: 'Aug', count: 0, 'Relationship with Transaction Report Data': 0 }
      ]
    }
  };
};

export default mockTransactionReportReserveGet;
