import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { partnerGuids } from '../../guids';

// this is the backend response to the GET request
// if a field does not have a value, the BE will not send an empty or null field
const mockPartners = {
  partner1: {
    // non-required fields not included
    partner: {
      contactInfo: {
        ownerName: 'Mr Testman',
        address: '123 Test House',
        // address2??
        city: 'Testopolis',
        state: 'TX',
        zip: '78001',
        portalEmail: 'test@test.com',
        portalPhone: '+15551234567',
        supportEmail: 'test@test.com',
        supportPhone: '+15551234567',
        ownerEmail: 'test@test.com',
        ownerPhone: '+15551234567',
        residualEmail: 'test@test.com',
        closureEmail: 'test@test.com'
      },
      proposedOutput: {
        averageApplicationsPerMonth: getMockValue({ type: 'number' }),
        averageVolumePerMidPerMonth: getMockValue({ type: 'number' })
      },
      ein: '111223333',
      name: 'Testman Partners',
      businessDba: '123456',
      businessLegal: 'legal business',
      partnerBusinessCode: '1234',
      crabConfigurationOptions: {
        ddaForChargebacksOnly: true,
        suppressStatementsDefault: true,
        merchantChargebackNotificationEmail: 'mockCbEmail@test.com'
      }
    }
  },
  partner2: {
    // non-required fields included
    partner: {
      contactInfo: {
        ownerName: 'Mrs Testlady',
        address: '456 Test Place',
        address2: 'Test Lane',
        city: 'Testopia',
        state: 'TX',
        zip: '31204',
        url: 'testland.com',
        portalEmail: 'test@test.com',
        portalPhone: '+15554567890',
        supportEmail: 'test@test.com',
        supportPhone: '+15554567890',
        ownerEmail: 'test@test.com',
        ownerPhone: '+15554567890',
        residualEmail: 'test@test.com',
        closureEmail: 'test@test.com'
      },
      proposedOutput: {
        averageApplicationsPerMonth: getMockValue({ type: 'number' }),
        averageVolumePerMidPerMonth: getMockValue({ type: 'number' })
      },
      ein: '098765432',
      riskReferralContact: 'Questioning Guy',
      name: 'Test LLC',
      subname: 'Really Testing You Inc.',
      businessDba: '098765',
      businessLegal: 'very legal business',
      partnerBusinessCode: '1456',
      crabConfigurationOptions: {
        ddaForChargebacksOnly: true,
        suppressStatementsDefault: true,
        merchantChargebackNotificationEmail: 'mockCbEmail@test.com'
      }
    }
  }
  // Only add new mock partner data here if you need hardcoded data (eg, for FTs)
};

const getMockPartnerV2Detail = (guid) => {
  const staticMap = {
    [partnerGuids.partner09]: {
      partnerBusinessCode: '1596',
      name: 'FE - Partner with no crab config options',
      crabConfigurationOptions: {
        ddaForChargebacksOnly: false,
        suppressStatementsDefault: false,
        merchantChargebackNotificationEmail: null
      }
    }
  };
  const mockAddress = getMockValue({ type: 'address' });
  const mockPartner = {
    contactInfo: {
      address: mockAddress.fullAddress,
      address2: getMockValue({ type: 'boolean' }) ? `Suite ${getMockValue({ type: 'number', min: 1 })}` : null,
      city: mockAddress.cityName,
      state: mockAddress.stateCode,
      zip: mockAddress.zipCode,
      ownerName: getMockValue({ type: 'user' }).fullName,
      url: getMockValue({ type: 'boolean' }) ? getMockValue({ type: 'url' }) : null,
      portalEmail: getMockValue({ type: 'email' }),
      portalPhone: getMockValue({ type: 'bePhone' }),
      supportEmail: getMockValue({ type: 'email' }),
      supportPhone: getMockValue({ type: 'bePhone' }),
      ownerEmail: getMockValue({ type: 'email' }),
      ownerPhone: getMockValue({ type: 'bePhone' }),
      residualEmail: getMockValue({ type: 'email' }),
      closureEmail: getMockValue({ type: 'email' })
    },
    proposedOutput: {
      averageApplicationsPerMonth: getMockValue({ type: 'number' }),
      averageVolumePerMidPerMonth: getMockValue({ type: 'number' })
    },
    ein: getMockValue({ type: 'ssn' }),
    riskReferralContact: getMockValue({ type: 'user' }).fullName,
    name: getMockValue({ type: 'businessName' }),
    subname: getMockValue({ type: 'boolean' }) ? getMockValue({ type: 'user' }).fullName : null,
    businessDba: getMockValue({ type: 'businessName' }),
    businessLegal: getMockValue({ type: 'businessName' }),
    partnerBusinessCode: getMockValue({
      type: 'number',
      min: 1000,
      max: 1999,
      stringify: true
    }),
    crabConfigurationOptions: {
      ddaForChargebacksOnly: true,
      suppressStatementsDefault: true,
      merchantChargebackNotificationEmail: getMockValue({ type: 'email', userType: 'external' })
    },
    ...(staticMap && staticMap[guid])
  };
  return { partner: mockPartner };
};

// this is the response that the backend sends on GET request
export const mockPartnerV2DetailsGetRes = {
  [partnerGuids.default]: mockPartners.partner1,
  [partnerGuids.partner02]: mockPartners.partner2
};

export const mockPartnerV2DetailGet = (body, params, options) => {
  const { guid } = options || {};
  const mockData = mockPartnerV2DetailsGetRes[guid] || getMockPartnerV2Detail(guid);
  return { [guid]: mockData };
};

export const mockPartnerV2Put = (body, params, options) => ({ [options?.guid]: { partnerId: partnerGuids.default, partnerBusinessCode: '1005' } });
