import { transformData } from '@f1/shared/src/_helpers';
import { relationshipGuids, partnerGuids } from '../../guids';
import relationshipV2POSTTemplate from './templates/relationshipV2POSTTemplate';
import relationshipV2PUTTemplate from './templates/relationshipV2PUTTemplate';
import { mockRelationshipV2DetailGetRes } from './mockRelationshipsV2Get';
import { sharedRelationshipFormProps, relationshipV3FormFields } from '../../../pages/components/forms/formHelpers';
import { mockGatewayInfoList } from '../root';

// formats data from formFields to what the BE expects to edit
const formatPOSTData = data => transformData({
  data: { ...data, allGatewayInfoList: mockGatewayInfoList },
  toSchema: 'backend',
  template: relationshipV2POSTTemplate,
  version: '1.0'
});

// formats data from BE to formFields to be edited
const formatPOSTToEditData = data => transformData({
  data,
  toSchema: 'frontendEdit',
  template: relationshipV2POSTTemplate,
  version: '1.0'
});

// formats data from formFields to what the BE expects to add
const formatPUTData = data => transformData({
  data: { ...data, allGatewayInfoList: mockGatewayInfoList },
  toSchema: 'backend',
  template: relationshipV2PUTTemplate,
  version: '2.0'
});

export const mockRelationshipsV2PutPost = {
  [relationshipGuids.default]: {
    relationshipId: {
      ...sharedRelationshipFormProps.relationshipId,
      value: relationshipGuids.default,
      required: false,
      valid: true
    },
    relationshipCode: {
      ...relationshipV3FormFields.relationshipCode,
      value: '123456',
      required: true,
      valid: true
    },
    relationshipName: {
      ...relationshipV3FormFields.relationshipName,
      value: 'Partner with Transaction Report Data',
      required: true,
      valid: true
    },
    parentRelationshipId: {
      ...relationshipV3FormFields.parentRelationshipId,
      value: relationshipGuids.relationship05,
      required: true,
      valid: true
    },
    childPartnerId: {
      ...relationshipV3FormFields.childPartnerId,
      value: partnerGuids.partner02,
      required: true,
      valid: true
    },
    bankName: {
      ...relationshipV3FormFields.bankName,
      value: 'CYNERGY',
      required: true,
      valid: true
    },
    processorName: {
      ...relationshipV3FormFields.processorName,
      value: 'FIRST_DATA',
      required: true,
      valid: true
    },
    processorFrontEnd: {
      ...relationshipV3FormFields.processorFrontEnd,
      value: 'test',
      required: false,
      valid: true
    },
    paymentGateway: {
      ...relationshipV3FormFields.paymentGateway,
      value: 'NMI',
      selected: 'NMI',
      required: true,
      valid: true
    },
    gatewayAffiliateId: {
      ...sharedRelationshipFormProps.gatewayAffiliateId,
      value: 'f1_house',
      required: false,
      valid: true
    },
    riskProfile: {
      ...relationshipV3FormFields.riskProfile,
      value: 'preferred',
      selected: 'preferred',
      required: true,
      valid: true
    },
    agentChain: {
      ...relationshipV3FormFields.agentChain,
      value: '012345',
      required: false,
      valid: true
    },
    inactive: {
      ...relationshipV3FormFields.inactive,
      value: false,
      selected: 'no',
      required: true,
      valid: true
    }
  },
  [relationshipGuids.relationship02]: {
    relationshipId: {
      ...sharedRelationshipFormProps.relationshipId,
      value: relationshipGuids.relationship02,
      required: false,
      valid: true
    },
    relationshipCode: {
      ...relationshipV3FormFields.relationshipCode,
      value: '189101',
      required: true,
      valid: true
    },
    relationshipName: {
      ...relationshipV3FormFields.relationshipName,
      value: '0CsKQFgXSYEb4XH',
      required: true,
      valid: true
    },
    parentRelationshipId: {
      ...relationshipV3FormFields.parentRelationshipId,
      value: relationshipGuids.relationship05,
      required: true,
      valid: true
    },
    childPartnerId: {
      ...relationshipV3FormFields.childPartnerId,
      value: partnerGuids.partner03,
      required: true,
      valid: true
    },
    bankName: {
      ...relationshipV3FormFields.bankName,
      value: 'CYNERGY',
      required: true,
      valid: true
    },
    processorName: {
      ...relationshipV3FormFields.processorName,
      value: 'FIRST_DATA',
      required: true,
      valid: true
    },
    processorFrontEnd: {
      ...relationshipV3FormFields.processorFrontEnd,
      value: 'test',
      required: false,
      valid: true
    },
    paymentGateway: {
      ...relationshipV3FormFields.paymentGateway,
      value: 'Test Gateway',
      required: true,
      valid: true
    },
    gatewayAffiliateId: {
      ...sharedRelationshipFormProps.gatewayAffiliateId,
      value: null,
      required: false,
      valid: true
    },
    riskProfile: {
      ...relationshipV3FormFields.riskProfile,
      value: 'standard',
      selected: 'standard',
      required: true,
      valid: true
    },
    agentChain: {
      ...relationshipV3FormFields.agentChain,
      value: '012345',
      required: false,
      valid: true
    },
    inactive: {
      ...relationshipV3FormFields.inactive,
      value: false,
      selected: 'no',
      required: true,
      valid: true
    }
  },
  [relationshipGuids.relationship03]: {
    relationshipId: {
      ...sharedRelationshipFormProps.relationshipId,
      value: relationshipGuids.relationship04,
      required: false,
      valid: true
    },
    relationshipCode: {
      ...relationshipV3FormFields.relationshipCode,
      value: '112131',
      required: true,
      valid: true
    },
    relationshipName: {
      ...relationshipV3FormFields.relationshipName,
      value: 'CrmCreateRelationshipFT',
      required: true,
      valid: true
    },
    parentRelationshipId: {
      ...relationshipV3FormFields.parentRelationshipId,
      value: relationshipGuids.relationship05,
      required: true,
      valid: true
    },
    childPartnerId: {
      ...relationshipV3FormFields.childPartnerId,
      value: partnerGuids.default,
      required: true,
      valid: true
    },
    bankName: {
      ...relationshipV3FormFields.bankName,
      value: 'CYNERGY',
      required: true,
      valid: true
    },
    processorName: {
      ...relationshipV3FormFields.processorName,
      value: 'FIRST_DATA',
      required: true,
      valid: true
    },
    processorFrontEnd: {
      ...relationshipV3FormFields.processorFrontEnd,
      value: 'test',
      required: false,
      valid: true
    },
    paymentGateway: {
      ...relationshipV3FormFields.paymentGateway,
      value: 'Test Gateway',
      required: true,
      valid: true
    },
    gatewayAffiliateId: {
      ...sharedRelationshipFormProps.gatewayAffiliateId,
      value: null,
      required: false,
      valid: true
    },
    riskProfile: {
      ...relationshipV3FormFields.riskProfile,
      value: 'elevated',
      selected: 'elevated',
      required: true,
      valid: true
    },
    agentChain: {
      ...relationshipV3FormFields.agentChain,
      value: '012345',
      required: false,
      valid: true
    },
    inactive: {
      ...relationshipV3FormFields.inactive,
      value: false,
      selected: 'no',
      required: true,
      valid: true
    }
  }
};

// POST does not take any further fields
// format from current formFields
export const mockRelationshipV2POST = {
  [relationshipGuids.default]: mockRelationshipsV2PutPost[relationshipGuids.default],
  [relationshipGuids.relationship02]: mockRelationshipsV2PutPost[relationshipGuids.relationship02],
  [relationshipGuids.relationship03]: mockRelationshipsV2PutPost[relationshipGuids.relationship03]
};

// POST relationship data formatted for BE axios Request
export const mockRelationshipV2POSTResFormatted = {
  [relationshipGuids.default]: formatPOSTData(
    mockRelationshipV2POST[relationshipGuids.default]
  ),
  [relationshipGuids.relationship02]: formatPOSTData(
    mockRelationshipV2POST[relationshipGuids.relationship02]
  ),
  [relationshipGuids.relationship03]: formatPOSTData(
    mockRelationshipV2POST[relationshipGuids.relationship03]
  )
};

// BE data that is formatted for the current formFields on prefilling the form for edit
export const mockRelationshipV2POSTToEditFormatted = {
  [relationshipGuids.default]: formatPOSTToEditData(
    mockRelationshipV2DetailGetRes[relationshipGuids.default]
  ),
  [relationshipGuids.relationship02]: formatPOSTToEditData(
    mockRelationshipV2DetailGetRes[relationshipGuids.relationship02]
  ),
  [relationshipGuids.relationship03]: formatPOSTToEditData(
    mockRelationshipV2DetailGetRes[relationshipGuids.relationship03]
  )
};

// PUT is the same as the POST.
export const mockRelationshipV2PUT = {
  [relationshipGuids.default]: mockRelationshipsV2PutPost[relationshipGuids.default],
  [relationshipGuids.relationship02]: mockRelationshipsV2PutPost[relationshipGuids.relationship02],
  [relationshipGuids.relationship03]: mockRelationshipsV2PutPost[relationshipGuids.relationship03]
};

// PUT that is formatted for the BE
export const mockRelationshipV2PUTResFormatted = {
  [relationshipGuids.default]: formatPUTData(
    mockRelationshipV2PUT[relationshipGuids.default]
  ),
  [relationshipGuids.relationship02]: formatPUTData(
    mockRelationshipV2PUT[relationshipGuids.relationship02]
  ),
  [relationshipGuids.relationship03]: formatPUTData(
    mockRelationshipV2PUT[relationshipGuids.relationship03]
  )
};

export const mockRelationshipV2Put = (body, params, options) => {
  const { guid } = options || {};
  return { [guid]: { relationshipId: 'mock-relationship-id' } };
};
