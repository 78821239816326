import { ignoreCase, isEmpty, toUpperCase } from '@f1/shared/src/_helpers';
import { nullIfOptional } from '../../../_templateHelpers';

const relationshipV2DetailTemplate = {
  frontend: (schema, version) => {
    const { data, options } = schema;
    const { relationship = {} } = data || {};
    const { isInternal = false } = options || {};
    if (version === '1.0' && data) {
      return {
        relationshipDetails: {
          generalInfo: {
            relationship: {
              relationshipId: relationship.relationshipId,
              relationshipCode: relationship.relationshipCode,
              relationshipName: relationship.relationshipName
            },
            code: relationship.relationshipCode,
            parentRelationship: {},
            childPartner: {},
            bank: relationship.bankName,
            processor: relationship.processorName,
            ...(isInternal && (relationship.processorName === 'REPAY' || !isEmpty(relationship.repaySortCode)) && {
              // this is only viewable by internal employees
              repaySortCode: nullIfOptional(relationship.repaySortCode)
            }),
            processorFrontEnd: nullIfOptional(relationship.processorFrontEnd),
            paymentGateway: relationship.paymentGateway,
            gatewayAffiliateId: nullIfOptional(relationship.gatewayAffiliateId),
            riskProfile: nullIfOptional(relationship.riskProfile),
            inactive: relationship.inactive === true ? 'Yes' : 'No',
            ...(relationship.agentChain && { agentChain: relationship.agentChain })
          }
        },
        childPartnerId: relationship.childPartnerId,
        parentRelationshipId: relationship.parentRelationshipId
      };
    }
    return schema;
  },
  frontendApiResponse: (schema, version) => {
    // Formats BE api response into the expected casing for some fields
    if (version === '1.0') {
      const { backendData = {} } = schema;
      return {
        bankName: toUpperCase(backendData.bankName),
        childPartnerId: backendData.childPartnerId,
        inactive: backendData.inactive,
        nmiAffiliateId: backendData.nmiAffiliateId,
        parentRelationshipId: backendData.parentRelationshipId,
        paymentGateway: toUpperCase(backendData.paymentGateway),
        processorFrontEnd: backendData.processorFrontEnd,
        processorName: toUpperCase(backendData.processorName),
        relationshipCode: backendData.relationshipCode,
        relationshipId: backendData.relationshipId,
        gatewayAffiliateId: backendData.gatewayAffiliateId,
        relationshipName: backendData.relationshipName,
        repaySortCode: backendData.repaySortCode,
        riskProfile: ignoreCase(backendData.riskProfile || ''),
        agentChain: backendData.agentChain
      };
    }
    return schema;
  },
  backend: (schema, version) => {
    if (version === '1.0') {
      // Just returning an empty object so we can retain the same formatting
      // across the whole codebase
      return {};
    }
    return schema;
  }
};

export default relationshipV2DetailTemplate;
