import { ignoreCase, isEmpty } from '@f1/shared/src/_helpers';
import { toBackendValue } from '@f1/shared/src/_templateHelpers';
import { sharedRelationshipFormProps, relationshipV3FormFields } from '../../../../pages/components/forms/formHelpers';
import { toFormField } from '../../../_templateHelpers';

const relationshipV2POSTTemplate = {
  frontend: (schema, version) => {
    if (version === '1.0') {
      // Just returning an empty object as there's no GET call here
      return {};
    }
    return schema;
  },
  // this is to transform data from axios request into the details databox and
  // formats the data to be inserted appropriately into the formFields upon editing
  frontendEdit: (schema, version) => {
    const { relationship } = schema;
    return {
      [sharedRelationshipFormProps.relationshipId.id]:
      toFormField(
        relationship.relationshipId,
        sharedRelationshipFormProps.relationshipId
      ),
      [relationshipV3FormFields.relationshipCode.id]:
      toFormField(
        relationship.relationshipCode,
        relationshipV3FormFields.relationshipCode
      ),
      [relationshipV3FormFields.relationshipName.id]:
      toFormField(
        relationship.relationshipName,
        relationshipV3FormFields.relationshipName
      ),
      [relationshipV3FormFields.parentRelationshipId.id]:
      toFormField(
        relationship.parentRelationshipId,
        relationshipV3FormFields.parentRelationshipId
      ),
      [relationshipV3FormFields.childPartnerId.id]:
      toFormField(
        relationship.childPartnerId,
        relationshipV3FormFields.childPartnerId
      ),
      [relationshipV3FormFields.bankName.id]:
      toFormField(
        relationship.bankName,
        relationshipV3FormFields.bankName
      ),
      [relationshipV3FormFields.processorName.id]:
      toFormField(
        relationship.processorName,
        relationshipV3FormFields.processorName
      ),
      [sharedRelationshipFormProps.repaySortCode.id]:
      toFormField(
        relationship.repaySortCode,
        sharedRelationshipFormProps.repaySortCode
      ),
      [relationshipV3FormFields.processorFrontEnd.id]:
      toFormField(
        relationship.processorFrontEnd,
        relationshipV3FormFields.processorFrontEnd
      ),
      [relationshipV3FormFields.paymentGateway.id]:
      toFormField(
        relationship.paymentGateway,
        relationshipV3FormFields.paymentGateway
      ),
      [sharedRelationshipFormProps.gatewayAffiliateId.id]:
      toFormField(
        relationship.gatewayAffiliateId,
        sharedRelationshipFormProps.gatewayAffiliateId
      ),
      [relationshipV3FormFields.riskProfile.id]:
      toFormField(
        ignoreCase(relationship.riskProfile),
        relationshipV3FormFields.riskProfile
      ),
      [relationshipV3FormFields.inactive.id]:
      toFormField(
        relationship.inactive,
        relationshipV3FormFields.inactive
      )
    };
  },
  backend: (schema, version) => {
    if (version === '1.0') {
      const { allGatewayInfoList } = schema || {};
      const processorValue = schema.processorName.value;
      const paymentGatewayValue = schema.paymentGateway.value;
      const gatewayAffiliate = !isEmpty(allGatewayInfoList)
        ? allGatewayInfoList.find(
          infoItem => (ignoreCase(infoItem.gatewayValue) === ignoreCase(paymentGatewayValue) &&
          !isEmpty(infoItem.gatewayAffiliateIdList))
        )
        : {};
      return {
        relationship: {
          ...(!isEmpty(schema?.[relationshipV3FormFields.agentChain.id]) && {
            agentChain: toBackendValue(
              schema?.[relationshipV3FormFields.agentChain.id]?.value,
              relationshipV3FormFields.agentChain
            ) || null
          }),
          relationshipCode: toBackendValue(
            schema?.[relationshipV3FormFields.relationshipCode.id]?.value,
            relationshipV3FormFields.relationshipCode
          ),
          relationshipName: toBackendValue(
            schema?.[relationshipV3FormFields.relationshipName.id]?.value,
            relationshipV3FormFields.relationshipName
          ),
          parentRelationshipId: toBackendValue(
            schema?.[relationshipV3FormFields.parentRelationshipId.id]?.value,
            relationshipV3FormFields.parentRelationshipId
          ),
          childPartnerId: toBackendValue(
            schema?.[relationshipV3FormFields.childPartnerId.id]?.value,
            relationshipV3FormFields.childPartnerId
          ),
          bankName: toBackendValue(
            schema?.[relationshipV3FormFields.bankName.id]?.value,
            relationshipV3FormFields.bankName
          ),
          processorName: toBackendValue(
            schema?.[relationshipV3FormFields.processorName.id]?.value,
            relationshipV3FormFields.processorName
          ),
          repaySortCode: processorValue === 'REPAY'
            ? toBackendValue(
              schema?.[sharedRelationshipFormProps.repaySortCode.id]?.value,
              sharedRelationshipFormProps.repaySortCode
            ).toUpperCase() || null
            : null,
          processorFrontEnd: toBackendValue(
            schema?.[relationshipV3FormFields.processorFrontEnd.id]?.value,
            relationshipV3FormFields.processorFrontEnd
          ),
          paymentGateway: toBackendValue(
            paymentGatewayValue,
            relationshipV3FormFields.paymentGateway
          ),
          gatewayAffiliateId: !isEmpty(gatewayAffiliate)
            ? toBackendValue(
              schema[sharedRelationshipFormProps.gatewayAffiliateId.id],
              sharedRelationshipFormProps.gatewayAffiliateId
            )
            : null,
          riskProfile: toBackendValue(
            schema?.[relationshipV3FormFields.riskProfile.id]?.value,
            relationshipV3FormFields.riskProfile
          ),
          inactive: toBackendValue(
            schema?.[relationshipV3FormFields.inactive.id]?.value,
            relationshipV3FormFields.inactive
          )
        }
      };
    }
    if (version === '2.0') {
      const { allGatewayInfoList } = schema || {};
      const processorValue = schema.processorName;
      const paymentGatewayValue = schema.paymentGateway;
      const gatewayAffiliate = !isEmpty(allGatewayInfoList)
        ? allGatewayInfoList.find(
          infoItem => (ignoreCase(infoItem.gatewayValue) === ignoreCase(paymentGatewayValue) &&
          !isEmpty(infoItem.gatewayAffiliateIdList))
        )
        : {};
      return {
        relationship: {
          ...(!isEmpty(schema?.[relationshipV3FormFields.agentChain.id]) && {
            agentChain: toBackendValue(
              schema?.[relationshipV3FormFields.agentChain.id],
              relationshipV3FormFields.agentChain
            ) || null
          }),
          relationshipCode: toBackendValue(
            schema?.[relationshipV3FormFields.relationshipCode.id],
            relationshipV3FormFields.relationshipCode
          ),
          relationshipName: toBackendValue(
            schema?.[relationshipV3FormFields.relationshipName.id],
            relationshipV3FormFields.relationshipName
          ),
          parentRelationshipId: toBackendValue(
            schema?.[relationshipV3FormFields.parentRelationshipId.id],
            relationshipV3FormFields.parentRelationshipId
          ),
          childPartnerId: toBackendValue(
            schema?.[relationshipV3FormFields.childPartnerId.id],
            relationshipV3FormFields.childPartnerId
          ),
          bankName: toBackendValue(
            schema?.[relationshipV3FormFields.bankName.id],
            relationshipV3FormFields.bankName
          ),
          processorName: toBackendValue(
            schema?.[relationshipV3FormFields.processorName.id],
            relationshipV3FormFields.processorName
          ),
          repaySortCode: processorValue === 'REPAY'
            ? toBackendValue(
              schema?.[sharedRelationshipFormProps.repaySortCode.id],
              sharedRelationshipFormProps.repaySortCode
            ).toUpperCase() || null
            : null,
          processorFrontEnd: toBackendValue(
            schema?.[relationshipV3FormFields.processorFrontEnd.id],
            relationshipV3FormFields.processorFrontEnd
          ),
          paymentGateway: !isEmpty(schema?.[relationshipV3FormFields.paymentGateway.id])
            ? toBackendValue(
              schema?.[relationshipV3FormFields.paymentGateway.id],
              relationshipV3FormFields.paymentGateway
            )
            : null,
          gatewayAffiliateId: !isEmpty(gatewayAffiliate)
            ? toBackendValue(
              schema[sharedRelationshipFormProps.gatewayAffiliateId.id],
              sharedRelationshipFormProps.gatewayAffiliateId
            )
            : null,
          riskProfile: toBackendValue(
            schema?.[relationshipV3FormFields.riskProfile.id],
            relationshipV3FormFields.riskProfile
          ),
          inactive: toBackendValue(
            schema?.[relationshipV3FormFields.inactive.id],
            relationshipV3FormFields.inactive
          )
        }
      };
    }
    return schema;
  }
};

export default relationshipV2POSTTemplate;
