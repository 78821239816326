import { mockEmployeeGroups } from './employeeGroup';
import { employeeGuids } from '../guids';
import { mockSignInUsers } from '../user/signIn';

const activeEmployee = {
  activated: true,
  disabled: false
};

const disabledEmployee = {
  activated: false,
  disabled: true
};

// hey you modifying this code, are you adding an employee group?
// if so, please check out this PR to make sure you are adding it everywhere needed
// BIRB 6957. Please add in all places that PR adds to
export const mockEmployees = {
  employee01: {
    firstName: 'Taylor',
    lastName: 'Swift',
    email: 'taylorSwift@f1payments.com',
    manager: employeeGuids.employee08,
    employeeId: employeeGuids.employee01,
    preferredCrabEscalationEmployee: {
      email: 'jesseJames@f1payments.com',
      firstName: 'Jesse',
      lastName: 'James',
      employeeId: employeeGuids.employee03
    }
  },
  employee02: {
    firstName: 'James',
    lastName: 'Taylor',
    email: 'f1paymentsengineering+risk1@gmail.com',
    manager: null,
    employeeId: employeeGuids.employee02,
    preferredCrabEscalationEmployee: {
      email: '',
      firstName: '',
      lastName: '',
      employeeId: ''
    }
  },
  employee03: {
    firstName: 'Jesse',
    lastName: 'James',
    email: 'jesseJames@f1payments.com',
    manager: employeeGuids.employee06,
    employeeId: employeeGuids.employee03,
    preferredCrabEscalationEmployee: {
      email: '',
      firstName: '',
      lastName: '',
      employeeId: ''
    }
  },
  employee04: {
    firstName: 'Musashi',
    lastName: 'Miyamoto',
    email: 'MusashiMiyamoto@f1payments.com',
    manager: employeeGuids.employee06,
    employeeId: employeeGuids.employee04,
    preferredCrabEscalationEmployee: {
      email: '',
      firstName: '',
      lastName: '',
      employeeId: ''
    }
  },
  employee05: {
    firstName: 'Sasaki',
    lastName: 'Kojiro',
    email: 'SasakiKojiro@f1payments.com',
    manager: employeeGuids.employee06,
    employeeId: employeeGuids.employee05,
    preferredCrabEscalationEmployee: {
      email: '',
      firstName: '',
      lastName: '',
      employeeId: ''
    }
  },
  employee06: {
    firstName: 'Shingen',
    lastName: 'Takeda',
    email: 'ShingenTakeda@f1payments.com',
    manager: null,
    employeeId: employeeGuids.employee06,
    preferredCrabEscalationEmployee: {
      email: '',
      firstName: '',
      lastName: '',
      employeeId: ''
    }
  },
  employee07: {
    firstName: 'George',
    lastName: 'Marooney',
    email: mockSignInUsers.operationsLegacy, // dev operations+1 user
    manager: null,
    employeeId: employeeGuids.employee07,
    preferredCrabEscalationEmployee: {
      email: '',
      firstName: '',
      lastName: '',
      employeeId: ''
    }
  },
  employee08: {
    firstName: 'Claudio',
    lastName: 'Sanchez',
    email: mockSignInUsers.risk, // dev risk+1 user
    manager: null,
    employeeId: employeeGuids.employee08,
    preferredCrabEscalationEmployee: {
      email: '',
      firstName: '',
      lastName: '',
      employeeId: ''
    }
  },
  employee09: {
    firstName: 'Marty',
    lastName: 'Byrde',
    email: mockSignInUsers.credit, // dev credit+1 user
    manager: null,
    employeeId: employeeGuids.employee09,
    preferredCrabEscalationEmployee: {
      email: '',
      firstName: '',
      lastName: '',
      employeeId: ''
    }
  },
  employee10: {
    firstName: 'Bruce',
    lastName: 'Wayne',
    email: mockSignInUsers.credit2, // dev credit+2 user
    manager: employeeGuids.employee09,
    employeeId: employeeGuids.employee10,
    preferredCrabEscalationEmployee: {
      email: '',
      firstName: '',
      lastName: '',
      employeeId: ''
    }
  },
  employee11: {
    firstName: 'Fred',
    lastName: 'Flintstone',
    email: mockSignInUsers.appReview, // dev app review user
    manager: employeeGuids.employee12,
    employeeId: employeeGuids.employee11,
    preferredCrabEscalationEmployee: {
      email: '',
      firstName: '',
      lastName: '',
      employeeId: ''
    }
  },
  employee12: {
    firstName: 'Barney',
    lastName: 'Fife',
    email: mockSignInUsers.appReview2, // dev app review manager user
    manager: employeeGuids.employee09,
    employeeId: employeeGuids.employee12,
    preferredCrabEscalationEmployee: {
      email: '',
      firstName: '',
      lastName: '',
      employeeId: ''
    }
  },
  employee13: {
    firstName: 'Bob',
    lastName: 'Barker',
    email: 'mockRandomAppReviewUser@corviapay.com', // stub data only - random app review user (unit tests/FTs)
    manager: employeeGuids.employee12,
    employeeId: employeeGuids.employee13,
    preferredCrabEscalationEmployee: {
      email: '',
      firstName: '',
      lastName: '',
      employeeId: ''
    }
  },
  employee14: {
    firstName: 'Jerry\u0027s',
    lastName: 'Player',
    email: mockSignInUsers.accounting,
    manager: employeeGuids.employee15,
    employeeId: employeeGuids.employee14,
    preferredCrabEscalationEmployee: {
      email: null,
      firstName: null,
      lastName: null,
      employeeId: null
    }
  },
  employee15: {
    email: mockSignInUsers.accountingManager,
    firstName: 'Jerry',
    lastName: 'Maguire',
    manager: null,
    employeeId: employeeGuids.employee15,
    preferredCrabEscalationEmployee: {
      email: null,
      firstName: null,
      lastName: null,
      employeeId: null
    }
  },
  employee16: {
    email: mockSignInUsers.riskManager,
    firstName: 'Yankee',
    lastName: 'Doodle',
    manager: null,
    employeeId: employeeGuids.employee16,
    preferredCrabEscalationEmployee: {
      email: null,
      firstName: null,
      lastName: null,
      employeeId: null
    }
  }
};

// hey you modifying this code, are you adding an employee group?
// if so, please check out this PR to make sure you are adding it everywhere needed
// BIRB 6957. Please add in all places that PR adds to
export const mockEmployeesGet = {
  employee01: {
    ...mockEmployees.employee01,
    ...activeEmployee
  },
  employee02: {
    ...mockEmployees.employee02,
    ...activeEmployee
  },
  employee03: {
    ...mockEmployees.employee03,
    ...activeEmployee
  },
  employee04: {
    ...mockEmployees.employee04,
    ...activeEmployee
  },
  employee05: {
    ...mockEmployees.employee05,
    ...activeEmployee
  },
  employee06: {
    ...mockEmployees.employee06,
    ...activeEmployee
  },
  employee07: {
    ...mockEmployees.employee07,
    ...activeEmployee
  },
  employee08: {
    ...mockEmployees.employee08,
    ...disabledEmployee
  },
  employee09: {
    ...mockEmployees.employee09,
    ...activeEmployee
  },
  employee10: {
    ...mockEmployees.employee10,
    ...activeEmployee
  },
  employee11: {
    ...mockEmployees.employee11,
    ...activeEmployee
  },
  employee12: {
    ...mockEmployees.employee12,
    ...activeEmployee
  },
  employee13: {
    ...mockEmployees.employee13,
    ...activeEmployee
  },
  employee14: {
    ...mockEmployees.employee14,
    ...activeEmployee
  },
  employee15: {
    ...mockEmployees.employee15,
    ...activeEmployee
  },
  employee16: {
    ...mockEmployees.employee16,
    ...activeEmployee
  }
};

// hey you modifying this code, are you adding an employee group?
// if so, please check out this PR to make sure you are adding it everywhere needed
// BIRB 6957. Please add in all places that PR adds to
export const mockEmployeesDetails = {
  employee01: {
    employee: {
      ...mockEmployees.employee01,
      ...activeEmployee
    },
    groups: [mockEmployeeGroups.risk, mockEmployeeGroups.riskAdmin]
  },
  employee02: {
    employee: {
      ...mockEmployees.employee02,
      ...activeEmployee
    },
    groups: [
      mockEmployeeGroups.credit,
      mockEmployeeGroups.risk,
      mockEmployeeGroups.it
    ]
  },
  employee03: {
    employee: {
      ...mockEmployees.employee03,
      ...activeEmployee
    },
    groups: [mockEmployeeGroups.risk]
  },
  employee04: {
    employee: {
      ...mockEmployees.employee04,
      ...activeEmployee
    },
    groups: [mockEmployeeGroups.appReview]
  },
  employee05: {
    employee: {
      ...mockEmployees.employee05,
      ...activeEmployee
    },
    groups: [mockEmployeeGroups.credit]
  },
  employee06: {
    employee: {
      ...mockEmployees.employee06,
      ...activeEmployee
    },
    groups: [mockEmployeeGroups.operationsLegacy]
  },
  employee07: {
    employee: {
      ...mockEmployees.employee07,
      ...activeEmployee
    },
    groups: [
      mockEmployeeGroups.operationsLegacy,
      mockEmployeeGroups.operationsManager
    ]
  },
  employee08: {
    employee: {
      ...mockEmployees.employee08,
      ...disabledEmployee
    },
    groups: [mockEmployeeGroups.risk]
  },
  employee09: {
    employee: {
      ...mockEmployees.employee09,
      ...activeEmployee
    },
    groups: [mockEmployeeGroups.credit]
  },
  employee10: {
    employee: {
      ...mockEmployees.employee10,
      ...activeEmployee
    },
    groups: [
      mockEmployeeGroups.credit,
      mockEmployeeGroups.creditManager
    ]
  },
  employee11: {
    employee: {
      ...mockEmployees.employee11,
      ...activeEmployee
    },
    groups: [
      mockEmployeeGroups.appReview
    ]
  },
  employee12: {
    employee: {
      ...mockEmployees.employee12,
      ...activeEmployee
    },
    groups: [
      mockEmployeeGroups.appReview,
      mockEmployeeGroups.appReviewManager
    ]
  },
  employee13: {
    employee: {
      ...mockEmployees.employee13,
      ...activeEmployee
    },
    groups: [
      mockEmployeeGroups.credit,
      mockEmployeeGroups.appReview
    ]
  },
  employee14: {
    employee: {
      ...mockEmployees.employee14,
      ...activeEmployee
    },
    groups: [
      mockEmployeeGroups.appReview,
      mockEmployeeGroups.residual
    ]
  },
  employee15: {
    employee: {
      ...mockEmployees.employee15,
      ...activeEmployee
    },
    groups: [
      mockEmployeeGroups.appReview,
      mockEmployeeGroups.residual
    ]
  },
  employee16: {
    employee: {
      ...mockEmployees.employee16,
      ...activeEmployee
    },
    groups: [
      mockEmployeeGroups.risk,
      mockEmployeeGroups.riskManager
    ]
  }
};
