import { snakeToTitle } from '@f1/shared/src/_helpers';

const fileInputTemplate = {
  toDataTable: (schema) => {
    const dataTableFormat = [];
    if (schema && Array.isArray(schema)) {
      schema.forEach(file => dataTableFormat.push(
        {
          fileName: file?.file?.fileName,
          type: snakeToTitle(file?.fileType),
          fileType: file?.fileType,
          timestamp: file?.file?.fileCreationTimestamp,
          deleteApp: file?.fileType
        }
      ));
      return dataTableFormat;
    }
    return dataTableFormat;
  }
};

export const getResidualsType = (file) => {
  switch (file?.fileType || file) {
    case 'payroc_input': return 'payroc_output';
    case 'repay_buyrates': return 'schedule_a_update_report';
    case 'repay_preparation_template': return 'repay_preparation';
    case 'repay_processing_template': return 'repay_processing';
    default: return 'repay_partners';
  }
};

export default fileInputTemplate;
