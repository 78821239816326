import * as React from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from '@f1/shared/src/routing/withRouter';
import {
  handleCloseSwal,
  isSidebarOpen,
  pageVisibilityApi
} from '@f1/shared/src/_helpers';
import * as actionCreators from '../redux/actions/actionCreators';
import { handleClearLocalDB } from '../utils';

function mapStateToProps (state) {
  return {
    isAuthenticated: state.authenticate.isAuthenticated,
    user: state.authenticate.user
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export class Polling extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
  }

  componentDidMount () {
    const { isAuthenticated } = this.props;
    const { visibilityChange } = pageVisibilityApi();
    if (isAuthenticated) {
      this.mounted = true;
      this.checkToken();
    }
    !isAuthenticated && handleClearLocalDB();
    document.addEventListener(visibilityChange, this.handleVisibilityChange);
  }

  componentDidUpdate (prevProps) {
    const { isAuthenticated, location } = this.props;
    if (prevProps.isAuthenticated !== isAuthenticated && isAuthenticated) {
      // user signs in
      this.checkToken();
    } else if (
      prevProps.location.state?.clearPolling !== location.state?.clearPolling &&
      location.state?.clearPolling
    ) {
      // user clicks sign out
      handleClearLocalDB();
      this.clearTimers();
    } else if (isAuthenticated) {
      // no cookie but still authenticated in store
      this.checkToken();
    } else if (prevProps.isAuthenticated !== isAuthenticated && !isAuthenticated) {
      // unauthorized user (401 error on handleApiError method)
      this.clearTimers();
      this.signOut({ clearStore: false }); // store has already been cleared
    }
  }

  componentWillUnmount () {
    const { visibilityChange } = pageVisibilityApi();
    this.clearTimers();
    document.removeEventListener(visibilityChange, this.handleVisibilityChange);
    this.mounted = false;
  }

  handleVisibilityChange = () => { // triggers when clicking on another tab & returning
    const { isAuthenticated } = this.props;
    if (isAuthenticated && document.visibilityState === 'visible') {
      this.checkToken();
    }
  }

  checkToken = () => {
    const { user } = this.props;
    const { exp = 0 } = user?.accessToken || {};
    const currentTime = Math.ceil(Date.now() / 1000);
    if (currentTime > exp) { // token expired, handle sign out
      this.signOut();
      this.clearTimers();
    } else {
      const fiveTil = exp - (5 * 60);
      if (currentTime < exp && currentTime > fiveTil) { // warn when <= 5 min remain
        this.handleWarning();
      } else if (!this.poll) { // set polling
        this.setPollInterval();
      }
    }
  }

  getRemainingTime = () => {
    const { user } = this.props;
    const { exp = 0 } = user?.accessToken || {};
    const currentTime = Math.ceil(Date.now() / 1000);
    const fiveTil = exp - (5 * 60);
    const minutesRemaining = 5 - Math.ceil((currentTime - fiveTil) / 60);
    return Math.max(1, minutesRemaining);
  }

  setPollInterval = () => {
    this.poll = setInterval(this.checkToken, (5 * 60 * 1000));
  }

  clearPollInterval = () => clearInterval(this.poll);

  setSessionTimeout = () => {
    const { user } = this.props;
    const { exp = 0 } = user?.accessToken || {};
    const currentTime = Math.ceil(Date.now() / 1000);
    const timeRemaining = (exp - currentTime) * 1000;
    this.sessionTimeout = setTimeout(this.signOut, timeRemaining);
  }

  clearSessionTimeout = () => clearTimeout(this.sessionTimeout);

  handleWarning = () => {
    this.displayWarningAlert();
    if (this.timeRemaining) {
      this.clearTimeRemainingInterval();
    }
    if (this.poll) {
      this.clearPollInterval();
    }
    if (this.sessionTimeout) {
      this.clearSessionTimeout();
    }
    this.setTimeRemainingInterval();
    this.setSessionTimeout();
  }

  setTimeRemainingInterval = () => {
    this.timeRemaining = setInterval(this.handleWarning, 1 * 60 * 1000);
  }

  displayWarningAlert = () => {
    const min = this.getRemainingTime();
    const { alertBar } = this.props;
    const warningText = `Your session is about to expire. For your security, we'll automatically sign you out in less than ${min} minute${min <= 1 ? '' : 's'}.`;
    isSidebarOpen()
      ? this.showSwal(warningText)
      : alertBar('notice', warningText);
  }

  showSwal = (warningText) => {
    swal({
      text: warningText,
      className: 'swal-corvia-default',
      icon: 'info',
      closeOnClickOutside: false,
      closeOnEsc: false
    });
  }

  clearTimeRemainingInterval = () => clearInterval(this.timeRemaining);

  clearTimers = () => {
    this.clearPollInterval();
    this.clearSessionTimeout();
    this.clearTimeRemainingInterval();
  }

  signOut = (options) => {
    const { clearStore = true } = options || {};
    const { navigate, resetStore, alertBar } = this.props;
    handleClearLocalDB();
    if (clearStore) {
      resetStore();
    }
    handleCloseSwal();
    alertBar('notice', 'Your session has expired. Please sign in again.');
    navigate('/signin');
  }

  render () {
    return (
      <div {...this.props} />
    );
  }
}

Polling.propTypes = {
  isAuthenticated: PropTypes.bool,
  user: PropTypes.shape({
    accessToken: PropTypes.shape({
      csrfToken: PropTypes.string,
      exp: PropTypes.number
    })
  }),
  navigate: PropTypes.func,
  alertBar: PropTypes.func,
  resetStore: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.shape({
      clearPolling: PropTypes.bool
    })
  })
};

Polling.defaultProps = {
  isAuthenticated: false,
  user: {
    accessToken: {
      csrfToken: '',
      exp: 0
    }
  },
  navigate: () => {},
  alertBar: () => {},
  resetStore: () => {},
  location: {
    state: {
      clearPolling: false
    }
  }
};

const withPolling = Component => withRouter(connect(mapStateToProps, mapDispatchToProps)(
  class extends Polling {
    render () {
      return <Component {...this.props} />;
    }
  }
));

export default withPolling;
