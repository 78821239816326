import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Navigate,
  Outlet,
  useLocation
} from 'react-router-dom';
import * as actionCreators from '../redux/actions/actionCreators';

function mapStateToProps (state) {
  return {
    isAuthenticated: state.authenticate.isAuthenticated,
    serverMaintenance: state.appState.serverMaintenance
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

function BoundPrivateRoute ({
  redirectPath,
  portalMerchant,
  isAuthenticated,
  serverMaintenance
}) {
  if (serverMaintenance) {
    /* istanbul ignore next */
    return (
      <Navigate to="/serverMaintenance" replace />
    );
  }
  const location = useLocation();
  if (isAuthenticated) {
    // basic check, will only be passed in PORTAL, and if the user is a merchant
    if (portalMerchant && location.pathname.includes('/application')) {
      // prevent merchants from accessing /application
      return <Navigate exact to="/dashboard" />;
    }
    return <Outlet />;
  }
  return <Navigate to={redirectPath} state={{ from: location }} replace />;
}

BoundPrivateRoute.propTypes = {
  redirectPath: PropTypes.string,
  portalMerchant: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  serverMaintenance: PropTypes.bool
};

BoundPrivateRoute.defaultProps = {
  redirectPath: '/signin',
  portalMerchant: false,
  isAuthenticated: false,
  serverMaintenance: false
};

const PrivateRoute = connect(mapStateToProps, mapDispatchToProps)(BoundPrivateRoute);

export default PrivateRoute;
