import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { getNow, ignoreCase, isEmpty } from '@f1/shared/src/_helpers';
import { ticketRequestDropdownFields } from '@f1/shared/src/_formFields';
import {
  merchantGuids,
  partnerGuids,
  relationshipGuids,
  riskRuleGuids,
  ticketGuids
} from '../guids';
import {
  mockEmployees
} from '../employee/mockEmployees';
import {
  mockEmployeeGroupGetRes,
  mockEmployeeGroups
} from '../employee/employeeGroup';

import { mockMerchantDetailsGetRes } from '../merchant/v2/mockMerchantsV2';
import mockPartner from '../partner/mockPartners';
import mockRelationship from '../relationship/mockRelationships';
import { mockRiskRules } from '../risk/rule/root';
import { getMockEmployee } from '../_mockHelpers';

const twoHours = () => {
  const currentTime = getNow().getTime();
  const updatedTime = new Date(currentTime + 2 * 60 * 60 * 1000);
  return updatedTime;
};

const mockTickets = {
  ticket01: {
    response: {
      assignedEmployee: {
        employeeId: mockEmployees.employee01.employeeId,
        employeeFirstName: mockEmployees.employee01.firstName,
        employeeLastName: mockEmployees.employee01.lastName,
        employeeEmail: mockEmployees.employee01.email
      }, // REQUIRED
      employeeGroups: [
        {
          employeeGroupId: mockEmployeeGroups.risk.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.risk.employeeGroupName
        },
        {
          employeeGroupId: mockEmployeeGroups.operations.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.operations.employeeGroupName
        },
        {
          employeeGroupId: mockEmployeeGroups.appReview.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.appReview.employeeGroupName
        }
      ], // REQUIRED
      merchant: {
        merchantGuid: merchantGuids.default,
        dbaName: mockMerchantDetailsGetRes[merchantGuids.default].merchant.dbaName
      },
      partner: {
        partnerId: partnerGuids.default,
        name: mockPartner.partner01.response.partnerName
      },
      riskRule: {
        riskRuleId: mockRiskRules.riskRule01.riskRuleId,
        name: mockRiskRules.riskRule01.name,
        primaryKeyType: 'MERCHANT_GUID'
      },
      relationship: {
        relationshipId: relationshipGuids.default,
        name: mockRelationship.relationship01.response.relationshipName
      },
      description: 'My first ticket has a description, which is this.',
      name: 'My first ticket', // REQUIRED
      priority: 'severity_5', // REQUIRED
      category: 'riskUnqueued',
      status: 'open', // REQUIRED
      wakeUpDate: null,
      snoozeActivatedDate: null,
      riskAlertPrimaryKeyValue: merchantGuids.default,
      classification: 'false_positive',
      createdBy: {
        employeeId: mockEmployees.employee03.employeeId,
        employeeFirstName: mockEmployees.employee03.firstName,
        employeeLastName: mockEmployees.employee03.lastName,
        employeeEmail: mockEmployees.employee03.email
      }, // REQUIRED - generated on creation
      ticketId: ticketGuids.ticket01, // REQUIRED - generated on creation
      ticketBusinessCode: '1234567',
      ticketClosedBy: null,
      createdTimestamp: '2020-07-23T00:23:04Z',
      watchers: [mockEmployees.employee02.email, mockEmployees.employee07.email, 'engineering@corviapay.com'],
      externalWatchers: ['testExternalEmail@test.com'],
      requiredCompletionTime: '2020-08-01T14:35:16.950',
      requestedByPortalUser: {
        portalUserId: 'portal-user-guid-01',
        email: 'createtestusershandler_withalldashboarddata@gmail.com'
      }
    }
  },
  ticket02: {
    response: {
      assignedEmployee: {
        employeeId: mockEmployees.employee02.employeeId,
        employeeFirstName: mockEmployees.employee02.firstName,
        employeeLastName: mockEmployees.employee02.lastName,
        employeeEmail: mockEmployees.employee02.email
      }, // REQUIRED
      employeeGroups: [
        {
          employeeGroupId: mockEmployeeGroups.appReview.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.appReview.employeeGroupName
        },
        {
          employeeGroupId: mockEmployeeGroups.risk.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.risk.employeeGroupName
        },
        {
          employeeGroupId: mockEmployeeGroups.operationsLegacy.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.operationsLegacy.employeeGroupName
        },
        {
          employeeGroupId: mockEmployeeGroups.credit.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.credit.employeeGroupName
        },
        {
          employeeGroupId: mockEmployeeGroups.appReview.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.appReview.employeeGroupName
        },
        {
          employeeGroupId: mockEmployeeGroups.analytics.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.analytics.employeeGroupName
        },
        {
          employeeGroupId: mockEmployeeGroups.humanResources.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.humanResources.employeeGroupName
        },
        {
          employeeGroupId: mockEmployeeGroups.operations.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.operations.employeeGroupName
        },
        {
          employeeGroupId: mockEmployeeGroups.engineering.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.engineering.employeeGroupName
        }
      ], // REQUIRED
      merchant: {
        merchantGuid: null,
        dbaName: null
      },
      partner: {
        partnerId: null,
        name: null
      },
      riskRule: {
        riskRuleId: mockRiskRules.riskRule01.riskRuleId,
        name: mockRiskRules.riskRule01.name,
        primaryKeyType: 'PARTNER_ID'
      },
      relationship: {
        relationshipId: null,
        name: null
      },
      description: null,
      name: 'important request', // REQUIRED
      priority: 'severity_4', // REQUIRED
      category: 'riskUnqueued',
      status: 'in_progress', // REQUIRED
      wakeUpDate: null,
      snoozeActivatedDate: null,
      riskAlertPrimaryKeyValue: partnerGuids.default,
      classification: 'false_positive',
      createdBy: {
        employeeId: mockEmployees.employee02.employeeId,
        employeeFirstName: mockEmployees.employee02.firstName,
        employeeLastName: mockEmployees.employee02.lastName,
        employeeEmail: mockEmployees.employee02.email
      }, // REQUIRED - generated on creation
      ticketId: ticketGuids.ticket02, // REQUIRED - generated on creation
      ticketBusinessCode: '1456780',
      ticketClosedBy: null,
      createdTimestamp: '2020-07-24T00:23:04Z',
      watchers: [mockEmployees.employee02.email],
      externalWatchers: [],
      requiredCompletionTime: '2020-08-02T14:36:16.950'
    }
  },
  ticket03: {
    response: {
      assignedEmployee: {
        employeeId: mockEmployees.employee03.employeeId,
        employeeFirstName: mockEmployees.employee03.firstName,
        employeeLastName: mockEmployees.employee03.lastName,
        employeeEmail: mockEmployees.employee03.email
      }, // REQUIRED
      employeeGroups: [
        {
          employeeGroupId: mockEmployeeGroups.operationsLegacy.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.operationsLegacy.employeeGroupName
        },
        {
          employeeGroupId: mockEmployeeGroups.appReview.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.appReview.employeeGroupName
        }
      ], // REQUIRED
      merchant: {
        merchantGuid: null,
        dbaName: null
      },
      partner: {
        partnerId: null,
        name: null
      },
      riskRule: {
        riskRuleId: mockRiskRules.riskRule01.riskRuleId,
        name: mockRiskRules.riskRule01.name,
        primaryKeyType: 'BIN_ID'
      },
      relationship: {
        relationshipId: relationshipGuids.default,
        name: mockRelationship.relationship01.response.relationshipName
      },
      description: null,
      name: 'do all the things Nero says', // REQUIRED
      priority: 'severity_1', // REQUIRED
      category: 'riskUnqueued',
      status: 'open', // REQUIRED
      ticketCompletionTimeInWorkHours: 4.22,
      ticketTimeWaitingOnNonCorviaInWorkHours: 12,
      wakeUpDate: null,
      snoozeActivatedDate: null,
      riskAlertPrimaryKeyValue: merchantGuids.default,
      classification: null,
      createdBy: {
        employeeId: mockEmployees.employee03.employeeId,
        employeeFirstName: mockEmployees.employee03.firstName,
        employeeLastName: mockEmployees.employee03.lastName,
        employeeEmail: mockEmployees.employee03.email
      }, // REQUIRED - generated on creation
      ticketId: ticketGuids.ticket03, // REQUIRED - generated on creation
      ticketBusinessCode: '1098765',
      ticketClosedBy: {
        employeeId: mockEmployees.employee03.employeeId,
        employeeFirstName: mockEmployees.employee03.firstName,
        employeeLastName: mockEmployees.employee03.lastName,
        employeeEmail: mockEmployees.employee03.email
      },
      requiredCompletionTime: getMockValue({ type: 'futureIsoDate' }),
      createdTimestamp: '2020-07-24T00:23:04Z',
      watchers: [],
      externalWatchers: [],
      closedTimestamp: '2020-07-27T00:24:04Z'
    }
  },
  ticket04: {
    response: {
      assignedEmployee: {
        employeeId: mockEmployees.employee04.employeeId,
        employeeFirstName: mockEmployees.employee04.firstName,
        employeeLastName: mockEmployees.employee04.lastName,
        employeeEmail: mockEmployees.employee04.email
      }, // REQUIRED
      employeeGroups: [
        {
          employeeGroupId: mockEmployeeGroups.crossRiver.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.crossRiver.employeeGroupName
        }
      ], // REQUIRED
      merchant: {
        merchantGuid: null,
        dbaName: null
      },
      partner: {
        partnerId: null,
        name: null
      },
      riskRule: {
        riskRuleId: mockRiskRules.riskRule01.riskRuleId,
        name: mockRiskRules.riskRule01.name,
        primaryKeyType: 'PARTNER_ID'
      },
      relationship: {
        relationshipId: null,
        name: null
      },
      description: null,
      name: 'changed my mind', // REQUIRED
      priority: 'unprioritized', // REQUIRED
      category: 'riskUnqueued',
      status: 'duplicate', // REQUIRED
      ticketCompletionTimeInWorkHours: 0.44,
      ticketTimeWaitingOnNonCorviaInWorkHours: 5.25,
      wakeUpDate: null,
      snoozeActivatedDate: null,
      riskAlertPrimaryKeyValue: partnerGuids.default,
      classification: 'true_positive',
      createdBy: {
        employeeId: mockEmployees.employee04.employeeId,
        employeeFirstName: mockEmployees.employee04.firstName,
        employeeLastName: mockEmployees.employee04.lastName,
        employeeEmail: mockEmployees.employee04.email
      }, // REQUIRED - generated on creation
      ticketId: ticketGuids.ticket04, // REQUIRED - generated on creation
      ticketBusinessCode: '8765432',
      ticketClosedBy: {
        employeeId: null,
        employeeFirstName: null,
        employeeLastName: null,
        employeeEmail: null
      },
      createdTimestamp: '2020-07-24T00:23:04Z',
      watchers: [],
      externalWatchers: [],
      closedTimestamp: '2020-07-29T00:23:04Z',
      requiredCompletionTime: '2020-08-03T14:37:16.950'
    }
  },
  ticket05: {
    response: {
      assignedEmployee: {
        employeeId: mockEmployees.employee03.employeeId,
        employeeFirstName: mockEmployees.employee03.firstName,
        employeeLastName: mockEmployees.employee03.lastName,
        employeeEmail: mockEmployees.employee03.email
      }, // REQUIRED
      employeeGroups: [
        {
          employeeGroupId: mockEmployeeGroups.risk.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.risk.employeeGroupName
        }
      ], // REQUIRED
      merchant: {
        merchantGuid: merchantGuids.default,
        dbaName: mockMerchantDetailsGetRes[merchantGuids.default].merchant.dbaName
      },
      partner: {
        partnerId: partnerGuids.default,
        name: mockPartner.partner01.response.partnerName
      },
      riskRule: {
        riskRuleId: mockRiskRules.riskRule01.riskRuleId,
        name: mockRiskRules.riskRule01.name,
        primaryKeyType: 'PERSON_PII_SSN_ID'
      },
      relationship: {
        relationshipId: relationshipGuids.default,
        name: mockRelationship.relationship01.response.relationshipName
      },
      description: 'Organize a bunch of party stuff',
      name: 'Party Planning', // REQUIRED
      priority: 'severity_3', // REQUIRED
      category: 'riskUnqueued',
      status: 'open', // REQUIRED
      wakeUpDate: null,
      snoozeActivatedDate: null,
      riskAlertPrimaryKeyValue: '123445678',
      classification: 'false_positive',
      createdBy: {
        employeeId: mockEmployees.employee03.employeeId,
        employeeFirstName: mockEmployees.employee03.firstName,
        employeeLastName: mockEmployees.employee03.lastName,
        employeeEmail: mockEmployees.employee03.email
      }, // REQUIRED - generated on creation
      ticketId: ticketGuids.ticket05, // REQUIRED - generated on creation
      ticketBusinessCode: '4477744',
      ticketClosedBy: null,
      createdTimestamp: '2020-07-25T00:23:04Z',
      watchers: [],
      externalWatchers: [],
      requiredCompletionTime: '2020-08-04T14:38:16.950'
    }
  },
  ticket06: {
    response: {
      assignedEmployee: {
        employeeId: mockEmployees.employee01.employeeId,
        employeeFirstName: mockEmployees.employee01.firstName,
        employeeLastName: mockEmployees.employee01.lastName,
        employeeEmail: mockEmployees.employee01.email
      }, // REQUIRED
      employeeGroups: [
        {
          employeeGroupId: mockEmployeeGroups.risk.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.risk.employeeGroupName
        },
        {
          employeeGroupId: mockEmployeeGroups.operations.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.operations.employeeGroupName
        },
        {
          employeeGroupId: mockEmployeeGroups.appReview.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.appReview.employeeGroupName
        }
      ], // REQUIRED
      merchant: {
        merchantGuid: merchantGuids.default,
        dbaName: mockMerchantDetailsGetRes[merchantGuids.default].merchant.dbaName
      },
      partner: {
        partnerId: partnerGuids.default,
        name: mockPartner.partner01.response.partnerName
      },
      riskRule: {
        riskRuleId: mockRiskRules.riskRule01.riskRuleId,
        name: mockRiskRules.riskRule01.name,
        primaryKeyType: 'MERCHANT_EIN_ID'
      },
      relationship: {
        relationshipId: relationshipGuids.default,
        name: mockRelationship.relationship01.response.relationshipName
      },
      description: 'Talk a lot of business and get things done',
      name: 'New/Existing Partner Hierarchy Request', // REQUIRED
      priority: 'severity_2', // REQUIRED
      category: null,
      status: 'open', // REQUIRED
      wakeUpDate: null,
      snoozeActivatedDate: null,
      riskAlertPrimaryKeyValue: null,
      classification: null,
      createdBy: {
        employeeId: mockEmployees.employee01.employeeId,
        employeeFirstName: mockEmployees.employee01.firstName,
        employeeLastName: mockEmployees.employee01.lastName,
        employeeEmail: mockEmployees.employee01.email
      }, // REQUIRED - generated on creation
      ticketId: ticketGuids.ticket06, // REQUIRED - generated on creation
      ticketBusinessCode: '9901823',
      ticketClosedBy: null,
      createdTimestamp: '2020-07-26T00:23:04Z',
      watchers: [],
      externalWatchers: [],
      requiredCompletionTime: '2020-08-05T14:39:16.950'
    }
  },
  ticket07: {
    response: {
      assignedEmployee: {
        employeeId: mockEmployees.employee02.employeeId,
        employeeFirstName: mockEmployees.employee02.firstName,
        employeeLastName: mockEmployees.employee02.lastName,
        employeeEmail: mockEmployees.employee02.email
      }, // REQUIRED
      employeeGroups: [
        {
          employeeGroupId: mockEmployeeGroups.risk.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.risk.employeeGroupName
        },
        {
          employeeGroupId: mockEmployeeGroups.operations.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.operations.employeeGroupName
        }
      ], // REQUIRED
      merchant: {
        merchantGuid: merchantGuids.default,
        dbaName: mockMerchantDetailsGetRes[merchantGuids.default].merchant.dbaName
      },
      partner: {
        partnerId: partnerGuids.default,
        name: mockPartner.partner01.response.partnerName
      },
      riskRule: {
        riskRuleId: mockRiskRules.riskRule01.riskRuleId,
        name: mockRiskRules.riskRule01.name,
        primaryKeyType: 'MERCHANT_GUID'
      },
      relationship: {
        relationshipId: relationshipGuids.default,
        name: mockRelationship.relationship01.response.relationshipName
      },
      description: 'Lots of tickets for everyone',
      name: 'Create more tickets', // REQUIRED
      priority: 'severity_4', // REQUIRED
      category: 'riskUnqueued',
      status: 'open', // REQUIRED
      wakeUpDate: null,
      snoozeActivatedDate: null,
      riskAlertPrimaryKeyValue: merchantGuids.default,
      classification: 'true_positive',
      createdBy: {
        employeeId: mockEmployees.employee03.employeeId,
        employeeFirstName: mockEmployees.employee03.firstName,
        employeeLastName: mockEmployees.employee03.lastName,
        employeeEmail: mockEmployees.employee03.email
      }, // REQUIRED - generated on creation
      ticketId: ticketGuids.ticket07, // REQUIRED - generated on creation
      ticketBusinessCode: '1113366',
      ticketClosedBy: null,
      createdTimestamp: '2020-07-27T00:23:04Z',
      watchers: [mockEmployees.employee11.email],
      externalWatchers: ['testExternalEmail@test.com'],
      requiredCompletionTime: '2020-08-06T14:40:16.950'
    }
  },
  ticket08: {
    response: {
      assignedEmployee: {
        employeeId: mockEmployees.employee03.employeeId,
        employeeFirstName: mockEmployees.employee03.firstName,
        employeeLastName: mockEmployees.employee03.lastName,
        employeeEmail: mockEmployees.employee03.email
      }, // REQUIRED
      employeeGroups: [
        {
          employeeGroupId: mockEmployeeGroups.risk.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.risk.employeeGroupName
        },
        {
          employeeGroupId: mockEmployeeGroups.appReview.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.appReview.employeeGroupName
        }
      ], // REQUIRED
      merchant: {
        merchantGuid: merchantGuids.default,
        dbaName: mockMerchantDetailsGetRes[merchantGuids.default].merchant.dbaName
      },
      partner: {
        partnerId: partnerGuids.default,
        name: mockPartner.partner01.response.partnerName
      },
      riskRule: {
        riskRuleId: mockRiskRules.riskRule01.riskRuleId,
        name: mockRiskRules.riskRule01.name,
        primaryKeyType: 'PARTNER_ID'
      },
      relationship: {
        relationshipId: relationshipGuids.default,
        name: mockRelationship.relationship01.response.relationshipName
      },
      description: 'Work play, work play, work life balance',
      name: 'Create a Balance', // REQUIRED
      priority: 'severity_3', // REQUIRED
      category: 'riskUnqueued',
      status: 'open', // REQUIRED
      wakeUpDate: null,
      snoozeActivatedDate: null,
      riskAlertPrimaryKeyValue: partnerGuids.default,
      classification: 'false_positive',
      createdBy: {
        employeeId: mockEmployees.employee01.employeeId,
        employeeFirstName: mockEmployees.employee01.firstName,
        employeeLastName: mockEmployees.employee01.lastName,
        employeeEmail: mockEmployees.employee01.email
      }, // REQUIRED - generated on creation
      ticketId: ticketGuids.ticket08, // REQUIRED - generated on creation
      ticketBusinessCode: '4282693',
      ticketClosedBy: null,
      createdTimestamp: '2020-07-28T00:23:04Z',
      watchers: [mockEmployees.employee10.email],
      externalWatchers: ['testExternalEmail@test.com'],
      requiredCompletionTime: '2020-08-07T14:41:16.950'
    }
  },
  ticket09: {
    response: {
      assignedEmployee: {
        employeeId: mockEmployees.employee03.employeeId,
        employeeFirstName: mockEmployees.employee03.firstName,
        employeeLastName: mockEmployees.employee03.lastName,
        employeeEmail: mockEmployees.employee03.email
      }, // REQUIRED
      employeeGroups: [
        {
          employeeGroupId: mockEmployeeGroups.risk.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.risk.employeeGroupName
        },
        {
          employeeGroupId: mockEmployeeGroups.appReview.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.appReview.employeeGroupName
        }
      ], // REQUIRED
      merchant: {
        merchantGuid: merchantGuids.default,
        dbaName: mockMerchantDetailsGetRes[merchantGuids.default].merchant.dbaName
      },
      partner: {
        partnerId: partnerGuids.default,
        name: mockPartner.partner01.response.partnerName
      },
      riskRule: {
        riskRuleId: mockRiskRules.riskRule01.riskRuleId,
        name: mockRiskRules.riskRule01.name,
        primaryKeyType: 'MERCHANT_GUID'
      },
      relationship: {
        relationshipId: relationshipGuids.default,
        name: mockRelationship.relationship01.response.relationshipName
      },
      description: 'Eat sleep eat sleep balance',
      name: 'Create Weight Gain', // REQUIRED
      priority: 'severity_2', // REQUIRED
      category: 'riskUnqueued',
      status: 'will_not_complete', // REQUIRED
      ticketCompletionTimeInWorkHours: 0.33,
      ticketTimeWaitingOnNonCorviaInWorkHours: 1.50,
      wakeUpDate: null,
      snoozeActivatedDate: null,
      riskAlertPrimaryKeyValue: merchantGuids.default,
      classification: null,
      createdBy: {
        employeeId: mockEmployees.employee01.employeeId,
        employeeFirstName: mockEmployees.employee01.firstName,
        employeeLastName: mockEmployees.employee01.lastName,
        employeeEmail: mockEmployees.employee01.email
      }, // REQUIRED - generated on creation
      ticketId: ticketGuids.ticket09, // REQUIRED - generated on creation
      ticketBusinessCode: '4282693',
      ticketClosedBy: {
        employeeId: mockEmployees.employee03.employeeId,
        employeeFirstName: mockEmployees.employee03.firstName,
        employeeLastName: mockEmployees.employee03.lastName,
        employeeEmail: mockEmployees.employee03.email
      },
      createdTimestamp: '2020-08-01T08:21:36Z',
      watchers: [],
      externalWatchers: [],
      requiredCompletionTime: '2020-08-07T14:41:16.950',
      closedTimestamp: '2020-08-01T08:45:36Z'
    }
  },
  ticket10: {
    response: {
      assignedEmployee: {
        employeeId: mockEmployees.employee02.employeeId,
        employeeFirstName: mockEmployees.employee02.firstName,
        employeeLastName: mockEmployees.employee02.lastName,
        employeeEmail: mockEmployees.employee02.email
      }, // REQUIRED
      employeeGroups: [
        {
          employeeGroupId: mockEmployeeGroups.appReview.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.appReview.employeeGroupName
        }
      ], // REQUIRED
      merchant: {
        merchantGuid: null,
        dbaName: null
      },
      partner: {
        partnerId: null,
        name: null
      },
      riskRule: {
        riskRuleId: mockRiskRules.riskRule01.riskRuleId,
        name: mockRiskRules.riskRule01.name,
        primaryKeyType: 'MERCHANT_GUID'
      },
      relationship: {
        relationshipId: null,
        name: null
      },
      description: null,
      name: 'not important request', // REQUIRED
      priority: 'severity_3', // REQUIRED
      category: 'riskUnqueued',
      status: 'in_progress', // REQUIRED
      wakeUpDate: null,
      snoozeActivatedDate: null,
      riskAlertPrimaryKeyValue: merchantGuids.default,
      classification: 'true_positive',
      createdBy: {
        employeeId: mockEmployees.employee02.employeeId,
        employeeFirstName: mockEmployees.employee02.firstName,
        employeeLastName: mockEmployees.employee02.lastName,
        employeeEmail: mockEmployees.employee02.email
      }, // REQUIRED - generated on creation
      ticketId: ticketGuids.ticket10, // REQUIRED - generated on creation
      ticketBusinessCode: '1456789',
      ticketClosedBy: null,
      createdTimestamp: '2020-07-26T00:23:04Z',
      watchers: [],
      externalWatchers: [],
      requiredCompletionTime: '2020-08-08T14:42:16.950',
      ticketCompletionTimeInWorkHours: 11,
      ticketTimeWaitingOnNonCorviaInWorkHours: 24
    }
  },
  // Approval request specific
  ticket11: {
    response: {
      assignedEmployee: {
        employeeId: mockEmployees.employee02.employeeId,
        employeeFirstName: mockEmployees.employee02.firstName,
        employeeLastName: mockEmployees.employee02.lastName,
        employeeEmail: mockEmployees.employee02.email
      }, // REQUIRED
      employeeGroups: [
        {
          employeeGroupId: mockEmployeeGroups.appReview.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.appReview.employeeGroupName
        }
      ], // REQUIRED
      merchant: {
        merchantGuid: merchantGuids.default,
        dbaName: mockMerchantDetailsGetRes[merchantGuids.default].merchant.dbaName
      },
      partner: {
        partnerId: null,
        name: null
      },
      riskRule: {
        riskRuleId: mockRiskRules.riskRule01.riskRuleId,
        name: mockRiskRules.riskRule01.name,
        primaryKeyType: 'PARTNER_ID'
      },
      relationship: {
        relationshipId: null,
        name: null
      },
      description: 'Merchant Closure Details:\nMID : ExtApiMidSettlement-1_3\nClosure Reason : MERCHANT_REQUEST\nClosed Date : 2021-07-27\nRisk Termination : NO\nRisk Details : GATEWAY_NON_COMPLIANCE\nEtf Fee Amount : NA',
      name: 'Approval Request test', // REQUIRED
      priority: 'severity_1', // REQUIRED
      category: 'approvalRequest',
      status: 'open', // REQUIRED
      wakeUpDate: null,
      snoozeActivatedDate: null,
      riskAlertPrimaryKeyValue: partnerGuids.default,
      classification: 'false_positive',
      createdBy: {
        employeeId: mockEmployees.employee02.employeeId,
        employeeFirstName: mockEmployees.employee02.firstName,
        employeeLastName: mockEmployees.employee02.lastName,
        employeeEmail: mockEmployees.employee02.email
      }, // REQUIRED - generated on creation
      ticketId: ticketGuids.ticket11, // REQUIRED - generated on creation
      ticketBusinessCode: '1456789',
      ticketClosedBy: null,
      createdTimestamp: '2020-08-02T00:23:04Z',
      watchers: [],
      externalWatchers: [],
      requiredCompletionTime: '2020-08-09T14:43:16.950'
    }
  },
  ticket12: {
    response: {
      assignedEmployee: {
        employeeId: mockEmployees.employee02.employeeId,
        employeeFirstName: mockEmployees.employee02.firstName,
        employeeLastName: mockEmployees.employee02.lastName,
        employeeEmail: mockEmployees.employee02.email
      }, // REQUIRED
      employeeGroups: [
        {
          employeeGroupId: mockEmployeeGroups.appReview.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.appReview.employeeGroupName
        }
      ], // REQUIRED
      merchant: {
        merchantGuid: merchantGuids.default,
        dbaName: mockMerchantDetailsGetRes[merchantGuids.default].merchant.dbaName
      },
      partner: {
        partnerId: null,
        name: null
      },
      riskRule: {
        riskRuleId: mockRiskRules.riskRule01.riskRuleId,
        name: mockRiskRules.riskRule01.name,
        primaryKeyType: 'PERSON_PII_SSN_ID'
      },
      relationship: {
        relationshipId: null,
        name: null
      },
      description: 'Merchant Closure Details:\nMID : ExtApiMidSettlement-1_3\nClosure Reason : MERCHANT_REQUEST\nClosed Date : 2021-07-27\nRisk Termination : NO\nRisk Details : GATEWAY_NON_COMPLIANCE\nEtf Fee Amount : NA',
      name: 'Approval Request test', // REQUIRED
      priority: 'severity_2', // REQUIRED
      category: 'approvalRequest',
      status: 'approved', // REQUIRED
      ticketCompletionTimeInWorkHours: 6.25,
      ticketTimeWaitingOnNonCorviaInWorkHours: 15.75,
      wakeUpDate: null,
      snoozeActivatedDate: null,
      riskAlertPrimaryKeyValue: '111223333',
      classification: null,
      createdBy: {
        employeeId: mockEmployees.employee02.employeeId,
        employeeFirstName: mockEmployees.employee02.firstName,
        employeeLastName: mockEmployees.employee02.lastName,
        employeeEmail: mockEmployees.employee02.email
      }, // REQUIRED - generated on creation
      ticketId: ticketGuids.ticket12, // REQUIRED - generated on creation
      ticketBusinessCode: '1556789',
      ticketClosedBy: null,
      createdTimestamp: '2020-08-02T00:23:04Z',
      watchers: [],
      externalWatchers: [],
      closedTimestamp: '2021-07-01T00:24:04Z',
      requiredCompletionTime: '2020-08-09T14:43:16.950'
    }
  },
  // SYSTEM created (external user)
  ticket13: {
    response: {
      assignedEmployee: {
        employeeId: mockEmployees.employee06.employeeId,
        employeeFirstName: mockEmployees.employee06.firstName,
        employeeLastName: mockEmployees.employee06.lastName,
        employeeEmail: mockEmployees.employee06.email
      }, // REQUIRED
      employeeGroups: [
        {
          employeeGroupId: mockEmployeeGroups.operations.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.operations.employeeGroupName
        }
      ], // REQUIRED
      merchant: {
        merchantGuid: null,
        dbaName: null
      },
      partner: {
        partnerId: partnerGuids.default,
        name: mockPartner.partner01.response.partnerName
      },
      riskRule: {
        riskRuleId: null,
        name: null,
        primaryKeyType: null
      },
      relationship: {
        relationshipId: null,
        name: null
      },
      description: 'I am looking to get test changed to test. Can you help?',
      name: 'Other', // REQUIRED
      priority: 'severity_4', // REQUIRED
      category: null,
      status: 'open', // REQUIRED
      wakeUpDate: null,
      snoozeActivatedDate: null,
      riskAlertPrimaryKeyValue: null,
      classification: 'false_negative',
      // Created by fields are all blank if created by SYSTEM
      createdBy: {
        employeeId: null,
        employeeFirstName: null,
        employeeLastName: null,
        employeeEmail: null
      }, // REQUIRED - generated on creation
      ticketId: ticketGuids.ticket13, // REQUIRED - generated on creation
      ticketBusinessCode: '1234568',
      ticketClosedBy: null,
      createdTimestamp: '2020-08-02T00:23:04Z',
      watchers: [],
      externalWatchers: [],
      requiredCompletionTime: '2020-08-10T14:44:16.950'
    }
  },
  // Merchant Specific
  ticketMerchant01: {
    response: {
      assignedEmployee: {
        employeeId: mockEmployees.employee05.employeeId,
        employeeFirstName: mockEmployees.employee05.firstName,
        employeeLastName: mockEmployees.employee05.lastName,
        employeeEmail: mockEmployees.employee05.email
      }, // REQUIRED
      employeeGroups: [
        {
          employeeGroupId: mockEmployeeGroups.analytics.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.analytics.employeeGroupName
        }
      ], // REQUIRED
      merchant: {
        merchantGuid: merchantGuids.default,
        dbaName: mockMerchantDetailsGetRes[merchantGuids.default].merchant.dbaName
      },
      partner: {
        partnerId: null,
        name: null
      },
      riskRule: {
        riskRuleId: mockRiskRules.riskRule01.riskRuleId,
        name: mockRiskRules.riskRule01.name,
        partnerGuids: 'MERCHANT_GUID'
      },
      relationship: {
        relationshipId: null,
        name: null
      },
      description: null,
      name: 'merchant do all the things Nero says', // REQUIRED
      priority: 'severity_2', // REQUIRED
      category: 'riskUnqueued',
      status: 'will_not_complete', // REQUIRED
      ticketCompletionTimeInWorkHours: 1.75,
      ticketTimeWaitingOnNonCorviaInWorkHours: 3,
      wakeUpDate: null,
      snoozeActivatedDate: null,
      riskAlertPrimaryKeyValue: merchantGuids.default,
      classification: 'true_positive',
      createdBy: {
        employeeId: mockEmployees.employee05.employeeId,
        employeeFirstName: mockEmployees.employee05.firstName,
        employeeLastName: mockEmployees.employee05.lastName,
        employeeEmail: mockEmployees.employee05.email
      }, // REQUIRED - generated on creation
      ticketId: ticketGuids.default, // REQUIRED - generated on creation
      ticketBusinessCode: '1098765',
      ticketClosedBy: {
        employeeId: null,
        employeeFirstName: null,
        employeeLastName: null,
        employeeEmail: null
      },
      createdTimestamp: '2020-07-31T00:22:04Z',
      watchers: [],
      externalWatchers: [],
      requiredCompletionTime: '2020-08-11T14:45:16.950',
      closedTimestamp: '2021-01-17T00:23:45Z'
    }
  },
  // Partner Specific
  ticketPartner01: {
    response: {
      assignedEmployee: {
        employeeId: mockEmployees.employee06.employeeId,
        employeeFirstName: mockEmployees.employee06.firstName,
        employeeLastName: mockEmployees.employee06.lastName,
        employeeEmail: mockEmployees.employee06.email
      }, // REQUIRED
      employeeGroups: [
        {
          employeeGroupId: mockEmployeeGroups.engineering.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.engineering.employeeGroupName
        },
        {
          employeeGroupId: mockEmployeeGroups.credit.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.credit.employeeGroupName
        },
        {
          employeeGroupId: mockEmployeeGroups.appReview.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.appReview.employeeGroupName
        }
      ], // REQUIRED
      merchant: {
        merchantGuid: null,
        dbaName: null
      },
      partner: {
        partnerId: partnerGuids.default,
        name: mockPartner.partner01.response.partnerName
      },
      riskRule: {
        riskRuleId: mockRiskRules.riskRule01.riskRuleId,
        name: mockRiskRules.riskRule01.name,
        primaryKeyType: 'PARTNER_ID'
      },
      relationship: {
        relationshipId: null,
        name: null
      },
      description: null,
      name: 'partner not important request', // REQUIRED
      priority: 'severity_4', // REQUIRED
      category: 'riskUnqueued',
      status: 'duplicate', // REQUIRED
      ticketCompletionTimeInWorkHours: 2.67,
      ticketTimeWaitingOnNonCorviaInWorkHours: 9.12,
      wakeUpDate: null,
      snoozeActivatedDate: null,
      riskAlertPrimaryKeyValue: partnerGuids.default,
      classification: 'false_positive',
      createdBy: {
        employeeId: mockEmployees.employee06.employeeId,
        employeeFirstName: mockEmployees.employee06.firstName,
        employeeLastName: mockEmployees.employee06.lastName,
        employeeEmail: mockEmployees.employee06.email
      }, // REQUIRED - generated on creation
      ticketId: ticketGuids.ticket02, // REQUIRED - generated on creation
      ticketBusinessCode: '1456789',
      ticketClosedBy: {
        employeeId: mockEmployees.employee05.employeeId,
        employeeFirstName: mockEmployees.employee05.firstName,
        employeeLastName: mockEmployees.employee05.lastName,
        employeeEmail: mockEmployees.employee05.email
      },
      createdTimestamp: '2020-08-01T00:23:04Z',
      watchers: [],
      externalWatchers: [],
      closedTimestamp: '2020-08-09T00:20:04Z',
      requiredCompletionTime: '2020-08-12T14:46:16.950'
    }
  },
  // Relationship Specific
  ticketRelationship01: {
    response: {
      assignedEmployee: {
        employeeId: mockEmployees.employee06.employeeId,
        employeeFirstName: mockEmployees.employee06.firstName,
        employeeLastName: mockEmployees.employee06.lastName,
        employeeEmail: mockEmployees.employee06.email
      }, // REQUIRED
      employeeGroups: [
        {
          employeeGroupId: mockEmployeeGroups.cynergy.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.cynergy.employeeGroupName
        },
        {
          employeeGroupId: mockEmployeeGroups.fifthThird.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.fifthThird.employeeGroupName
        },
        {
          employeeGroupId: mockEmployeeGroups.crossRiver.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.crossRiver.employeeGroupName
        }
      ], // REQUIRED
      merchant: {
        merchantGuid: null,
        dbaName: null
      },
      partner: {
        partnerId: null,
        name: null
      },
      riskRule: {
        riskRuleId: mockRiskRules.riskRule01.riskRuleId,
        name: mockRiskRules.riskRule01.name,
        primaryKeyType: 'PARTNER_ID'
      },
      relationship: {
        relationshipId: relationshipGuids.default,
        name: mockRelationship.relationship01.response.relationshipName
      },
      description: null,
      name: 'relationship select statement violation', // REQUIRED
      priority: 'severity_3', // REQUIRED
      category: 'riskUnqueued',
      status: 'open', // REQUIRED
      wakeUpDate: null,
      snoozeActivatedDate: null,
      riskAlertPrimaryKeyValue: partnerGuids.default,
      classification: null,
      createdBy: {
        employeeId: mockEmployees.employee06.employeeId,
        employeeFirstName: mockEmployees.employee06.firstName,
        employeeLastName: mockEmployees.employee06.lastName,
        employeeEmail: mockEmployees.employee06.email
      }, // REQUIRED - generated on creation
      ticketId: ticketGuids.ticket03, // REQUIRED - generated on creation
      ticketBusinessCode: '1234568',
      ticketClosedBy: null,
      createdTimestamp: '2020-08-01T00:23:04Z',
      watchers: [],
      externalWatchers: [],
      requiredCompletionTime: '2020-08-13T14:47:16.950'
    }
  },
  // Snoozed Specific
  ticketSnoozed01: {
    response: {
      assignedEmployee: {
        employeeId: mockEmployees.employee06.employeeId,
        employeeFirstName: mockEmployees.employee06.firstName,
        employeeLastName: mockEmployees.employee06.lastName,
        employeeEmail: mockEmployees.employee06.email
      }, // REQUIRED
      employeeGroups: [
        {
          employeeGroupId: mockEmployeeGroups.operations.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.operations.employeeGroupName
        }
      ], // REQUIRED
      merchant: {
        merchantGuid: null,
        dbaName: null
      },
      partner: {
        partnerId: null,
        name: null
      },
      riskRule: {
        riskRuleId: mockRiskRules.riskRule01.riskRuleId,
        name: mockRiskRules.riskRule01.name,
        primaryKeyType: 'PARTNER_ID'
      },
      relationship: {
        relationshipId: relationshipGuids.default,
        name: mockRelationship.relationship01.response.relationshipName
      },
      description: null,
      name: 'Snoozed Today', // REQUIRED
      priority: 'severity_4', // REQUIRED
      category: 'riskUnqueued',
      status: 'snoozed', // REQUIRED
      wakeUpDate: '2020-08-05',
      snoozeActivatedDate: '2020-08-02',
      riskAlertPrimaryKeyValue: partnerGuids.default,
      classification: null,
      createdBy: {
        employeeId: mockEmployees.employee06.employeeId,
        employeeFirstName: mockEmployees.employee06.firstName,
        employeeLastName: mockEmployees.employee06.lastName,
        employeeEmail: mockEmployees.employee06.email
      }, // REQUIRED - generated on creation
      ticketId: ticketGuids.ticket14, // REQUIRED - generated on creation
      ticketBusinessCode: '1234568',
      ticketClosedBy: null,
      createdTimestamp: '2020-08-02T00:23:04Z',
      watchers: [],
      externalWatchers: [],
      requiredCompletionTime: '2020-08-14T14:48:16.950'
    }
  },
  ticketSnoozed02: {
    response: {
      assignedEmployee: {
        employeeId: mockEmployees.employee06.employeeId,
        employeeFirstName: mockEmployees.employee06.firstName,
        employeeLastName: mockEmployees.employee06.lastName,
        employeeEmail: mockEmployees.employee06.email
      }, // REQUIRED
      employeeGroups: [
        {
          employeeGroupId: mockEmployeeGroups.risk.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.risk.employeeGroupName
        }
      ], // REQUIRED
      merchant: {
        merchantGuid: null,
        dbaName: null
      },
      partner: {
        partnerId: null,
        name: null
      },
      riskRule: {
        riskRuleId: mockRiskRules.riskRule01.riskRuleId,
        name: mockRiskRules.riskRule01.name,
        primaryKeyType: 'PARTNER_ID'
      },
      relationship: {
        relationshipId: relationshipGuids.default,
        name: mockRelationship.relationship01.response.relationshipName
      },
      description: null,
      name: 'Wakes Up Today', // REQUIRED
      priority: 'severity_3', // REQUIRED
      category: 'riskUnqueued',
      status: 'snoozed', // REQUIRED
      wakeUpDate: '2020-08-02',
      snoozeActivatedDate: '2020-08-01',
      riskAlertPrimaryKeyValue: partnerGuids.default,
      classification: null,
      createdBy: {
        employeeId: mockEmployees.employee06.employeeId,
        employeeFirstName: mockEmployees.employee06.firstName,
        employeeLastName: mockEmployees.employee06.lastName,
        employeeEmail: mockEmployees.employee06.email
      }, // REQUIRED - generated on creation
      ticketId: ticketGuids.ticket15, // REQUIRED - generated on creation
      ticketBusinessCode: '1234568',
      ticketClosedBy: null,
      createdTimestamp: '2020-08-01T00:23:04Z',
      watchers: [],
      externalWatchers: [],
      requiredCompletionTime: '2020-08-14T14:48:16.950'
    }
  },
  ticketWithDeprecatedPriority: {
    response: {
      assignedEmployee: {
        employeeId: mockEmployees.employee06.employeeId,
        employeeFirstName: mockEmployees.employee06.firstName,
        employeeLastName: mockEmployees.employee06.lastName,
        employeeEmail: mockEmployees.employee06.email
      }, // REQUIRED
      employeeGroups: [
        {
          employeeGroupId: mockEmployeeGroups.risk.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.risk.employeeGroupName
        }
      ], // REQUIRED
      merchant: {
        merchantGuid: null,
        dbaName: null
      },
      partner: {
        partnerId: null,
        name: null
      },
      riskRule: {
        riskRuleId: mockRiskRules.riskRule01.riskRuleId,
        name: mockRiskRules.riskRule01.name,
        primaryKeyType: 'PARTNER_ID'
      },
      relationship: {
        relationshipId: relationshipGuids.default,
        name: mockRelationship.relationship01.response.relationshipName
      },
      description: null,
      name: 'I had a deprecated priority', // REQUIRED
      priority: 'high', // REQUIRED
      category: 'riskUnqueued',
      status: 'open', // REQUIRED
      wakeUpDate: null,
      snoozeActivatedDate: null,
      riskAlertPrimaryKeyValue: partnerGuids.default,
      classification: null,
      createdBy: {
        employeeId: mockEmployees.employee06.employeeId,
        employeeFirstName: mockEmployees.employee06.firstName,
        employeeLastName: mockEmployees.employee06.lastName,
        employeeEmail: mockEmployees.employee06.email
      }, // REQUIRED - generated on creation
      ticketId: ticketGuids.ticket16, // REQUIRED - generated on creation
      ticketBusinessCode: '1234568',
      ticketClosedBy: null,
      requiredCompletionTime: twoHours().toISOString(), // REQUIRED for testing imminent due time
      createdTimestamp: '2021-01-16T00:23:04Z',
      watchers: [mockEmployees.employee09.email, mockEmployees.employee06.email],
      externalWatchers: ['testExternalEmail@test.com']
    }
  },
  systemGeneratedTicket: {
    response: {
      assignedEmployee: {
        employeeId: mockEmployees.employee06.employeeId,
        employeeFirstName: mockEmployees.employee06.firstName,
        employeeLastName: mockEmployees.employee06.lastName,
        employeeEmail: mockEmployees.employee06.email
      }, // REQUIRED
      employeeGroups: [
        {
          employeeGroupId: mockEmployeeGroups.sales.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.sales.employeeGroupName
        },
        {
          employeeGroupId: mockEmployeeGroups.operations.employeeGroupId,
          employeeGroupName: mockEmployeeGroups.operations.employeeGroupName
        }
      ], // REQUIRED
      merchant: {
        merchantGuid: null,
        dbaName: null
      },
      partner: {
        partnerId: null,
        name: null
      },
      riskRule: {},
      relationship: {
        relationshipId: relationshipGuids.default,
        name: mockRelationship.relationship01.response.relationshipName
      },
      description: null,
      name: 'Generated by system', // REQUIRED
      priority: 'high', // REQUIRED
      category: 'riskUnqueued',
      status: 'open', // REQUIRED
      wakeUpDate: null,
      snoozeActivatedDate: null,
      riskAlertPrimaryKeyValue: partnerGuids.default,
      classification: null,
      createdBy: {
        employeeId: 'd46c6297-d69e-4128-b276-5b4a4801cde7',
        employeeFirstName: 'Corvia',
        employeeLastName: 'System',
        employeeEmail: 'system@corviapay.com'
      }, // REQUIRED - generated on creation
      ticketId: ticketGuids.ticket17, // REQUIRED - generated on creation
      ticketBusinessCode: '1234568',
      ticketClosedBy: null,
      requiredCompletionTime: '2020-02-01T18:00:04Z',
      createdTimestamp: '2021-01-16T00:23:04Z',
      watchers: [mockEmployees.employee01.email],
      externalWatchers: ['testExternalEmail@test.com']
    }
  }
};

export const closedTicketStatuses = ['completed', 'will_not_complete', 'duplicate', 'approved', 'denied'];
export const openTicketStatuses = ['open', 'in_progress', 'snoozed', 'waiting_on_partner', 'waiting_on_bank'];

const mockStatusList = [...closedTicketStatuses, ...openTicketStatuses];

export const createMockTickets = () => {
  const staticMockTickets = Object.values(mockTickets).map(item => item.response);
  const mockCreatedTickets = [...mockStatusList, ...Array.from({ length: 20 })]
    .map(createMockTicket);
  return staticMockTickets.concat(mockCreatedTickets);
};

const mockPortalRequestList = ticketRequestDropdownFields({ userType: 'partner' }).map(item => (`PORTAL USER REQUEST: ${item.title}`));

const createMockTicket = (staticStatus, ticketIndex) => {
  const isPortalTicket = ticketIndex >= 0 && ticketIndex < 6;
  const isCategoryTicket = ticketIndex >= 6 && ticketIndex < 10;
  const mockAssignedEmployee = getMockEmployee();
  const mockCreatedByEmployee = getMockEmployee();
  const mockClosedByEmployee = getMockEmployee();
  const mockGroupIndex = getMockValue({ type: 'number', min: 0, max: mockEmployeeGroupGetRes.length - 1 });
  const mockGroup = mockEmployeeGroupGetRes[mockGroupIndex] ?? mockEmployeeGroupGetRes[0];
  const mockAllowedGroups = [{
    employeeGroupName: mockGroup.employeeGroupName,
    employeeGroupId: mockGroup.employeeGroupId
  }];
  const mockStatus = !isEmpty(staticStatus)
    ? ignoreCase(staticStatus)
    : getMockValue({ type: 'list', list: mockStatusList });
  const isClosed = closedTicketStatuses.includes(mockStatus);
  const mockCategory = isCategoryTicket ? getMockValue({ type: 'list', list: ['riskUnqueued', 'approvalRequest'] }) : null;
  const mockTicketName = isPortalTicket
    ? getMockValue({ type: 'list', list: mockPortalRequestList })
    : `Mock Ticket Name ${ticketIndex}${mockCategory ? (' (').concat(mockCategory).concat(')') : ''}`;
  const mockWatcherList = [ // Valid dev-emails
    mockEmployees.employee02.email,
    mockEmployees.employee07.email,
    mockEmployees.employee08.email,
    mockEmployees.employee09.email,
    mockEmployees.employee10.email,
    mockEmployees.employee11.email,
    mockEmployees.employee12.email
  ];
  const mockWatcherStartIndex = getMockValue({ min: 0, max: mockWatcherList.length - 1 });
  const mockTicketItem = {
    assignedEmployee: {
      employeeId: mockAssignedEmployee.employeeId,
      employeeFirstName: mockAssignedEmployee.firstName,
      employeeLastName: mockAssignedEmployee.lastName,
      employeeEmail: mockAssignedEmployee.email
    },
    employeeGroups: mockAllowedGroups,
    merchant: {
      merchantGuid: merchantGuids.default,
      dbaName: 'Merchant with Transaction Report Data'
    },
    partner: {
      partnerId: partnerGuids.default,
      name: 'Partner with Transaction Report Data'
    },
    relationship: {
      relationshipId: relationshipGuids.default,
      name: 'Relationship with Transaction Report Data'
    },
    category: mockCategory,
    description: getMockValue({ type: 'description' }),
    name: mockTicketName,
    priority: getMockValue({ type: 'list', list: ['unprioritized', 'severity_1', 'severity_2', 'severity_3', 'severity_4', 'severity_5'] }),
    requiredCompletionTime: null,
    watchers: mockWatcherList.slice(mockWatcherStartIndex),
    externalWatchers: Array.from({ length: getMockValue({ type: 'number', min: 1, max: 5 }) }).map(() => getMockValue({ type: 'email', userType: 'external' })),
    status: mockStatus,
    wakeUpDate: mockStatus === 'snoozed' ? getMockValue({ type: 'date' }) : null,
    riskRule: {
      riskRuleId: riskRuleGuids.default,
      name: getMockValue({ type: 'string' }),
      primaryKeyType: getMockValue({ type: 'list', list: ['MERCHANT_GUID', 'MERCHANT_EIN_ID', 'PARTNER_ID', 'PERSON_PII_SSN_ID', 'BIN_ID'] })
    },
    classification: getMockValue({ type: 'list', list: ['false_positive', 'true_positive', 'false_negative', 'true_negative'] }),
    riskAlertPrimaryKeyValue: getMockValue({ type: 'string' }),
    requestedByPortalUser: isPortalTicket
      ? {
        portalUserId: getMockValue({ type: 'guid' }),
        email: getMockValue({ type: 'email', userType: 'external' })
      }
      : null,
    createdBy: isPortalTicket
      ? null
      : {
        employeeId: mockCreatedByEmployee.employeeId,
        employeeFirstName: mockCreatedByEmployee.firstName,
        employeeLastName: mockCreatedByEmployee.lastName,
        employeeEmail: mockCreatedByEmployee.email
      },
    ticketId: `mock-ticket-id-${ticketIndex}`,
    ticketBusinessCode: `${ticketIndex + 1}`,
    ticketClosedBy: isClosed ? {
      employeeId: mockClosedByEmployee.employeeId,
      employeeFirstName: mockClosedByEmployee.firstName,
      employeeLastName: mockClosedByEmployee.lastName,
      employeeEmail: mockClosedByEmployee.email
    } : null,
    snoozeActivatedDate: getMockValue({ type: 'date' }),
    createdTimestamp: getMockValue({ type: 'isoDate' }),
    closedTimestamp: isClosed ? getMockValue({ type: 'isoDate' }) : null,
    approvalActionPayload: {
      endpointToInvoke: getMockValue({ type: 'list', list: [`/crm/merchant/v2/closure`, `crm/risk/prohibitedEntity`] }),
      payload: getMockValue({ type: 'string' })
    },
    ticketCompletionTimeInWorkHours: isClosed ? getMockValue({ type: 'number' }) : null,
    ticketTimeWaitingOnNonCorviaInWorkHours: isClosed ? getMockValue({ type: 'number' }) : null
  };
  return mockTicketItem;
};

export default mockTickets;
