import { ignoreCase, isEmpty } from '@f1/shared/src/_helpers';
import { toBackendValue } from '@f1/shared/src/_templateHelpers';
import { sharedRelationshipFormProps, relationshipV3FormFields } from '../../../../pages/components/forms/formHelpers';

const relationshipV2PUTTemplate = {
  frontend: (schema, version) => {
    if (version === '1.0') {
      // Just returning an empty object as there's no GET call here
      return {};
    }
    return schema;
  },
  backend: (schema, version) => {
    if (version === '2.0') {
      const { allGatewayInfoList } = schema || {};
      const processorValue = schema.processorName;
      const paymentGatewayValue = schema.paymentGateway;
      const gatewayAffiliate = !isEmpty(allGatewayInfoList)
        ? allGatewayInfoList.find(
          infoItem => (ignoreCase(infoItem.gatewayValue) === ignoreCase(paymentGatewayValue) &&
          !isEmpty(infoItem.gatewayAffiliateIdList))
        )
        : {};
      return {
        relationship: {
          relationshipCode: toBackendValue(
            schema?.[relationshipV3FormFields.relationshipCode.id],
            relationshipV3FormFields.relationshipCode
          ),
          relationshipName: toBackendValue(
            schema?.[relationshipV3FormFields.relationshipName.id],
            relationshipV3FormFields.relationshipName
          ),
          parentRelationshipId: toBackendValue(
            schema?.[relationshipV3FormFields.parentRelationshipId.id],
            relationshipV3FormFields.parentRelationshipId
          ),
          childPartnerId: toBackendValue(
            schema?.[relationshipV3FormFields.childPartnerId.id],
            relationshipV3FormFields.childPartnerId
          ),
          bankName: toBackendValue(
            schema?.[relationshipV3FormFields.bankName.id],
            relationshipV3FormFields.bankName
          ),
          processorName: toBackendValue(
            schema?.[relationshipV3FormFields.processorName.id],
            relationshipV3FormFields.processorName
          ),
          repaySortCode: processorValue === 'REPAY'
            ? toBackendValue(
              schema?.[sharedRelationshipFormProps.repaySortCode.id],
              sharedRelationshipFormProps.repaySortCode
            ).toUpperCase() || null
            : null,
          processorFrontEnd: toBackendValue(
            schema?.[relationshipV3FormFields.processorFrontEnd.id],
            relationshipV3FormFields.processorFrontEnd
          ),
          paymentGateway: !isEmpty(schema?.[relationshipV3FormFields.paymentGateway.id])
            ? toBackendValue(
              schema?.[relationshipV3FormFields.paymentGateway.id],
              relationshipV3FormFields.paymentGateway
            )
            : null,
          gatewayAffiliateId: !isEmpty(gatewayAffiliate)
            ? toBackendValue(
              schema[sharedRelationshipFormProps.gatewayAffiliateId.id],
              sharedRelationshipFormProps.gatewayAffiliateId
            )
            : null,
          riskProfile: toBackendValue(
            schema?.[relationshipV3FormFields.riskProfile.id],
            relationshipV3FormFields.riskProfile
          ),
          inactive: toBackendValue(
            schema?.[relationshipV3FormFields.inactive.id],
            relationshipV3FormFields.inactive
          ),
          ...(!isEmpty(schema?.[relationshipV3FormFields.agentChain.id]) && {
            agentChain: toBackendValue(
              schema?.[relationshipV3FormFields.agentChain.id],
              relationshipV3FormFields.agentChain
            ) || null
          })
        }
      };
    }
    return schema;
  }
};

export default relationshipV2PUTTemplate;
