import { varIndustryList } from '@f1/shared/src/_crabFields';
import sharedFormFields from '@f1/shared/src/_formFields';
import {
  getRandomNumber,
  isEmpty,
  sortData
} from '@f1/shared/src/_helpers';

const comboBoxProps = {
  fieldType: 'combobox',
  displaySearch: true,
  useBlockForm: true,
  formField: true
};

export const approveFormCustomValidations = {
  midDefault: value => RegExp(/^[0-9]+$/).test(value),
  midRepay: value => RegExp(/^840100([0-9]+)$/).test(value),
  midPayroc: value => RegExp(/^403903([0-9]+)$/).test(value),
  idlePrompt: value => value && value.length <= 16,
  agentBank: value => RegExp(/^[0-9]{6}$/).test(value),
  agentChain: value => RegExp(/^[0-9]{6}$/).test(value),
  storeNumber: value => RegExp(/^[0-9]{4}$/).test(value),
  terminalNumber: value => RegExp(/^[0-9]{4}$/).test(value),
  discoverMid: value => RegExp(/^[0-9]{15}$/).test(value),
  authenticationCode: value => RegExp(/^[A-Za-z0-9]{6,10}$/).test(value) &&
  /* non-repeating chars */ !RegExp(/([A-Za-z0-9])\1{1,}/).test((value || '').toUpperCase()),
  commentsTxt: value => value && value.length >= 10 && value.length <= 256,
  abaNumber: value => RegExp(/^[0-9]{9}$/).test(value),
  locationNumber: value => RegExp(/^[0-9]{5}$/).test(value),
  posMidOptional: value => (!isEmpty(value) ? RegExp(/^[0-9]{12}$/).test(value) : true), // optional
  posMidRequired: value => RegExp(/^[0-9]{12}$/).test(value), // required
  vitalNumber: value => RegExp(/^7([0-9]{7})$/).test(value),
  reimbursementAttribute: value => value && value.length > 0,
  acquirerBin: value => RegExp(/^[0-9]{6}$/).test(value)
};

export const approveCustomBackendFormats = {
  // Custom formatting for BE values for POST request
  authenticationCode: value => (value || '').toUpperCase() /* BE expects uppercase */
};

const softwareListMap = {
  AIOHTL: { title: 'AIOHTL', value: 'AIOHTL' },
  BACS: { title: 'BACS', value: 'BACS' },
  BAS: { title: 'BAS', value: 'BAS' },
  CCASH: { title: 'CCASH', value: 'CCASH' },
  CSOURCE: { title: 'CSOURCE', value: 'CSOURCE' },
  CTRPASS: { title: 'CTRPASS', value: 'CTRPASS' },
  DCCHTL: { title: 'DCCHTL', value: 'DCCHTL' },
  DEJAVOO: { title: 'DEJAVOO', value: 'DEJAVOO' },
  ENGAGE: { title: 'ENGAGE', value: 'ENGAGE' },
  HCSTAGE: { title: 'HCSTAGE', value: 'HCSTAGE' },
  HCSTGEN: { title: 'HCSTGEN', value: 'HCSTGEN' },
  HCSTGT: { title: 'HCSTGT', value: 'HCSTGT' },
  HSTPYMT: { title: 'HSTPYMT', value: 'HSTPYMT' },
  INGEMV4: { title: 'INGEMV4', value: 'INGEMV4' },
  IP: { title: 'IP', value: 'IP' },
  MBLPYMT: { title: 'MBLPYMT', value: 'MBLPYMT' },
  MLTPASS: { title: 'MLTPASS', value: 'MLTPASS' },
  OMR: { title: 'OMR', value: 'OMR' },
  PAX: { title: 'PAX', value: 'PAX' },
  SPOSDCC: { title: 'SPOSDCC', value: 'SPOSDCC' },
  SSL: { title: 'SSL', value: 'SSL' },
  STG: { title: 'STG', value: 'STG' },
  STGENCR: { title: 'STGENCR', value: 'STGENCR' },
  STGT: { title: 'STGT', value: 'STGT' },
  TETRA: { title: 'TETRA', value: 'TETRA' },
  TSEPYMT: { title: 'TSEPYMT', value: 'TSEPYMT' },
  VITLPOS: { title: 'VITLPOS', value: 'VITLPOS' },
  VSDRECT: { title: 'VSDRECT', value: 'VSDRECT' },
  VSRESEL: { title: 'VSRESEL', value: 'VSRESEL' },
  WEBPASS: { title: 'WEBPASS', value: 'WEBPASS' },
  XEVA440: { title: 'XEVA440', value: 'XEVA440' }
};

export const softwareList = Object.values(softwareListMap);

const modelListMap = {
  AIO: { title: 'AIO', value: 'AIO', softwareList: [softwareListMap.AIOHTL] },
  AUTH: { title: 'AUTH', value: 'AUTH', softwareList: [softwareListMap.BACS] },
  BAS: { title: 'BAS', value: 'BAS', softwareList: [softwareListMap.BAS] },
  CAT250: { title: 'CAT 250', value: 'CAT 250', softwareList: [softwareListMap.OMR] },
  CLSBPLUS: { title: 'CLSBPLUS', value: 'CLSBPLUS', softwareList: [softwareListMap.DEJAVOO, softwareListMap.PAX] },
  DESK3500: { title: 'DESK3500', value: 'DESK3500', softwareList: [softwareListMap.TETRA] },
  DESK5000: { title: 'DESK5000', value: 'DESK5000', softwareList: [softwareListMap.TETRA] },
  HCSTAGE: { title: 'HCSTAGE', value: 'HCSTAGE', softwareList: [softwareListMap.HCSTAGE, softwareListMap.HCSTGT] },
  ICT220: { title: 'ICT220', value: 'ICT220', softwareList: [softwareListMap.INGEMV4] },
  ICT220CTL: { title: 'ICT220CTL', value: 'ICT220CTL', softwareList: [softwareListMap.INGEMV4] },
  ICT250: { title: 'ICT250', value: 'ICT250', softwareList: [softwareListMap.INGEMV4] },
  IPP320: { title: 'IPP320', value: 'IPP320', softwareList: [softwareListMap.INGEMV4] },
  IWL220: { title: 'IWL220', value: 'IWL220', softwareList: [softwareListMap.INGEMV4] },
  IWL250: { title: 'IWL250', value: 'IWL250', softwareList: [softwareListMap.INGEMV4] },
  LINK: { title: 'LINK', value: 'LINK', softwareList: [softwareListMap.VSDRECT, softwareListMap.VSRESEL] },
  M4230: { title: 'M4230', value: 'M4230', softwareList: [softwareListMap.M4230] },
  MBC3: { title: 'MB-C3', value: 'MB-C3', softwareList: [softwareListMap.VITLPOS] },
  MBC4: { title: 'MB-C4', value: 'MB-C4', softwareList: [softwareListMap.VITLPOS] },
  MBC8: { title: 'MB-C8', value: 'MB-C8', softwareList: [softwareListMap.VITLPOS] },
  MBNOCDRD: { title: 'MB-NOCDRD', value: 'MB-NOCDRD', softwareList: [softwareListMap.VITLPOS] },
  MOVE5000: { title: 'MOVE5000', value: 'MOVE5000', softwareList: [softwareListMap.TETRA] },
  PC: { title: 'PC', value: 'PC', softwareList: [softwareListMap.CTRPASS, softwareListMap.HSTPYMT, softwareListMap.MBLPYMT, softwareListMap.MLTPASS, softwareListMap.TSEPYMT, softwareListMap.WEBPASS] },
  PLUSX4: { title: 'PLUS-X4', value: 'PLUS-X4', softwareList: [softwareListMap.VITLPOS] },
  PLUSX5: { title: 'PLUS-X5', value: 'PLUS-X5', softwareList: [softwareListMap.VITLPOS] },
  PRO: { title: 'PRO', value: 'PRO', softwareList: [softwareListMap.VSDRECT, softwareListMap.VSRESEL] },
  SLTE13: { title: 'SLT-E13', value: 'SLT-E13', softwareList: [softwareListMap.VITLPOS] },
  SLTE15: { title: 'SLT-E15', value: 'SLT-E15', softwareList: [softwareListMap.VITLPOS] },
  SLTX8: { title: 'SLT-X8', value: 'SLT-X8', softwareList: [softwareListMap.VITLPOS] },
  STAGE: { title: 'STAGE', value: 'STAGE', softwareList: [softwareListMap.HCSTGEN, softwareListMap.STG, softwareListMap.STGENCR, softwareListMap.STGT] },
  T4230: { title: 'T4230', value: 'T4230', softwareList: [softwareListMap.SPOSDCC] },
  T7PLUS: { title: 'T7PLUS', value: 'T7PLUS', softwareList: [softwareListMap.DCCHTL] },
  TABLEX5: { title: 'TABLE-X5', value: 'TABLE-X5', softwareList: [softwareListMap.VITLPOS] },
  VNET: { title: 'V-NET', value: 'V-NET', softwareList: [softwareListMap.CCASH, softwareListMap.CSOURCE, softwareListMap.IP, softwareListMap.SSL] },
  V200C: { title: 'V200C', value: 'V200C', softwareList: [softwareListMap.ENGAGE] },
  V400C: { title: 'V400C', value: 'V400C', softwareList: [softwareListMap.ENGAGE] },
  V400M: { title: 'V400M', value: 'V400M', softwareList: [softwareListMap.ENGAGE] },
  VX520: { title: 'VX520', value: 'VX520', softwareList: [softwareListMap.XEVA440] },
  VX680: { title: 'VX680', value: 'VX680', softwareList: [softwareListMap.XEVA440] },
  VX680WFBT: { title: 'VX680WFBT', value: 'VX680WFBT', softwareList: [softwareListMap.XEVA440] }
};

export const modelList = Object.values(modelListMap);

export const manufacturerList = [
  { title: 'BAS', value: 'BAS', modelList: [modelListMap.BAS] },
  { title: 'BATCH', value: 'BATCH', modelList: [modelListMap.AUTH] },
  { title: 'CLSBPLUS', value: 'CLSBPLUS', modelList: [modelListMap.CLSBPLUS] },
  { title: 'COMPUTER', value: 'COMPUTER', modelList: [modelListMap.PC] },
  { title: 'EQUINOX', value: 'EQUINOX', modelList: [modelListMap.AIO] },
  { title: 'HCSTAGE', value: 'HCSTAGE', modelList: [modelListMap.HCSTAGE] },
  { title: 'HYPERCOM', value: 'HYPERCOM', modelList: [modelListMap.M4230, modelListMap.T4230, modelListMap.T7PLUS] },
  { title: 'INGENICO', value: 'INGENICO', modelList: [modelListMap.DESK3500, modelListMap.DESK5000, modelListMap.ICT220, modelListMap.ICT220CTL, modelListMap.ICT250, modelListMap.IPP320, modelListMap.IWL220, modelListMap.IWL250, modelListMap.MOVE5000] },
  { title: 'INTERNET', value: 'INTERNET', modelList: [modelListMap.LINK, modelListMap.PRO, modelListMap.VNET] },
  { title: 'OMRON', value: 'OMRON', modelList: [modelListMap.CAT250] },
  { title: 'STAGE', value: 'STAGE', modelList: [modelListMap.HCSTAGE, modelListMap.STAGE] },
  { title: 'VERIFONE', value: 'VERIFONE', modelList: [modelListMap.V200C, modelListMap.V400C, modelListMap.V400M, modelListMap.VX520, modelListMap.VX680, modelListMap.VX680WFBT] },
  { title: 'VITALPOS', value: 'VITALPOS', modelList: [modelListMap.MBC3, modelListMap.MBC4, modelListMap.MBC8, modelListMap.MBNOCDRD, modelListMap.PLUSX4, modelListMap.PLUSX5, modelListMap.SLTE13, modelListMap.SLTE15, modelListMap.SLTX8, modelListMap.TABLEX5] }
];

export const crabApproveGeneral = ({
  processName,
  isDefaultProcessor,
  processorBoarding,
  gatewayBoarding = false
} = {}) => ({
  id: 'general',
  ...(!gatewayBoarding && !processorBoarding && {
    requiresExtraMonitoring: sharedBoardingFields.requiresExtraMonitoring
  }),
  ...(((isDefaultProcessor) || ['netevia'].includes(processName) || ['priority'].includes(processName) || gatewayBoarding) && {
    mid: {
      ...sharedBoardingFields.mid,
      ...(gatewayBoarding && { disabled: true })
    }
  }),
  ...(!gatewayBoarding && {
    ...(!processorBoarding && {
      mccCode: {
        ...sharedFormFields.mccCode,
        required: true
      },
      highRiskRegistrationRequired: {
        name: 'highRiskRegistrationRequired',
        id: 'highRiskRegistrationRequired',
        label: 'High Risk Registration Required',
        type: 'radio',
        fieldType: 'radio',
        fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
        required: false
      }
    }),
    reserveHoldbackDays: {
      id: 'reserveHoldbackDays',
      label: 'Reserve Holdback Days',
      type: 'rationalNumber',
      fieldType: 'input',
      tooltip: `Cannot be zero if: "Reserve Holdback Percent" is greater than zero${processName === 'repay' ? ', and/or Boarding to Repay and "Rolling Reserve Days" is greater than zero' : ''}`,
      required: true,
      ...(processorBoarding && processName === 'repay' && { disabled: true })
    }
  })
});

export const crabApproveReserveHoldbackSection = (options) => {
  const { processName, gatewayBoarding = false, processorBoarding = false } = options || {};
  const isRepay = processName === 'repay';
  return gatewayBoarding
    ? {
      // Do not send any fields if accessing the ApproveApplicationForm via MerchantBoardToGateway
    }
    : {
      id: 'reserveHoldbackSection',
      reserveHoldbackRatio: { // Custom confirm input validation field
        id: 'reserveHoldbackRatio',
        label: 'Reserve Holdback Percent',
        type: 'percent',
        suffix: '%',
        fieldType: 'input',
        tooltip: `Cannot be zero if: "Reserve Holdback Days" is greater than zero${processName === 'repay' ? ', and/or Boarding to Repay and "Rolling Reserve Days" is greater than zero' : ''}`,
        required: true,
        isRatio: true,
        ...(processorBoarding && isRepay && { disabled: true })
      }
    };
};

export const sharedBoardingFields = {
  // Shared fields for the different "Board To..." API boarding objects
  agentBank: {
    id: 'agentBank',
    label: 'Agent Bank',
    type: 'text',
    fieldType: 'input',
    required: true,
    customValidation: approveFormCustomValidations.agentBank,
    errorMessage: 'Must be 6 digits'
  },
  agentChain: {
    id: 'agentChain',
    label: 'Agent Chain',
    type: 'text',
    fieldType: 'input',
    required: true,
    customValidation: approveFormCustomValidations.agentChain,
    errorMessage: 'Must be 6 digits'
  },
  discoverMid: {
    id: 'discoverMid',
    label: 'Discover MID',
    type: 'text',
    fieldType: 'input',
    required: true,
    customValidation: approveFormCustomValidations.discoverMid,
    errorMessage: 'Must be 15 digits'
  },
  storeNumber: {
    id: 'storeNumber',
    label: 'Store Number',
    type: 'text',
    fieldType: 'input',
    required: true,
    customValidation: approveFormCustomValidations.storeNumber,
    errorMessage: 'Must be 4 digits'
  },
  terminalNumber: {
    id: 'terminalNumber',
    label: 'Terminal Number',
    type: 'text',
    fieldType: 'input',
    required: true,
    customValidation: approveFormCustomValidations.terminalNumber,
    errorMessage: 'Must be 4 digits',
    tooltip: 'This value is only for VISA/MASTERCARD, AMEX/DISC/JCBC are not being set'
  },
  mid: {
    id: 'mid',
    label: 'MID',
    type: 'mid',
    fieldType: 'input',
    required: true,
    customValidation: approveFormCustomValidations.midDefault,
    errorMessage: 'Must be numbers only'
  /**
   * Custom validation for specific processors will override in form:
   * - if the processor name is PAYROC then the mid must start with 403903
   * - if the processor name is REPAY then the mid must start with 840100
  */
  },
  combinedAch: {
    initialValue: false, // BE value - default value for the FE should be false
    name: 'combinedAch',
    id: 'combinedAch',
    label: 'Combined ACH',
    type: 'radio',
    fieldType: 'radio',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    required: true
  },
  customerServicePhone: {
    id: 'customerServicePhone',
    type: 'tel',
    fieldType: 'input',
    telPrefixRequired: true, // to include +1 for BE
    label: 'Customer Service Phone Number',
    required: true
  },
  autoAssignMid: {
    id: 'autoAssignMid',
    name: 'autoAssignMid',
    label: 'Auto Assign MID',
    type: 'radio',
    fieldType: 'radio',
    fields: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' }
    ]
  },
  requiresExtraMonitoring: {
    id: 'requiresExtraMonitoring',
    name: 'requiresExtraMonitoring',
    label: 'Mark this merchant as needing extra monitoring from Risk',
    fieldType: 'checkbox',
    type: 'mini',
    height: 'auto',
    wrapperStyle: { width: '100%', padding: '10px 10px' },
    required: false,
    className: 'requiresExtraMonitoring',
    controls: {
      true: {
        reason: {
          id: 'reason',
          label: 'Reason for Extra Monitoring',
          type: 'text',
          fieldType: 'input',
          required: true
        }
      }
    }
  },
  abaNumber: {
    id: 'abaNumber',
    label: 'ABA Number',
    type: 'text',
    fieldType: 'input',
    required: false,
    customValidation: approveFormCustomValidations.abaNumber,
    errorMessage: 'Must be 9 digits'
  },
  acceptDebt: {
    name: 'acceptDebt',
    label: 'Accept Debit',
    id: 'acceptDebt',
    type: 'radio',
    fieldType: 'radio',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    required: true
  },
  acceptEbt: {
    name: 'acceptEbt',
    label: 'Accept EBT',
    id: 'acceptEbt',
    type: 'radio',
    fieldType: 'radio',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    required: true
  },
  acceptVisa: {
    name: 'acceptVisa',
    label: 'Accept Visa',
    id: 'acceptVisa',
    type: 'radio',
    fieldType: 'radio',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    required: true
  },
  acceptMastercard: {
    name: 'acceptMastercard',
    label: 'Accept Mastercard',
    id: 'acceptMastercard',
    type: 'radio',
    fieldType: 'radio',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    required: true
  },
  acceptDiscover: {
    name: 'acceptDiscover',
    label: 'Accept Discover',
    id: 'acceptDiscover',
    type: 'radio',
    fieldType: 'radio',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    required: true
  },
  acceptDinersClub: {
    name: 'acceptDinersClub',
    label: 'Accept Diners Club',
    id: 'acceptDinersClub',
    type: 'radio',
    fieldType: 'radio',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    required: true
  },
  acceptJcb: {
    name: 'acceptJcb',
    label: 'Accept JCB',
    id: 'acceptJcb',
    type: 'radio',
    fieldType: 'radio',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    required: true
  },
  acceptAmex: {
    name: 'acceptAmex',
    label: 'Accept Amex',
    id: 'acceptAmex',
    type: 'radio',
    fieldType: 'radio',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    required: true
  },
  currencyCode: {
    initialValue: '840', // default value
    id: 'currencyCode',
    label: 'Currency Code',
    type: 'text',
    fieldType: 'input',
    required: true,
    errorMessage: 'Must be valid ISO 4217 type code'
  },
  countryCode: {
    initialValue: '840', // default value
    id: 'countryCode',
    label: 'Country Code',
    type: 'text',
    fieldType: 'input',
    required: true,
    errorMessage: 'Must be valid ISO 3166-1 numeric code'
  },
  industry: {
    id: 'industry',
    label: 'Industry',
    ...comboBoxProps,
    required: true,
    list: varIndustryList
  },
  ebtFcsId: {
    id: 'ebtFcsId',
    label: 'EBT FCS ID',
    type: 'text',
    fieldType: 'input',
    required: false
  },
  locationNumber: {
    id: 'locationNumber',
    label: 'Location Number',
    type: 'text',
    fieldType: 'input',
    required: true,
    customValidation: approveFormCustomValidations.locationNumber,
    errorMessage: 'Must be 5 digits'
  },
  networksAndSharingGroups: {
    id: 'networksAndSharingGroups',
    label: 'Networks And Sharing Groups',
    type: 'text',
    fieldType: 'input',
    required: false
  },
  sharingGroups: {
    id: 'sharingGroups',
    label: 'Sharing Groups',
    type: 'text',
    fieldType: 'input',
    required: false
  },
  reimbursementAttribute: {
    initialValue: 'Z', // default value
    id: 'reimbursementAttribute',
    label: 'Reimbursement Attribute',
    type: 'text',
    fieldType: 'input',
    required: true,
    customValidation: approveFormCustomValidations.reimbursementAttribute
  },
  settlementAgentFiId: {
    initialValue: 'V103', // default value
    id: 'settlementAgentFiId',
    label: 'Settlement Agent Fi ID',
    type: 'text',
    fieldType: 'input',
    required: true
  },
  terminalPhoneAuthPrimary: {
    initialValue: '1-866-265-6609', // default value
    id: 'terminalPhoneAuthPrimary',
    label: 'Terminal Phone Auth (Primary)',
    type: 'tel',
    telPrefixRequired: true, // to include +1 for BE
    fieldType: 'input',
    required: true
  },
  terminalPhoneAuthSecondary: {
    initialValue: '1-866-265-6610', // default value
    id: 'terminalPhoneAuthSecondary',
    label: 'Terminal Phone Auth (Secondary)',
    type: 'tel',
    telPrefixRequired: true, // to include +1 for BE
    fieldType: 'input',
    required: true
  },
  terminalPhoneSettlementPrimary: {
    initialValue: '1-866-265-6609', // default value
    id: 'terminalPhoneSettlementPrimary',
    label: 'Terminal Phone Settlement (Primary)',
    type: 'tel',
    telPrefixRequired: true, // to include +1 for BE
    fieldType: 'input',
    required: true
  },
  terminalPhoneSettlementSecondary: {
    initialValue: '1-866-265-6610', // default value
    id: 'terminalPhoneSettlementSecondary',
    label: 'Terminal Phone Settlement (Secondary)',
    type: 'tel',
    telPrefixRequired: true, // to include +1 for BE
    fieldType: 'input',
    required: true
  },
  terminalUrlAuthPrimary: {
    initialValue: 'ssl2.vitalps.net/5003', // default value
    id: 'terminalUrlAuthPrimary',
    label: 'Terminal URL Auth (Primary)',
    type: 'url',
    fieldType: 'input',
    required: true
  },
  terminalUrlAuthSecondary: {
    initialValue: 'ssl2.vitalps.net/5003', // default value
    id: 'terminalUrlAuthSecondary',
    label: 'Terminal URL Auth (Secondary)',
    type: 'url',
    fieldType: 'input',
    required: true
  },
  terminalUrlSettlementPrimary: {
    initialValue: 'ssl2.vitalps.net/5003', // default value
    id: 'terminalUrlSettlementPrimary',
    label: 'Terminal URL Settlement (Primary)',
    type: 'url',
    fieldType: 'input',
    required: true
  },
  terminalUrlSettlementSecondary: {
    initialValue: 'ssl2.vitalps.net/5003', // default value
    id: 'terminalUrlSettlementSecondary',
    label: 'Terminal URL Settlement (Secondary)',
    type: 'url',
    fieldType: 'input',
    required: true
  },
  timeZoneCode: {
    initialValue: '706', // default value
    id: 'timeZoneCode',
    label: 'Time Zone Code',
    type: 'text',
    fieldType: 'input',
    required: true
  },
  vitalNumber: {
    id: 'vitalNumber',
    label: 'Vital Number',
    type: 'text',
    fieldType: 'input',
    customValidation: approveFormCustomValidations.vitalNumber,
    errorMessage: 'Must be 8 digits and start with 7',
    required: true
  },
  acquirerBin: {
    id: 'acquirerBin',
    label: 'Acquirer Bin',
    type: 'text',
    fieldType: 'input',
    required: true,
    errorMessage: 'Must be 6 digits',
    customValidation: approveFormCustomValidations.acquirerBin
  },
  posMid: {
    id: 'posMid',
    label: 'POS MID',
    type: 'text',
    fieldType: 'input',
    required: false, // optional unless gateway is NMI
    customValidation: approveFormCustomValidations.posMidOptional,
    errorMessage: 'Must be 12 digits'
  }
};

const sharedBoardToRepayFields = {
  authenticationCode: {
    id: 'authenticationCode',
    label: 'Authentication Code',
    type: 'text',
    fieldType: 'input',
    required: true,
    customValidation: approveFormCustomValidations.authenticationCode,
    backendFormat: approveCustomBackendFormats.authenticationCode,
    errorMessage: 'Must be 6-10 non-repeating letters/numbers'
  },
  commentsTxt: {
    id: 'commentsTxt',
    label: 'Profile Comments',
    type: 'textarea',
    fieldType: 'input',
    required: true,
    wrapperStyle: { flex: '100%', marginBottom: '-2px' },
    customValidation: approveFormCustomValidations.commentsTxt,
    errorMessage: 'Must be at least 10 characters (Max 256)'
  }
};

export const manufacturersWithCustomFields = ['VITALPOS', 'VERIFONE', 'INGENICO']; // Manufacturers that require more fields
export const crabRepayTerminalCombos = { // manufacturer/software/model that require more fields
  // Specific manufacturers that require additional fields
  VITALPOS: { // if manufacturer === VITALPOS, required -> buildType, commentsTxt, encryptionVendor
    buildType: {
      id: 'buildType',
      label: 'Build Type',
      ...comboBoxProps,
      required: true,
      list: [
        { title: 'Restaurant', value: 'restaurant' },
        { title: 'Retail', value: 'retail' },
        { title: 'Retail With Tip', value: 'retail_with_tip' }
      ]
    },
    encryptionVendor: {
      id: 'encryptionVendor',
      label: 'Encryption Vendor',
      ...comboBoxProps,
      required: true,
      list: [
        { title: 'NESA', value: 'nesa' },
        { title: 'P2PE', value: 'p2pe' },
        { title: 'TDES (TripleDES)', value: 'triple_des' },
        { title: 'Voltage', value: 'voltage' }
      ]
    },
    commentsTxt: sharedBoardToRepayFields.commentsTxt
  },
  VERIFONE: { // if manufacturer === VERIFONE, required -> authenticationCode, commentsTxt
    authenticationCode: sharedBoardToRepayFields.authenticationCode,
    commentsTxt: sharedBoardToRepayFields.commentsTxt
  },
  INGENICO: { // if manufacturer === INGENICO, required -> authenticationCode
    authenticationCode: sharedBoardToRepayFields.authenticationCode
  },
  // STAGE_STGENCR_STAGE combo requires additional fields
  STAGE_STGENCR_STAGE: { // required -> authenticationCode
    authenticationCode: sharedBoardToRepayFields.authenticationCode
  }
};

export const crabRepayTerminalFields = { // Manufacturer/Software/Model fields
  manufacturer: {
    id: 'manufacturer',
    label: 'POS Device Manufacturer',
    ...comboBoxProps,
    required: true,
    displaySearch: true,
    controllerStateOverrides: manufacturerList.reduce((acc, item) => ({
      ...acc,
      [item.value]: {
        software: undefined, // since this is required, must reset to undefined
        softwareIsValid: false,
        model: undefined, // since this is required, must reset to undefined
        modelIsValid: false
      }
    }), {}),
    list: manufacturerList
  },
  model: {
    id: 'model',
    label: 'Terminal Model Type',
    ...comboBoxProps,
    required: true,
    displaySearch: true,
    controllerStateOverrides: modelList.reduce((acc, item) => ({
      ...acc,
      [item.value]: {
        software: undefined, // since these are required, must reset to undefined
        softwareIsValid: false
      }
    }), {}),
    controlledBy: {
      controllerId: 'manufacturer',
      controllerValueMap: manufacturerList.reduce((acc, item) => ({
        ...acc,
        [item.value]: { overrides: { list: item.modelList } }
      }), {})
    },
    list: modelList
  },
  software: {
    id: 'software',
    label: 'Terminal Software',
    ...comboBoxProps,
    required: true,
    displaySearch: true,
    controlledBy: {
      controllerId: 'model',
      controllerValueMap: modelList.reduce((acc, item) => ({
        ...acc,
        [item.value]: { overrides: { list: item.softwareList } }
      }), {})
    },
    list: softwareList
  }
};

export const rollingReserveDaysList = [
  { title: '0 Days', value: 0 },
  { title: '1 Day', value: 1 },
  { title: '2 Days', value: 2 },
  { title: '60 Days', value: 60 },
  { title: '180 Days', value: 180 },
  { title: '365 Days', value: 365 }
];

const sharedApproveRepayFields = {
  rollingReserveDays: {
    id: 'rollingReserveDays',
    label: 'Rolling Reserve Days',
    ...comboBoxProps,
    list: rollingReserveDaysList,
    tooltip: `Cannot be zero if: "Reserve Holdback Percent" is greater than 0, and/or "Reserve Holdback Days" is greater than 0`,
    required: true
  },
  holdFlag: {
    initialValue: false, // BE value - default value for the FE should be false
    name: 'holdFlag',
    id: 'holdFlag',
    label: 'Hold Flag',
    type: 'radio',
    fieldType: 'radio',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    required: true
  },
  achDelayDays: {
    initialValue: 0, // BE value - default value for the FE is 0
    id: 'achDelayDays',
    type: 'rationalNumber',
    fieldType: 'input',
    label: 'ACH Delay Days',
    required: true
  }
};

export const crabApproveRepayBoardingFields = ({ useV3 } = {}) => (useV3 ? ({
  id: 'boardToRepayFields',
  rollingReserveDays: sharedApproveRepayFields.rollingReserveDays,
  holdFlag: sharedApproveRepayFields.holdFlag,
  achDelayDays: sharedApproveRepayFields.achDelayDays
}) : ({ // repayApiBoarding
  id: 'boardToRepayFields',
  rollingReserveDays: sharedApproveRepayFields.rollingReserveDays,
  achAdjustment: {
    initialValue: false, // BE value - Must be false if holdFlag is false
    name: 'achAdjustment',
    id: 'achAdjustment',
    label: 'ACH Adjustment',
    type: 'radio',
    fieldType: 'radio',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    controlledBy: {
      controllerId: 'holdFlag',
      controllerValue: 'no',
      overrides: {
        disabled: true,
        selected: 'no'
      }
    },
    required: true
  },
  combinedAch: sharedBoardingFields.combinedAch,
  discountType: {
    initialValue: 'monthly', // BE value - default value for the FE should be monthly
    id: 'discountType',
    label: 'Discount Type',
    ...comboBoxProps,
    list: [
      { title: 'Monthly', value: 'monthly' },
      { title: 'Daily', value: 'daily' }
    ],
    required: true
  },
  holdFlag: sharedApproveRepayFields.holdFlag,
  statementDestination: {
    initialValue: 'corporation', // BE value - default value for the FE is corporation
    id: 'statementDestination',
    label: 'Statement Destination',
    ...comboBoxProps,
    list: [
      { title: 'Corporation', value: 'corporation' },
      { title: 'Merchant', value: 'merchant' }
    ],
    required: true
  },
  achDelayDays: sharedApproveRepayFields.achDelayDays,
  fileBuildType: {
    initialValue: 'SA', // BE value - default value for the FE is SA
    id: 'fileBuildType',
    label: 'Attachment Code',
    ...comboBoxProps,
    list: [
      { title: 'IT - IVR Auth Merch', value: 'IT' },
      { title: 'IV - IVR Data Capture Merch', value: 'IV' },
      { title: 'SA - Standalone', value: 'SA' }
    ],
    required: true,
    tooltip: 'Type of extra service the terminal will be using'
  },
  sslParticipant: {
    initialValue: true, // BE value - default value for the FE is true
    name: 'sslParticipant',
    id: 'sslParticipant',
    label: 'SSL Participant?',
    type: 'radio',
    fieldType: 'radio',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    required: true,
    tooltip: `Select "Yes" to flag the merchant's terminals so authorizations can be rejected if the access method does not align with bill codes and the expected access method for the terminal setup`
  },
  terminalName: {
    id: 'terminalName',
    label: 'Terminal Name',
    ...comboBoxProps,
    list: [
      // In RAML, but not using yet:
      // dejavoo_qd2, dejavoo_qd4, multipass, tranzpay, valor_vl100, valor_vl110
      { title: 'Dejavoo Z1', value: 'Dejavoo Z1', mpaValue: 'dejavoo_z1' },
      { title: 'Dejavoo Z11', value: 'Dejavoo Z11', mpaValue: 'dejavoo_z11' },
      { title: 'Dejavoo Z8', value: 'Dejavoo Z8', mpaValue: 'dejavoo_z8' },
      { title: 'Dejavoo Z9', value: 'Dejavoo Z9', mpaValue: 'dejavoo_z9' },
      { title: 'Ingenico iCT220', value: 'Ingenico iCT220', mpaValue: 'ingenico_ict220' },
      { title: 'Ingenico iCT220CTL', value: 'Ingenico iCT220CTL', mpaValue: 'ingenico_ict220ctl' },
      { title: 'Ingenico iCT250', value: 'Ingenico iCT250', mpaValue: 'ingenico_ict250' },
      { title: 'Ingenico iWL220', value: 'Ingenico iWL220', mpaValue: 'ingenico_iwl220' },
      { title: 'Ingenico iWL250', value: 'Ingenico iWL250', mpaValue: 'ingenico_iwl250' },
      { title: 'PAX A920', value: 'PAX A920', mpaValue: 'pax_a920' },
      { title: 'PAX S80', value: 'PAX S80', mpaValue: 'pax_s80' },
      { title: 'Software - Other', value: 'SOFTWARE-OTHER', mpaValue: 'software_other' },
      { title: 'Terminal - Other', value: 'Terminal-Other', mpaValue: 'terminal_other' }
    ],
    required: true
  },
  idlePrompt: {
    initialValue: 'IDLE', // default value for the FE is IDLE
    id: 'idlePrompt',
    type: 'text',
    fieldType: 'input',
    label: 'Idle Prompt',
    required: true,
    customValidation: approveFormCustomValidations.idlePrompt,
    errorMessage: 'Must be 16 characters or less'
  },
  customerServicePhone: sharedBoardingFields.customerServicePhone,
  agentBank: sharedBoardingFields.agentBank,
  agentChain: sharedBoardingFields.agentChain,
  storeNumber: {
    ...sharedBoardingFields.storeNumber,
    initialValue: `${getRandomNumber({ min: 1000, max: 9999 })}`
  },
  terminalNumber: {
    ...sharedBoardingFields.terminalNumber,
    initialValue: `${getRandomNumber({ min: 1000, max: 9999 })}`
  },
  serviceLevel: {
    id: 'serviceLevel',
    label: 'Service Level',
    ...comboBoxProps,
    list: [
      { title: 'RAO', value: 'RAO' },
      { title: 'RNO', value: 'RNO' }
    ],
    required: true
  }
}));

export const crabApproveRepayMidBoardingField = {
  discoverMid: sharedBoardingFields.discoverMid
};

export const crabApproveAutoMidOnly = processName => ({
  id: 'autoMidOnly',
  autoAssignMid: {
    ...sharedBoardingFields.autoAssignMid,
    controls: {
      no: {
        mid: {
          ...sharedBoardingFields.mid,
          ...(processName === 'repay' && {
            customValidation: approveFormCustomValidations.midRepay,
            errorMessage: 'Must start with 840100'
          })
        }
      }
    }
  }
});

export const crabApproveBoardToRepay = ({ disabled } = {}) => ({
  id: 'boardToRepay',
  boardWithApiRadio: { // FE-only radio to determine whether to display `repayApiBoarding` fields
    name: 'boardWithApiRadio',
    label: 'Board To Repay (Via API)?',
    id: 'boardWithApiRadio',
    type: 'radio',
    fieldType: 'radio',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    required: true,
    disabled: disabled || false,
    wrapperStyle: { flex: '100%' }
  }
});

export const crabApproveNmiBoardingFields = gatewayBoarding => ({ // nmiBoarding
  abaNumber: sharedBoardingFields.abaNumber,
  acquirerBin: sharedBoardingFields.acquirerBin,
  agentBank: sharedBoardingFields.agentBank,
  agentChain: sharedBoardingFields.agentChain,
  currencyCode: sharedBoardingFields.currencyCode,
  countryCode: sharedBoardingFields.countryCode,
  industry: sharedBoardingFields.industry,
  locationNumber: sharedBoardingFields.locationNumber,
  networksAndSharingGroups: sharedBoardingFields.networksAndSharingGroups,
  sharingGroups: sharedBoardingFields.sharingGroups,
  reimbursementAttribute: sharedBoardingFields.reimbursementAttribute,
  settlementAgentFiId: sharedBoardingFields.settlementAgentFiId,
  storeNumber: sharedBoardingFields.storeNumber,
  terminalNumber: sharedBoardingFields.terminalNumber,
  vitalNumber: sharedBoardingFields.vitalNumber
});

export const crabApproveNmiMidBoardingFields = {
  posMid: sharedBoardingFields.posMid
};

export const crabApproveBoardToNmi = {
  id: 'boardToNmi',
  boardToNmiRadio: { // FE-only radio to determine whether to display `nmiBoarding` fields
    name: 'boardToNmiRadio',
    label: 'Board To NMI?',
    id: 'boardToNmiRadio',
    type: 'radio',
    fieldType: 'radio',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    required: true,
    wrapperStyle: { flex: '100%' }
  }
};

export const crabApproveFluidPayBoardingFields = gatewayBoarding => ({ // fluidPayBoarding
  abaNumber: sharedBoardingFields.abaNumber,
  acquirerBin: sharedBoardingFields.acquirerBin,
  agentBank: sharedBoardingFields.agentBank,
  agentChain: sharedBoardingFields.agentChain,
  currencyCode: sharedBoardingFields.currencyCode,
  countryCode: sharedBoardingFields.countryCode,
  industry: sharedBoardingFields.industry,
  locationNumber: sharedBoardingFields.locationNumber,
  networksAndSharingGroups: sharedBoardingFields.networksAndSharingGroups,
  sharingGroups: sharedBoardingFields.sharingGroups,
  reimbursementAttribute: sharedBoardingFields.reimbursementAttribute,
  settlementAgentFiId: sharedBoardingFields.settlementAgentFiId,
  storeNumber: sharedBoardingFields.storeNumber,
  terminalName: {
    id: 'terminalName',
    label: 'Terminal Name',
    type: 'text',
    fieldType: 'input',
    required: true
  },
  terminalNumber: sharedBoardingFields.terminalNumber,
  terminalPhoneAuthPrimary: sharedBoardingFields.terminalPhoneAuthPrimary,
  terminalPhoneAuthSecondary: sharedBoardingFields.terminalPhoneAuthSecondary,
  terminalPhoneSettlementPrimary: sharedBoardingFields.terminalPhoneSettlementPrimary,
  terminalPhoneSettlementSecondary: sharedBoardingFields.terminalPhoneSettlementSecondary,
  terminalUrlAuthPrimary: sharedBoardingFields.terminalUrlAuthPrimary,
  terminalUrlAuthSecondary: sharedBoardingFields.terminalUrlAuthSecondary,
  terminalUrlSettlementPrimary: sharedBoardingFields.terminalUrlSettlementPrimary,
  terminalUrlSettlementSecondary: sharedBoardingFields.terminalUrlSettlementSecondary,
  timeZoneHumanReadable: {
    id: 'timeZoneHumanReadable',
    label: 'Time Zone',
    ...comboBoxProps,
    required: true,
    list: sortData([
      { title: 'UTC', value: 'utc' },
      { title: 'US - Alaska', value: 'us_alaska' },
      { title: 'US - Aleutian', value: 'us_aleutian' },
      { title: 'US - Arizona', value: 'us_arizona' },
      { title: 'US - Central', value: 'us_central' },
      { title: 'US - Eastern', value: 'us_eastern' },
      { title: 'US - East-Indiana', value: 'us_east_indiana' },
      { title: 'US - Hawaii', value: 'us_hawaii' },
      { title: 'US - Indiana-Starke', value: 'us_indiana_starke' },
      { title: 'US - Michigan', value: 'us_michigan' },
      { title: 'US - Mountain', value: 'us_mountain' },
      { title: 'US - Pacific', value: 'us_pacific' },
      { title: 'US - Samoa', value: 'us_samoa' }

    ], 'title')
  },
  vitalNumber: sharedBoardingFields.vitalNumber
});

export const crabApproveFluidPayMidBoardingFields = { // fluidPayBoarding
  posMid: sharedBoardingFields.posMid
};

export const crabApproveBoardToFluidPay = { // fluidPayBoarding
  id: 'boardToFluidPay',
  boardToFluidPayRadio: { // FE-only radio to determine whether to display `fluidPayBoarding` fields
    name: 'boardToFluidPayRadio',
    label: 'Board To Fluid Pay?',
    id: 'boardToFluidPayRadio',
    type: 'radio',
    fieldType: 'radio',
    fields: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    required: true,
    wrapperStyle: { flex: '100%' }
  }
};
