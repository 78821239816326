import { transformData } from '@f1/shared/src/_helpers';
import { crmEndpoint } from '../../utilsEndpoints';
import fileInputTemplate from './templates/fileInputTemplate';
import fileOutputTemplate from './templates/fileOutputTemplate';

export const mockUploadFilesInputGet = (body, params, options) => {
  if (params.yearMonth === '2022-01') {
    return { residualsInputFiles: [] };
  }
  return mockUploadFilesGet;
};

export const mockUploadFilesGet = {
  residualsInputFiles: [
    {
      fileType: 'payroc',
      file: {
        fileName: 'payroc.xls',
        relativePath: '/SYSTEM/residuals/2022/01/input/payroc',
        url: crmEndpoint.file.v2DummyUploadUrl,
        fileCreationTimestamp: '2021-01-16 00:23:04Z'
      }
    },
    {
      fileType: 'repay_processing_template',
      file: {
        fileName: 'repay-process.xls',
        relativePath: '/SYSTEM/residuals/2022/01/input/repay',
        url: crmEndpoint.file.v2DummyUploadUrl,
        fileCreationTimestamp: '2021-01-16 00:23:04Z'
      }
    }
  ]
};

export const mockUploadFilesGetFormatted = transformData({
  data: mockUploadFilesGet.residualsInputFiles,
  toSchema: 'toDataTable',
  template: fileInputTemplate,
  version: '1.0'
});

export const mockUploadFilesPut = {
  uploadUrl: {
    fileName: 'nameOfFileForS3Bucket.file',
    relativePath: '/SYSTEM/residuals/2022/01/input/payroc',
    url: crmEndpoint.file.v2DummyUploadUrl
  }
};

export const mockUploadFilesOutputGet = (body, params, options) => {
  if (params.yearMonth === '2022-01') {
    return { residualsOutputFiles: [] };
  }
  return mockUploadFilesOutput;
};

export const mockUploadFilesOutput = {
  residualsOutputFiles: [
    {
      fileType: 'repay_processing',
      file: {
        fileName: '2022-07_repay_processing_residuals_report_2022-10-21T16:59:04:091680477.zip',
        relativePath: '/SYSTEM/residuals/2022/01/output/mid_residuals_history',
        url: crmEndpoint.file.v2DummyUploadUrl,
        fileCreationTimestamp: '2022-01-16 00:23:04Z'
      }
    },
    {
      fileType: 'repay_partners',
      file: {
        fileName: '2022-07_repay_partners_residuals_report_2022-10-21T16:59:04:091680477.zip',
        relativePath: '/SYSTEM/residuals/2022/01/output/mid_residuals_history',
        url: crmEndpoint.file.v2DummyUploadUrl,
        fileCreationTimestamp: '2022-01-16 00:23:04Z'
      }
    },
    {
      fileType: 'payroc_output',
      file: {
        fileName: 'historyByPartner.xls',
        relativePath: '/SYSTEM/residuals/2022/01/output/partner_residuals_history',
        url: crmEndpoint.file.v2DummyUploadUrl,
        fileCreationTimestamp: '2022-01-16 00:23:04Z'
      }
    }
  ]
};

export const mockUploadFilesOutputFormatted = transformData({
  data: mockUploadFilesOutput.residualsOutputFiles,
  toSchema: 'frontend',
  template: fileOutputTemplate,
  version: '1.0'
});
