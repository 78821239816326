import { camelToTitle, formatDateForFEView, formatNumber } from '@f1/shared/src/_helpers';

const nachaTemplate = {
  toDataTable: (schema, version) => {
    if (!(schema instanceof Array)) return [];
    return schema.map(sub => ({
      created: formatDateForFEView(sub?.createdTimestamp, { includeTime: true }),
      bank: camelToTitle(sub?.bankName ?? ''),
      status: sub?.status === 'submitted_to_bank' ? 'submitted' : sub?.status,
      submissionId: sub?.nachaSubmissionId,
      submittedToBank: sub?.submittedToBankTimestamp ? formatDateForFEView(sub?.submittedToBankTimestamp, { includeTime: true }) : '-',
      creditItems: sub?.creditItemCount ?? 0,
      debitItems: sub?.debitItemCount ?? 0,
      creditAmount: formatNumber(sub?.creditAmountTotal ?? 0, { currency: true }),
      debitAmount: formatNumber(sub?.debitAmountTotal ?? 0, { currency: true }),
      excelFileId: sub?.createdFromExcelFileId ?? '-',
      fileId: sub?.nachaFileId ?? '-',
      fileHeaderReferenceCode: sub?.fileHeaderReferenceCode || '-'
    }));
  },
  toDetails: (schema, version) => {
    if (!(schema?.humanReadableTranslation instanceof Array)) return [];
    return schema?.humanReadableTranslation?.map(payment => ({
      batchId: payment.batchId,
      standardEntryClassCode: payment.standardEntryClassCode,
      amount: formatNumber(payment?.amount ?? 0, { currency: true }),
      transactionCode: payment.transactionCode,
      receivingRoutingNumber: payment.receivingRoutingNumber,
      receivingAccountNumber: payment.receivingAccountNumber,
      individualName: payment?.individualName ?? '-',
      companyName: payment?.companyName ?? '-',
      companyDiscretionaryData: payment.companyDiscretionaryData ?? '-',
      companyEntryDescription: payment.companyEntryDescription ?? '-'
    }));
  },
  toReturnsDataTable: (schema, version) => {
    if (!(schema instanceof Array)) return [];
    return schema.map(sub => ({
      created: formatDateForFEView(sub?.processorSyncTimestamp, { includeTime: true }),
      bank: camelToTitle(sub?.bankName ?? ''),
      nachaReturnId: sub?.nachaReturnId,
      download: sub?.file?.url,
      fileName: sub?.file?.fileName
    }));
  }
};

export const nachaHelpText = [
  {
    title: 'Batch ID',
    value: ['Max Length: 7', 'Must be numeric pattern [0-9]']
  },
  {
    title: 'Standard Entry Class Code',
    value: ['Must be one of [CCD, PPD]']
  },
  {
    title: 'Amount',
    value: ['Max Length: 10']
  },
  {
    title: 'Transaction Code',
    value: ['Must be one of [22, 27, 32, 37]', 'CHECKING_CREDIT_22("22")', 'CHECKING_DEBIT_27("27")', 'SAVINGS_CREDIT_32("32")', 'SAVINGS_DEBIT_37("37")']
  },
  {
    title: 'Receiving Routing Number',
    value: ['Max Length: 9', 'Min Length: 9', 'Must be a numeric pattern [0-9]']
  },
  {
    title: 'Receiving Account Number',
    value: ['Max Length: 17', 'Characters A-Z and 0-9 are permitted']
  },
  {
    title: 'Individual Name',
    value: ['Either Individual Name or Company Name is required', 'If this is present, standardEntryClassCode must be PPD', 'Max Length: 22']
  },
  {
    title: 'Company Name',
    value: ['Either Individual Name or Company Name is required', 'If this is present, standardEntryClassCode must be CCD', 'Max Length: 22']
  }
];

export default nachaTemplate;
