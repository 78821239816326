// hey you modifying this code, are you adding an employee group?
// if so, please check out this PR to make sure you are adding it everywhere needed
// BIRB 6957. Please add in all places that PR adds to
export const mockSignInUsers = {
  operationsLegacy: 'f1paymentsengineering+operations1@gmail.com',
  accountingManager: 'f1paymentsengineering+finance2@gmail.com',
  accounting: 'f1paymentsengineering+finanace1@gmail.com',
  risk: 'f1paymentsengineering+risk1@gmail.com',
  riskAdmin: 'f1paymentsengineering+risk2@gmail.com',
  riskManager: 'f1paymentsengineering+risk3@gmail.com',
  sales: 'f1paymentsengineering+sales2@gmail.com',
  compliance: 'f1paymentsengineering+compliance1@gmail.com',
  engineering: 'f1paymentsengineering+engineering1@gmail.com',
  credit: 'f1paymentsengineering+credit1@gmail.com',
  credit2: 'f1paymentsengineering+credit2@gmail.com',
  hr: 'f1paymentsengineering+hr1@gmail.com',
  hr2: 'f1paymentsengineering+hr2@gmail.com',
  operations: 'f1paymentsengineering+support1@gmail.com',
  operations2: 'f1paymentsengineering+support2@gmail.com',
  it: 'f1paymentsengineering+it1@gmail.com',
  nonF1Internal: 'f1paymentsengineering+crossriver1@gmail.com',
  mvb1: 'mockRandomBankUserMvb@corviapay.com',
  appReview: 'f1paymentsengineering+appreview1@gmail.com',
  appReview2: 'f1paymentsengineering+appreview2@gmail.com',
  appReviewAndCredit: 'mockRandomAppReviewUser@corviapay.com'
};

const mockFrontendCsrfToken = 'mockFrontendCsrfToken'; // Must be this exact value for FTs

// hey you modifying this code, are you adding an employee group?
// if so, please check out this PR to make sure you are adding it everywhere needed
// BIRB 6957. Please add in all places that PR adds to
export const mockUserSignInPost = {
  [mockSignInUsers.operationsLegacy]: {
    identityToken: {
      f1Email: { email: mockSignInUsers.operationsLegacy },
      email: mockSignInUsers.operationsLegacy,
      emailVerified: true,
      phoneNumber: '+15125536624',
      firstName: 'Bob',
      lastName: 'Ross'
    },
    csrfToken: mockFrontendCsrfToken,
    employeeGroupList: ['OPERATIONS_LEGACY', 'ENGINEERING'],
    isInternal: true
  },
  [mockSignInUsers.accountingManager]: {
    identityToken: {
      f1Email: { email: mockSignInUsers.accountingManager },
      email: mockSignInUsers.accountingManager,
      emailVerified: true,
      phoneNumber: '+12223334455',
      firstName: 'Jerry\u0027s',
      lastName: 'Player'
    },
    csrfToken: mockFrontendCsrfToken,
    employeeGroupList: ['RESIDUAL', 'ACCOUNTING', 'ACCOUNTING_MANAGER'],
    isInternal: true
  },
  [mockSignInUsers.accounting]: {
    identityToken: {
      f1Email: { email: mockSignInUsers.accounting },
      email: mockSignInUsers.accounting,
      emailVerified: true,
      phoneNumber: '+12223334444',
      firstName: 'Jerry',
      lastName: 'Maguire'
    },
    csrfToken: mockFrontendCsrfToken,
    employeeGroupList: ['RESIDUAL', 'ACCOUNTING'],
    isInternal: true
  },
  [mockSignInUsers.risk]: {
    identityToken: {
      f1Email: { email: mockSignInUsers.risk },
      email: mockSignInUsers.risk,
      emailVerified: true,
      phoneNumber: '+12223334455',
      firstName: 'Tom',
      lastName: 'Jerry'
    },
    csrfToken: mockFrontendCsrfToken,
    employeeGroupList: ['RISK'],
    isInternal: true
  },
  [mockSignInUsers.riskAdmin]: {
    identityToken: {
      f1Email: { email: mockSignInUsers.riskAdmin },
      email: mockSignInUsers.riskAdmin,
      emailVerified: true,
      phoneNumber: '+12223334455',
      firstName: 'Calvin',
      lastName: 'Hobbes'
    },
    csrfToken: mockFrontendCsrfToken,
    employeeGroupList: ['RISK', 'RISK_CLOSURE_ADMIN'],
    isInternal: true
  },
  [mockSignInUsers.riskManager]: {
    identityToken: {
      f1Email: { email: mockSignInUsers.riskManager },
      email: mockSignInUsers.riskManager,
      emailVerified: true,
      phoneNumber: '+12223334455',
      firstName: 'Yankee',
      lastName: 'Doodle'
    },
    csrfToken: mockFrontendCsrfToken,
    employeeGroupList: ['RISK', 'RISK_MANAGER'],
    isInternal: true
  },
  [mockSignInUsers.sales]: {
    identityToken: {
      f1Email: { email: mockSignInUsers.sales },
      email: mockSignInUsers.sales,
      emailVerified: true,
      phoneNumber: '+12223334455',
      firstName: 'Sally',
      lastName: 'Mae'
    },
    csrfToken: mockFrontendCsrfToken,
    employeeGroupList: ['SALES', 'PROJECT'],
    isInternal: true
  },
  [mockSignInUsers.compliance]: {
    identityToken: {
      f1Email: { email: mockSignInUsers.compliance },
      email: mockSignInUsers.compliance,
      emailVerified: true,
      phoneNumber: '+12223334455',
      firstName: 'Swanthula',
      lastName: 'Boulet'
    },
    csrfToken: mockFrontendCsrfToken,
    employeeGroupList: ['COMPLIANCE'],
    isInternal: true
  },
  [mockSignInUsers.engineering]: {
    identityToken: {
      f1Email: { email: mockSignInUsers.engineering },
      email: mockSignInUsers.engineering,
      emailVerified: true,
      phoneNumber: '+12223334455',
      firstName: 'Engineer',
      lastName: 'BossMan'
    },
    csrfToken: mockFrontendCsrfToken,
    employeeGroupList: ['ENGINEERING'],
    isInternal: true
  },
  [mockSignInUsers.credit]: {
    identityToken: {
      f1Email: { email: mockSignInUsers.credit },
      email: mockSignInUsers.credit,
      emailVerified: true,
      phoneNumber: '+12223334455',
      firstName: 'Freddie',
      lastName: 'Mac'
    },
    csrfToken: mockFrontendCsrfToken,
    employeeGroupList: ['CREDIT'],
    isInternal: true
  },
  [mockSignInUsers.credit2]: {
    identityToken: {
      f1Email: { email: mockSignInUsers.credit2 },
      email: mockSignInUsers.credit2,
      emailVerified: true,
      phoneNumber: '+13334445555',
      firstName: 'Bruce',
      lastName: 'Wayne'
    },
    csrfToken: mockFrontendCsrfToken,
    employeeGroupList: ['CREDIT', 'CREDIT_MANAGER'],
    isInternal: true
  },
  [mockSignInUsers.hr]: {
    identityToken: {
      f1Email: { email: mockSignInUsers.hr },
      email: mockSignInUsers.hr,
      emailVerified: true,
      phoneNumber: '+12223334455',
      firstName: 'Alyssa',
      lastName: 'Edwards'
    },
    csrfToken: mockFrontendCsrfToken,
    employeeGroupList: ['HUMAN_RESOURCES'],
    isInternal: true
  },
  [mockSignInUsers.operations]: {
    identityToken: {
      f1Email: { email: mockSignInUsers.operations },
      email: mockSignInUsers.operations,
      emailVerified: true,
      phoneNumber: '+12223334455',
      firstName: 'Lawrence',
      lastName: 'Cheney'
    },
    csrfToken: mockFrontendCsrfToken,
    employeeGroupList: ['OPERATIONS'],
    isInternal: true
  },
  [mockSignInUsers.it]: {
    identityToken: {
      f1Email: { email: mockSignInUsers.it },
      email: mockSignInUsers.it,
      emailVerified: true,
      phoneNumber: '+12223334455',
      firstName: 'Mister',
      lastName: 'Robot'
    },
    csrfToken: mockFrontendCsrfToken,
    employeeGroupList: ['IT'],
    isInternal: true
  },
  [mockSignInUsers.nonF1Internal]: {
    identityToken: {
      f1Email: { email: mockSignInUsers.nonF1Internal },
      email: mockSignInUsers.nonF1Internal,
      emailVerified: true,
      phoneNumber: '+15125536624',
      firstName: 'Serena',
      lastName: 'Chacha'
    },
    csrfToken: mockFrontendCsrfToken,
    employeeGroupList: ['CROSSRIVER'],
    isInternal: false
  },
  [mockSignInUsers.mvb1]: {
    identityToken: {
      f1Email: { email: mockSignInUsers.mvb1 },
      email: mockSignInUsers.mvb1,
      emailVerified: true,
      phoneNumber: '+15125536620',
      firstName: 'Tom',
      lastName: 'Ato'
    },
    csrfToken: mockFrontendCsrfToken,
    employeeGroupList: ['MVB'],
    isInternal: false
  },
  [mockSignInUsers.appReview]: {
    identityToken: {
      f1Email: { email: mockSignInUsers.appReview },
      email: mockSignInUsers.appReview,
      emailVerified: true,
      phoneNumber: '+15125536624',
      firstName: 'Fred',
      lastName: 'Flintstone'
    },
    csrfToken: mockFrontendCsrfToken,
    employeeGroupList: ['APP_REVIEW'],
    isInternal: true
  },
  [mockSignInUsers.appReview2]: {
    identityToken: {
      f1Email: { email: mockSignInUsers.appReview2 },
      email: mockSignInUsers.appReview2,
      emailVerified: true,
      phoneNumber: '+15125536624',
      firstName: 'Barney',
      lastName: 'Fife'
    },
    csrfToken: mockFrontendCsrfToken,
    employeeGroupList: ['APP_REVIEW_MANAGER', 'APP_REVIEW'],
    isInternal: true
  },
  [mockSignInUsers.appReviewAndCredit]: {
    identityToken: {
      f1Email: { email: mockSignInUsers.appReviewAndCredit },
      email: mockSignInUsers.appReviewAndCredit,
      emailVerified: true,
      phoneNumber: '+15125536625',
      firstName: 'Bob',
      lastName: 'Barker'
    },
    csrfToken: mockFrontendCsrfToken,
    employeeGroupList: ['APP_REVIEW', 'CREDIT'],
    isInternal: true
  }
};
