import { pad } from '@f1/shared/src/_helpers';
import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';

const createMockRubmapRequest = (index, requestIndex) => ({
  applicationMpaId: `${index}`,
  dbaName: `DBA Name ${index + 1}`,
  applicationStateCode: index === 0
    ? 'TX' // Hardcoded value for tests
    : getMockValue({ type: 'address' }).stateCode,
  searchTimestamp: index === 0
    ? `2022-10-${pad(20 - requestIndex)}T14:35:16.950`
    : getMockValue({ type: 'isoDate' })
});

const createMockRubmapResponse = (index, options) => {
  const { length } = options || {};
  const resLength = index === 2 ? 0 : length || 3;
  return Array.from({ length: resLength }).map((resItem, resIndex) => {
    const mockAddress = getMockValue({ type: 'address' });
    return {
      merchantName: `DBA Name ${index + 1}`,
      merchantAddress: `123 Mock Address St, Apt ${resIndex + 1}`,
      merchantCity: mockAddress.cityName,
      merchantZip: mockAddress.zipCode,
      merchantState: index === 0
        ? 'TX' // Hardcoded value for tests
        : mockAddress.stateCode,
      merchantPhoneNumber: getMockValue({ type: 'bePhone' }),
      reviews: index === 1
        ? [] // Hardcoded value for tests
        : Array.from({ length: getMockValue({ min: 1, max: 10 }) }).map(() => (
          `${getMockValue({ type: 'description', length: 'long' })}\n${getMockValue({ type: 'description', length: 'long' })}`
        ))
    };
  });
};

const createMockRubmapsResultList = () => {
  const mpaIds = [0, 0, 0, 1, 1, 1, 2, 2, 2]; // create 3 entries for each mpaId
  return mpaIds.map((mpaId, arrayIndex) => ({
    requestFields: createMockRubmapRequest(mpaId, arrayIndex),
    responseFields: createMockRubmapResponse(mpaId, { length: getMockValue({ min: 2, max: 10 }) })
  }));
};

export const mockRubmapsApplicationV3 = (body, params, options) => {
  const { guid } = options || {};
  const mockResultList = createMockRubmapsResultList(options);
  return { [guid]: { resultList: mockResultList } };
};

export default mockRubmapsApplicationV3;
